<div id="accountPageWrapper" class="bg-f5f5f5 ff-roboto">
    <div class="w-100 p-30 pl-sm-20 pr-sm-20 p-20-sm">
        <div class="d-flex-row w-100">
            <h1 class="h1-dark fs-22">My Account</h1>
        </div>
        <div class="w-100 mt-20 b-s1-00012 brds-4px p-25 d-flex-col bg-white">
            <div class="w-100 d-flex-row fx-wrap-sm">
                <div class="profile-pic-box mr-25">
                    <div *ngIf="(!myAccount?.profilePictureUrl && !myAccount?.logoUrl) || profileImgNotExist"
                        class="text-profile-box b-s1-00012 text-primary fs-32 fw-500 lh-36 bg-f5f5f5">
                        <span
                            *ngIf="myAccount?.firstName && myAccount?.lastName">{{myAccount?.firstName[0]}}{{myAccount?.lastName[0]}}</span>
                        <span *ngIf="!myAccount?.lastName">{{myAccount?.firstName.substr(0,2)}}</span>
                    </div>
                    <div *ngIf="(myAccount?.profilePictureUrl || myAccount?.logoUrl) && !profileImgNotExist" class="image-profile-box">
                        <img [src]="profileImage | apiImgPath : {nl:true}" (error)="profileError()" class=" b-s1-00012">
                    </div>
                </div>
                <div class="profile-contact-box d-flex-col jc-center">
                    <h2 class="fs-16-i fw-500-i lh-24-i text-0087 m-0-i mt-10-i">{{myAccount?.firstName}}
                        {{myAccount?.lastName}}</h2>
                    <p class="m-o-i"><a class="fs-14 fw-14 lh-24 text-primary"
                            href="mailto:{{myAccount?.email}}">{{myAccount?.email}}</a></p>
                </div>
                <div class="profile-action-box d-flex-col jc-center ml-auto">
                    <button (click)="openUpdateProfileDialog()" mat-raised-button color="primary" class="tt-upper mb-15-i">Edit Profile</button>
                </div>
            </div>
            <div class="w-100 d-flex-col mt-30 b-s1-00012 fs-14 fw-500 lh-24 text-0087">
                <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                    <label class="p-10 bg-f5f5f5 w-20 w-100-sm">First Name</label>
                    <span class="p-10 fw-400">{{myAccount?.firstName}}</span>
                </p>
                <mat-divider></mat-divider>
                <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                    <label class="p-10 bg-f5f5f5 w-20 w-100-sm">Last Name</label>
                    <span class="p-10 fw-400">{{myAccount?.lastName}}</span>
                </p>
                <mat-divider></mat-divider>
                <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                    <label class="p-10 bg-f5f5f5 w-20 w-100-sm">Email Address</label>
                    <span class="p-10 fw-400">{{myAccount?.email}}</span>
                </p>
                <mat-divider></mat-divider>
                <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                    <label class="p-10 bg-f5f5f5 w-20 w-100-sm">Phone</label>
                    <span class="p-10 fw-400">{{myAccount?.phoneNumber}}</span>
                </p>
                <mat-divider></mat-divider>
                <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                    <label class="p-10 bg-f5f5f5 w-20 w-100-sm">Address</label>
                    <span class="p-10 fw-400">
                        {{myAccount?.addressLine1}}
                        <span *ngIf="myAccount?.addressLine2"> {{myAccount?.addressLine2}}</span>
                    </span>
                </p>
                <mat-divider></mat-divider>
                <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                    <label class="p-10 bg-f5f5f5 w-20 w-100-sm">State</label>
                    <span class="p-10 fw-400">{{myAccount?.state}}</span>
                </p>
                <mat-divider></mat-divider>
                <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                    <label class="p-10 bg-f5f5f5 w-20 w-100-sm">City</label>
                    <span class="p-10 fw-400">{{myAccount?.cityOrTown}}</span>
                </p>
                <mat-divider></mat-divider>
                <p class="m-0-i w-100 d-flex-row fxd-col-sm ">
                    <label class="p-10 bg-f5f5f5 w-20 w-100-sm">Zipcode</label>
                    <span class="p-10 fw-400">{{myAccount?.zipCode}}</span>
                </p>
                <div *ngIf="isRetailerAccount">
                    <mat-divider></mat-divider>
                    <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                        <label class="p-10 bg-f5f5f5 w-20 w-100-sm">Store Banner</label>
                        <span class="p-10">
                            <img class="mw-150px obj-ft-contain" *ngIf="myAccount?.storeBannerUrl"
                                [src]="myAccount?.storeBannerUrl | apiImgPath">
                        </span>
                    </p>
                    <mat-divider></mat-divider>
                    <p class="m-0-i w-100 d-flex-row fxd-col-sm">
                        <label class="p-10 bg-f5f5f5 w-20 w-100-sm">Store Logo</label>
                        <span class="p-10">
                            <img class="mw-64px obj-ft-contain brds-100" *ngIf="myAccount?.logoUrl"
                                [src]="myAccount?.logoUrl | apiImgPath">
                        </span>
                    </p>
                    <mat-divider></mat-divider>
                    <p class="m-0-i w-100 d-flex-row fxd-col-sm ">
                        <label class="p-10 bg-f5f5f5 w-20 w-100-sm">Store Name</label>
                        <span class="p-10 fw-400">{{myAccount?.storeName}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="w-100 mt-20 b-s1-00012 brds-4px p-25 d-flex-col bg-white">
            <div class="w-100 d-flex-row fx-wrap-sm">
                <div class="profile-contact-box d-flex-col jc-center">
                    <h2 class="fs-18-i fw-500-i lh-24-i text-0087 m-0-i">
                        <span *ngIf="isRetailerAccount">Product Natures</span>
                        <span *ngIf="!isRetailerAccount">Personal Interests</span>
                    </h2>
                </div>
                <div class="profile-action-box d-flex-col jc-center ml-auto">
                    <button (click)="openEditProductNaturesDialog()" mat-button color="primary" class="tt-upper text-primary">Edit</button>
                </div>
            </div>
            <div class="w-100 d-flex-col mt-30 b-s1-00012 fs-14 fw-500 lh-24 text-0087">
                <div class="m-0-i w-100 d-flex-row fxd-col-sm">
                    <div class="p-10 bg-f5f5f5 w-20 w-100-sm">
                        <span *ngIf="isRetailerAccount">Natures</span>
                        <span *ngIf="!isRetailerAccount">Interests</span>
                    </div>
                    <div class="p-10 fw-400">
                        <p class="m-0-i lh-30" *ngFor="let nature of myAccount?.productNatures">
                            <span>{{getProductNatureNameById(nature)}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>