import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleEnum } from 'src/app/enums/role.enum';
import { ISubscriberListItem } from 'src/app/models/subscriber-list-item.model';
import { ISubscriptionListItem } from 'src/app/models/subscription-list-item.model';
import { AuthenticationService } from 'src/app/services';
import { OrderService } from 'src/app/services/order.service';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';

@Component({
    selector: 'subscription-page',
    templateUrl: '../../views/account/subscriptions.component.html'
})
export class SubscriptionsComponent implements OnInit, OnDestroy {

    domain: string;
    searchKeyword: string;
    filterStatus: any;

    subscriptionListItem: any[] = [];
    subscriptionListItemOriginal: any[] = [];
    tablePageSize: number = 10;
    tablePageIndex: number = 0;
    tableDatasource: any[] = [];

    isRetailer: boolean;
    retailerDomain:string;
    loading: boolean = true;

    retailerView:boolean = false;

    requestForSubscriptionsPage:boolean;
    requestForSubscribersPage:boolean;

    constructor(
        private authService: AuthenticationService,
        private orderService: OrderService,
        private retailerStoreService: RetailerStoreService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private datePipe: DatePipe) { 
            this.retailerView = this.router.url.indexOf("subscribers") > -1;
            this.requestForSubscriptionsPage = this.router.url.split('/').pop() == "subscriptions";
            this.requestForSubscribersPage = this.router.url.split('/').pop() == "subscribers";
        }

    ngOnInit(): void {
        
        const userId = this.authService.currentUserId;
        this.isRetailer = this.authService.currentUserRole === RoleEnum.retailer;
        if (this.isRetailer) {
            this.retailerDomain = this.activatedRoute.parent.snapshot.paramMap.get('domain') || this.activatedRoute.snapshot.paramMap.get('domain');
        }
        
        if (!this.retailerView) {
            this.orderService
                .geSubscriptionListItemByUserId(userId)
                .toPromise()
                .then((res: ISubscriptionListItem[]) => {
                    this.loading = false;
                    if (res && res.length > 0) {
                        this.subscriptionListItemOriginal = res.sort((a, b) => { return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime(); });
                        this.subscriptionListItem = this.subscriptionListItemOriginal;
                        const items = [...this.subscriptionListItem];
                        this.tableDatasource = items.splice(this.tablePageIndex * this.tablePageSize, this.tablePageSize);
                    }
                })
                .catch(err => {
                    this.loading = false;
                });
            return;
        }

        this.retailerStoreService
            .geSubscriberListItemByUserId(userId)
            .toPromise()
            .then((res: ISubscriberListItem[]) => {
                this.loading = false;
                if (res && res.length > 0) {
                    this.subscriptionListItemOriginal = res.sort((a, b) => { return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime(); });
                    this.subscriptionListItem = this.subscriptionListItemOriginal;
                    const items = [...this.subscriptionListItem];
                    this.tableDatasource = items.splice(this.tablePageIndex * this.tablePageSize, this.tablePageSize);
                }
            })
            .catch(err => {
                this.loading = false;
            });
    }

    filterOrderListItems(): void {
        var self = this;
        var filterList = self.subscriptionListItemOriginal.filter(o => {
            if (!self.searchKeyword) return true;
            return Object.keys(o).some(k => {
                return o[k] && o[k].toString().toLowerCase().includes(self.searchKeyword.toLowerCase())
            });
        });

        var filterList = filterList.filter(o => {
            if (self.filterStatus == -1 || !self.filterStatus) return true;
            return o.status == self.filterStatus;
        });

        const items = [...filterList];
        self.tableDatasource = items.splice(self.tablePageIndex * self.tablePageSize, self.tablePageSize);
    }

    pageChanged(event: PageEvent) {
        this.tablePageIndex = event.pageIndex;
        this.tablePageSize = event.pageSize;

        const items = [...this.subscriptionListItem];
        this.tableDatasource = items.splice(this.tablePageIndex * this.tablePageSize, this.tablePageSize);
    }
    ngOnDestroy(): void { }

}