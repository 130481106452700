import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { ICartItem } from 'src/app/models/cart-item.model';
import { ICreateOrderResponse } from 'src/app/models/create-order-response.model';
import { IDeliveryFee } from 'src/app/models/delivery-fee.model';
import { IExclusiveSessionSubscriptionResponse } from 'src/app/models/exclusive-session-subscription-response.model';
import { IOrder } from 'src/app/models/order.model';
import { IPayment } from 'src/app/models/payment.model';
import { IUserInfo } from 'src/app/models/user-info.model';
import { TaxPipe } from 'src/app/pipes/tax.pipe';
import { AccountService, AuthenticationService } from 'src/app/services';
import { CartService } from 'src/app/services/cart.service';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';
import { OrderService } from 'src/app/services/order.service';
import { SessionService } from 'src/app/services/session.service';
import { ShopService } from 'src/app/services/shop.service';
import { SquaurePaymentService } from 'src/app/services/square-payment.service';
import { ProgressSpinnerDialogComponent } from '../common/progress-spinner-dialog.component';

@Component({
    selector: 'checkout',
    templateUrl: '../../views/shops/checkout.shops.component.html',
    styleUrls: ['../../../assets/css/shops/checkout.shops.component.css']
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {

    loadingRetailerLocationInfo: boolean = true;

    isAuthenticated: boolean;
    isExclusiveSession: boolean;
    loggedInUserId: string;
    loggedInUserInfo: IUserInfo;
    cartItms: ICartItem[] = [];

    deliveryFee: IDeliveryFee;
    taxRate: number = 2;

    checkoutForm: FormGroup;
    inProgress = false;

    firstName = new FormControl('', [Validators.required]);
    lastName = new FormControl('', []);
    addressLine1 = new FormControl('', [Validators.required]);
    addressLine2 = new FormControl('', []);
    state = new FormControl('', [Validators.required]);
    city = new FormControl('', [Validators.required]);
    zipcode = new FormControl('', [Validators.required, Validators.minLength(4)]);

    email = new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
    phoneNumber = new FormControl('', [Validators.required]);

    subscriptions = new Subscription();

    private window: Window;
    private appId: string = AppConfig.settings.square.appId;
    private locationId: string;

    private paymentcard: any;

    constructor(
        @Inject(DOCUMENT) private document,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private session: SessionService,
        private authenticationService: AuthenticationService,
        private cartService: CartService,
        private shopService: ShopService,
        private paymentService: SquaurePaymentService,
        private orderService: OrderService,
        private accountService: AccountService,
        private taxPipe: TaxPipe,
        private sessionComponentService: SessionComponentService,
        private router: Router) {
        this.window = this.document.defaultView;
    }

    ngOnInit(): void {
        this.cartItms = this.cartService.getItems();
        if (this.cartItms.length == 0) {
            this.router.navigate([`/`]);
        }

        if (this.cartItms[0]?.isExclusiveStream) {
            this.isExclusiveSession = this.cartItms[0]?.isExclusiveStream;
        }

        this.subscriptions.add(
            this.authenticationService.currentUser.subscribe(loggedInuser => {
                if (loggedInuser) {
                    this.isAuthenticated = true;
                    this.loggedInUserId = loggedInuser.id;
                    this.accountService.getUserInfoByUserId(loggedInuser.id)
                        .toPromise()
                        .then((info: IUserInfo) => {
                            this.loggedInUserInfo = info;
                            this.firstName.setValue(info.firstName);
                            this.lastName.setValue(info.lastName);
                            this.addressLine1.setValue(info.addressLine1);
                            this.addressLine2.setValue(info.addressLine2);
                            this.state.setValue(info.state);
                            this.city.setValue(info.cityOrTown);
                            this.zipcode.setValue(info.zipCode);

                            this.email.setValue(info.email);
                            this.phoneNumber.setValue(info.phoneNumber);
                        });
                } else {
                    this.isAuthenticated = false;
                }
            })
        );

        if (!this.isExclusiveSession) {
            this.subscriptions.add(
                this.shopService.loadDeliveryFeeInfo(this.session.checkoutDomain).subscribe(info => {
                    this.deliveryFee = info;

                })
            );
        }

        this.shopService
            .retailerLocationIdByDomain(this.session.checkoutDomain)
            .toPromise()
            .then(res => {
                this.loadingRetailerLocationInfo = false;
                this.locationId = res.locationId;
            })

        this.checkoutForm = this.formBuilder.group({
            firstName: this.firstName,
            lastName: this.lastName,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            state: this.state,
            city: this.city,
            zipcode: this.zipcode,
            email: this.email,
            phoneNumber: this.phoneNumber,
        });
    }

    get totalQuantity(): number {
        const tq = this.cartItms.reduce((x, y) => x + y.quantity, 0);
        if (!tq) return 0;
        return tq;
    }

    get subtotal(): number {
        const total = this.cartItms.reduce((x, y) => x + (y.unitPrice * y.quantity), 0);
        return total;
    }

    get taxAmount(): any {
        return this.taxPipe.transform(this.cartItms)
    }

    get orderTotal(): any {
        if (this.isExclusiveSession) {
            return this.subtotal/100;
        }

        var total = this.subtotal / 100 + this.taxAmount; // + this.deliveryFee?.deliveryFee || 0;
        if (this.deliveryFee) total += this.deliveryFee.deliveryFee;
        return total;
    }

    async ngAfterViewInit() {

        var self = this;
        var documentWindow: any = self.window;
        if (!documentWindow.Square) {
            throw new Error('Square.js failed to load properly');
        }

        const payments = documentWindow.Square.payments(self.appId, self.locationId);
        try {
            this.paymentcard = await self.paymentService.initializeCard(payments);
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }
    }

    onCheckoutSubmit() {

        const progressSpinnerDialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
            panelClass: 'transparent',
            disableClose: true
        });

        if (this.checkoutForm.invalid) {
            progressSpinnerDialogRef.close();
            return;
        }

        if (this.isExclusiveSession) {
            this.onExclusiveSessionSubscribe(progressSpinnerDialogRef);
            return;
        }

        const shippingCharge = this.deliveryFee ? this.deliveryFee.deliveryFee : 0;
        const orderdata: IOrder = {
            addressLine1: this.addressLine1.value,
            cityOrTown: this.city.value,
            currency: 'USD',
            deliveryFee: shippingCharge as number,
            domain: this.session.checkoutDomain,
            email: this.email.value,
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            phoneNumber: this.phoneNumber.value,
            quantity: this.totalQuantity,
            state: this.state.value,
            type: 'SHIPMENT',
            variationId: this.cartItms[0].variationId,
            zipCode: this.zipcode.value,
        }

        this.orderService.createNewOrder(orderdata)
            .toPromise().then(async (order: ICreateOrderResponse) => {
                const paymentData: IPayment = {
                    amount_money: order.amount_money,
                    location_id: order.location_id,
                    source_id: undefined,
                    order_id: order.order_id,
                };

                await this.paymentService.handlePaymentMethodSubmission(this.paymentcard, paymentData, progressSpinnerDialogRef);
            })
            .catch(err => {
                progressSpinnerDialogRef.close();
                this.session.subscriptionErrorResponse = err;
                this.router.navigate([`/shops/checkout/failed`]);
            });
    }

    onExclusiveSessionSubscribe(progressSpinnerDialogRef: any) {

        // const progressSpinnerDialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
        //     panelClass: 'transparent',
        //     disableClose: true
        // });

        const cartItem = this.cartItms[0];
        const subscriptionData = {
            source_id: undefined,
            stream_status: cartItem.exclusiveStreamStatus,
            user_id: this.loggedInUserId,
            amount: cartItem.unitPrice,
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            phoneNumber: this.phoneNumber.value,
            state: this.state.value,
            addressLine1: this.addressLine1.value,
            addressLine2: undefined,
            cityOrTown: this.city.value,
            zipCode: this.zipcode.value,
            email: this.email.value,
        };


        this.paymentService
            .tokenize(this.paymentcard)
            .then((token: any) => {

                subscriptionData.source_id = token;

                this.orderService.subscribeExclusiveSession(subscriptionData, cartItem.itemId)
                    .toPromise()
                    .then(async (res: IExclusiveSessionSubscriptionResponse) => {
                        this.session.isSubscription = true;
                        this.session.subscriptionResponse = res;
                        progressSpinnerDialogRef.close();
                        this.cartService.clearCart();

                        // post message to opener window to close the subscription required popup
                        const message = { 'command': 'subscriptionRequired', 'uid': (new Date).getTime() + Math.random(), 'data': false };
                        localStorage.setItem('session-subscription', JSON.stringify(message));
                        localStorage.removeItem('session-subscription');

                        this.router.navigate([`/shops/checkout/success`]);
                    })
                    .catch(err => {
                        progressSpinnerDialogRef.close();
                        this.session.subscriptionErrorResponse = err;
                        this.router.navigate([`/shops/checkout/failed`]);
                    });
            })
            .catch(err => {
                progressSpinnerDialogRef.close();
                this.session.subscriptionErrorResponse = err;
                this.router.navigate([`/shops/checkout/failed`]);
            });
    }

    ngOnDestroy(): void {
        this.cartService.clearCart();
        this.subscriptions.unsubscribe();
    }

}
