import {
    Component,
    Input,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
    AfterContentInit
  } from '@angular/core';
  import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
  import { MatChip, MatChipList } from '@angular/material/chips';
  import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
  import { map } from 'rxjs/operators';
  
  @UntilDestroy()
  @Component({
    selector: 'app-chips-multi-select',
    templateUrl: '../../views/common/chips-multi-select.component.html',
    styleUrls: ['../../../assets/css/common/chips-multi-select.component.css'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: ChipsMultiSelectComponent,
        multi: true,
      },
    ],
  })
  export class ChipsMultiSelectComponent implements OnInit, AfterContentInit {
    
    @ViewChild(MatChipList)
    chipList!: MatChipList;
  
    @Input() options: any[] = [];
    @Input() activeOptions: any[] = [];
  
    value: string[] = [];
  
    onChange!: (value: string[]) => void;
    onTouch: any;
  
    disabled = false;
  

    @Output() toggleSelectionEvent = new EventEmitter<MatChip>();

    constructor() {}
  
    registerOnChange(fn: any): void {
      this.onChange = fn;
    }
  
    registerOnTouched(fn: any): void {
      this.onTouch = fn;
    }
  
    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }
  
    ngOnInit() {}
  
    ngAfterContentInit() {
      
    }
  
    toggleSelection(chip: MatChip) {
      this.toggleSelectionEvent.emit(chip);
      if (!this.disabled) {
        chip.toggleSelected();
      }
    }
  }