<div class="home-wrapper d-block w-100">
  <div id="exclusiveLiveSession" class="w-100 bg-white pl-80 pr-80 p-40 p-sm-20">
    <excl-live-session class="d-flex-row fxd-col-sm"></excl-live-session>
  </div>

  <live-demonstration-card-inhome></live-demonstration-card-inhome>

  <!-- <div id="trendingItemsSection" class="w-100 pl-80 pr-80 p-20 pl-sm-20 pr-sm-20 p-20-sm bg-F5F5F5">
    <mat-card class="p-0-i">
      <mat-card-header class="d-block w-100 p-15 pb-0" fxLayout="row" fxLayoutAlign="space-between">
        <mat-card-title class="h1-dark">
          <h3 class="m-0-i h1-dark">Trending</h3>
        </mat-card-title>
        <a routerLink="#" class="link-primary lh-23-i">View All</a>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="fx-wrap-sm p-5">
        <div class="d-flex-row fx-wrap-sm w-100 pstn-relative">
          <trending-items *ngFor="let ti of trendingItems" class="m-5 d-flex-col flex-auto p-0-i slider-card-item"
            [imageName]="ti.imageName" [description]="ti.description" [price]="ti.price">
          </trending-items>
        </div>
      </mat-card-content>
    </mat-card>
  </div> -->

  <picked-items></picked-items>
  <recently-viewed-items *ngIf="currentUser"></recently-viewed-items>
  <aired-items></aired-items>
</div>