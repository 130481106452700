<div>
  <div class="d-flex" fxLayout="row" fxLayoutAlign="space-between">
    <h2 mat-dialog-title>Project</h2>
    <button
      *ngIf="isOwner && showEdit"
      (click)="editProject()"
      mat-button
      class="tt-upper text-primary ml-auto-i"
    >
      <span>EDIT</span>
    </button>
  </div>
  <mat-spinner
    diameter="20"
    class="color-primary"
    [class.hidden]="project"
  ></mat-spinner>
  <div *ngIf="project">
    <div class="fs-14 fw-500 pt-20 pb-10" style="color: #000000">
      {{ project.name }}
    </div>
    <div class="d-flex" style="gap: 8px">
      <video
        style="border-radius: 8px"
        width="330px"
        height="250px"
        autoplay
        controls
      >
        <source src="{{ videoPath }}" type="video/mp4" />
      </video>
      <img
        style="width: 330px; height: 250px; border-radius: 8px"
        class="w-100"
        src="{{ project.thumbnail }}"
      />
    </div>
    <div *ngIf="isRetailer && showInvitation">
      <div class="fs-14 fw-500 pt-20 pb-10">Choose your Product</div>
      <div
        class="d-flex flex-wrap"
        style="max-width: 660px; justify-content: space-between; gap: 8px"
      >
        <div
          [class.hidden]="!productFetchProgress"
          class="mat-dialog-actions pstn-relative"
        >
          <mat-spinner diameter="25" class="color-primary t24 l4">
          </mat-spinner>
        </div>

        <div
          style="width: 121px; border-radius: 8px; cursor: pointer"
          *ngFor="let item of productList; let i = index"
          [ngStyle]="{
            border: selectedProductId === item.id ? '1px #860093 solid' : 'none'
          }"
          (click)="onProductSelectionChange(item)"
        >
          <img
            style="width: 119px; height: 90px; border-radius: 8px"
            src="{{ item.imagePath }}"
          />
          <div
            class="fs-12 fw-500 pl-10"
            style="
              border: 1px rgba(0, 0, 0, 0.12) solid;
              border-top: 0;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            "
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="mat-dialog-actions pstn-relative m-0-24-neg p-0-24-i bt-s1-i bc-00012-i mt-15"
    *ngIf="isRetailer"
  >
    <mat-spinner
      diameter="25"
      class="color-primary pstn-absolute t24 l4"
      [class.hidden]="true"
    >
    </mat-spinner>
    <button
      mat-button
      class="tt-upper text-primary ml-auto-i"
      (click)="closeDialog()"
    >
      <span>Cancel</span>
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="tt-upper ml-0-i"
      disabled="{{ false }}"
      (click)="createNotification()"
      *ngIf="showInvitation"
    >
      <span>Send Invitation</span>
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="tt-upper ml-0-i"
      disabled="{{ false }}"
      (click)="goToPayment()"
      *ngIf="showPayment"
    >
      <span>Payment</span>
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="tt-upper ml-0-i"
      disabled="{{ false }}"
      (click)="goToCreateStream()"
      *ngIf="showCreateStream"
    >
      <span>Create Stream</span>
    </button>
  </div>
</div>
