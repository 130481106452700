<div
  class="page-wrapper w-100 h-100 d-flex pstn-absolute-b0-l0"
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <div class="page-banner w-40 h-100 bg-primary d-inline-block hidden-sm">
    <div class="d-block w-100">
      <a mat-icon-button class="m-10-i d-block" href="{{ homeRoute }}">
        <mat-icon class="text-white">arrow_back</mat-icon>
        <span class="h1-white ml-10">Back to Marketplace</span>
      </a>
    </div>
    <div class="w-100 ta-center">
      <img src="./assets/images/shopping_bg.png" class="w-75 m-auto mt-60" />
    </div>
    <div class="pstn-absolute-b0-l0 ml-35 mb-30">
      <h1 class="h1-white mb-25">Contact</h1>
      <span class="d-block h3-dwhite">+1 425 280 3473</span>
      <span class="d-block h3-dwhite">info@macurian.shop</span>
      <span class="d-block h3-dwhite mt-30">Follow us on</span>
      <div class="d-block mt-10">
        <img
          class="facebook_icon mr-10"
          height="25px"
          src="./assets/images/facebook_icon.png"
        />
        <img
          class="instagram_icon mr-10"
          height="22px"
          src="./assets/images/instagram_icon.png"
        />
        <img
          class="twitter_icon mr-10"
          height="21px"
          src="./assets/images/twitter_icon.png"
        />
        <img
          class="youtube_icon mr-10"
          height="20px"
          src="./assets/images/youtube_icon.png"
        />
        <img
          class="pinterist_icon"
          height="25px"
          src="./assets/images/pinterist_icon.png"
        />
      </div>
    </div>
  </div>
  <div
    class="page-form w-60 w-100-sm h-100 bg-white d-inline-block pstn-relative"
  >
    <div class="form-wrapper">
      <div
        class="dialog-form-overlap pstn.abolute-100h-100h p-140 pt-50 pb-0-i p-sm-30"
      >
        <div class="form-content form-light mw-400px">
          <a href="{{ homeRoute }}">
            <img src="../../assets/images/marketplace-logo.png" />
          </a>
          <form
            class="mw-400px"
            id="registerForm"
            name="registerForm"
            [formGroup]="registerForm"
            (ngSubmit)="onSubmit()"
          >
            <h1 class="h1-black mt-20-i">Sign up for a new account</h1>
            <mat-tab-group
              mat-align-tabs="center"
              class="mb-20"
              (selectedIndexChange)="updateSelectedRole($event)"
              [selectedIndex]="selectedTabIndex"
            >
              <mat-tab label="Consumer" class="link-primary tt-upper"></mat-tab>
              <mat-tab label="Retailer" class="link-primary tt-upper"></mat-tab>
            </mat-tab-group>
            <div class="row d-block mt-30">
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>First name</mat-label>
                  <input
                    matInput
                    placeholder="Firstname"
                    [formControl]="firstName"
                    required
                  />
                  <mat-error *ngIf="firstName.invalid"
                    >{{ getErrorMessage("firstName") }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Last name</mat-label>
                  <input
                    matInput
                    placeholder="Lastname"
                    [formControl]="lastName"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row d-block">
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    placeholder="email@example.com"
                    [formControl]="email"
                    required
                  />
                  <mat-error *ngIf="email.invalid">{{
                    getErrorMessage("email")
                  }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row d-block">
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Create Password</mat-label>
                  <input
                    matInput
                    placeholder="Create Password"
                    [formControl]="password"
                    type="password"
                    required
                  />
                  <mat-hint class="mb-10 hidden"
                    >Minimum 8 characters, include uppercase, lowercase, numbers
                    and a special charecter.</mat-hint
                  >
                  <mat-error *ngIf="password.invalid"
                    >{{ getErrorMessage("password") }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Confirm password</mat-label>
                  <input
                    matInput
                    placeholder="Confirm password"
                    [formControl]="confirmPassword"
                    type="password"
                    required
                  />
                  <mat-error *ngIf="confirmPassword.invalid">
                    {{ getErrorMessage("confirmPassword") }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row d-block mt-20-i" [class.hidden]="role.value != '1'">
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Store Name</mat-label>
                  <input
                    matInput
                    placeholder="Store name"
                    [formControl]="storeName"
                    [ngClass]="{ 'is-invalid': inProgress && storeName.errors }"
                    required
                  />
                  <mat-error *ngIf="storeName.invalid">{{
                    getErrorMessage("storeName")
                  }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Domain Name</mat-label>
                  <input
                    matInput
                    placeholder="Domain name"
                    [formControl]="domainName"
                    [ngClass]="{
                      'is-invalid': inProgress && domainName.errors
                    }"
                    required
                  />
                  <mat-error *ngIf="domainName.invalid">{{
                    getErrorMessage("domainName")
                  }}</mat-error>
                  <mat-hint class="mb-10"
                    >Unique name to be used for store link (spaces and special
                    characters not allowed)</mat-hint
                  >
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Address</mat-label>
                  <input
                    matInput
                    placeholder="Address"
                    [formControl]="addressLine1"
                    [ngClass]="{
                      'is-invalid': inProgress && addressLine1.errors
                    }"
                    required
                  />
                  <mat-error *ngIf="addressLine1.invalid">{{
                    getErrorMessage("addressLine1")
                  }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>State</mat-label>
                  <input
                    matInput
                    placeholder="State"
                    [formControl]="state"
                    [ngClass]="{
                      'is-invalid': inProgress && state.errors
                    }"
                    required
                  />
                  <mat-error *ngIf="state.invalid">{{
                    getErrorMessage("state")
                  }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>City</mat-label>
                  <input
                    matInput
                    placeholder="City"
                    [formControl]="cityOrTown"
                    [ngClass]="{
                      'is-invalid': inProgress && cityOrTown.errors
                    }"
                    required
                  />
                  <mat-error *ngIf="cityOrTown.invalid">{{
                    getErrorMessage("cityOrTown")
                  }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Zip</mat-label>
                  <input
                    matInput
                    placeholder="Zip"
                    [formControl]="zipCode"
                    [ngClass]="{
                      'is-invalid': inProgress && zipCode.errors
                    }"
                    required
                  />
                  <mat-error *ngIf="zipCode.invalid">{{
                    getErrorMessage("zipCode")
                  }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 ta-left">
                <mat-checkbox [checked]="isCreator" [formControl]="isCreator"
                  >Content Creator</mat-checkbox
                >
              </div>
            </div>
            <div class="row d-block pb-20 pt-20">
              <div class="col-12 ml-5 ta-left">
                <label class="sign-terms h3-dark"
                  >By creating an account, I agree to MarketPlace0
                  <a href="/termsofservice">Terms of Service</a> and
                  <span class="privacy-policy">
                    <a href="/privacy">Privacy Policy</a>
                  </span>
                </label>
              </div>
            </div>
            <div class="row d-block p-b-20">
              <div class="col-12 ml-5 mr-5">
                <div class="md-form ta-left">
                  <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    id="button-form-submit"
                    class="pstn-relative w-95-i tt-upper pt-5-i pb-10-i"
                  >
                    <mat-spinner
                      diameter="20"
                      class="color-white pstn-absolute t24 l35"
                      [class.hidden]="!inProgress"
                    ></mat-spinner>
                    <span>Sign Up</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row d-block pb-20 pt-20">
              <div class="col-12 ml-5 ta-left">
                <label class="h3-dark-wl"
                  >Already have an account?
                  <a href="/login" class="sign-in">Log in</a>
                </label>
              </div>
            </div>
          </form>
        </div>
        <div
          class="pstn-absolute-b0-l0 ml-35 mw-400px m-auto-sm mb-30 hidden d-block-sm pstn-sm-relative"
        >
          <h1 class="h1-white mb-25 clr-sm-dark">Contact</h1>
          <span class="d-block h3-dwhite clr-sm-ddark">+1 425 280 3473</span>
          <span class="d-block h3-dwhite clr-sm-ddark">info@macurian.shop</span>
          <span class="d-block h3-dwhite mt-30 mt-sm-15 clr-sm-ddark"
            >Follow us on</span
          >
          <div class="d-block mt-10 clr-sm-primary mb-sm-15">
            <img
              class="facebook_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="25px"
              src="./assets/images/facebook_icon.png"
            />
            <img
              class="instagram_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="22px"
              src="./assets/images/instagram_icon.png"
            />
            <img
              class="twitter_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="21px"
              src="./assets/images/twitter_icon.png"
            />
            <img
              class="youtube_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="20px"
              src="./assets/images/youtube_icon.png"
            />
            <img
              class="pinterist_icon bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="25px"
              src="./assets/images/pinterist_icon.png"
            />
          </div>
        </div>
        <div class="signup-footer pstn-sm-relative ta-sm-center">
          <p class="h4-dark">
            Copyright &copy; 2021 Macurian Inc. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
