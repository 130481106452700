import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { IItemCategory } from 'src/app/models';
import { ISearchResultDetail } from 'src/app/models/search-result-detail.model';
import { ItemService } from 'src/app/services';
import { SearchService } from 'src/app/services/search-service';

@Component({
  selector: 'search-page',
  templateUrl: '../../views/search/search.component.html',
})
export class SearchComponent implements OnInit, OnDestroy {
  searchKeyword: string;
  searchResult: ISearchResultDetail;
  loadingResult: boolean = true;
  productNatures: IItemCategory[];
  private subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private itemService: ItemService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.searchKeyword = params['q'];
        this.loadSearchResult(this.searchKeyword);
      })
    );
  }

  loadSearchResult(searchKeyword: string) {
    this.itemService.categories().subscribe((productNatures: any) => {
      this.searchService
        .searchShopsScheduleBy(searchKeyword)
        .toPromise()
        .then((shopsandschedues) => {
          this.productNatures = productNatures;
          this.searchResult = shopsandschedues;
          this.loadingResult = false;
        });
    });
  }

  combineBackendApiUrl(relativePath: string, isLogo?: boolean): string {
    if (isLogo && !relativePath) return '/assets/images/nologo.jpg';
    if (!relativePath) return '/assets/images/store_shop_banner.png';
    return relativePath;
  }

  getProductNatureNameById(id: string) {
    const category = this.productNatures.find((x) => x.id === id);
    if (!category) return '';
    return category.category;
  }

  parseStreamStatus(
    statusEnumValue: StreamStatusEnum,
    startTime: Date
  ): string {
    switch (statusEnumValue) {
      case StreamStatusEnum.IN_PROGRESS:
        return 'On Air';
      case StreamStatusEnum.PENDING:
        return this.datePipe.transform(startTime, 'hh:mm a MMM yy');
    }
  }

  hasInProgressStatus(statusEnumValue: StreamStatusEnum): boolean {
    return statusEnumValue == StreamStatusEnum.IN_PROGRESS;
  }

  parseSessionUrlPath(sessionType: string) {
    if (sessionType.toLowerCase().indexOf('showcase') > -1) return 'sessions';

    return 'sessions/exclusive';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
