<form id="payment-form" name="registerForm" [formGroup]="checkoutForm" (ngSubmit)="onCheckoutSubmit()">
    <div class="w-100 pl-70 pr-70 pt-20 pl-20-sm pr-20-sm">
        <div class="mb-15 d-flex pstn-relative">
            <label class="text-0087 fs-24 fw-500 mr-5">Checkout</label>
            <mat-spinner diameter="20" class="color-primary pstn-absolute-t0-r0-i mr-30" [class.hidden]="!loadingRetailerLocationInfo"></mat-spinner>
        </div>
    </div>
    <div class="w-100 pl-70 pr-70 pt-15 pb-15 pl-20-sm pr-20-sm">
        <div class="d-flex-row flex-1 d-block-sm ff-roboto">
            <div class="w-60 mr-10 w-100-sm">
                <mat-card class="p-0-i mat-elevation-z1 w-100">
                    <mat-card-header class="w-100 p-20 pt-15 pb-5 mb-5 mat-elevation-z1">
                        <mat-card-title class="text-0087 fs-18 fw-500 lh-24 fs-16-sm lh-28-sm">Shipping Information
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="d-flex-i mb-0-i">
                        <div class="d-flex-col w-100 p-15">
                            <a *ngIf="!isAuthenticated" class="d-flex  jc-fx-end link-primary fs-14 fw-500 lh-24 pb-15" href="/login?returnUrl=/shops/checkout">Log In to your account</a>

                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>First Name</mat-label>
                                <input matInput placeholder="First name" [formControl]="firstName">
                                <mat-error *ngIf="firstName.invalid" [class.invalid]="firstName.invalid">First name is required</mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Last Name</mat-label>
                                <input matInput placeholder="Last name" [formControl]="lastName">
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Address Line 1</mat-label>
                                <textarea matInput placeholder="Address line 1" [formControl]="addressLine1"></textarea>
                                <mat-error *ngIf="addressLine1.invalid" [class.invalid]="addressLine1.invalid">Address line1 is required</mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Address Line 2</mat-label>
                                <textarea matInput placeholder="Address line 2" [formControl]="addressLine2"></textarea>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>State</mat-label>
                                <input matInput placeholder="State" [formControl]="state">
                                <mat-error *ngIf="state.invalid" [class.invalid]="state.invalid">State is required</mat-error>
                            </mat-form-field>
                            <div class="w-100 d-flex-row">
                                <mat-form-field class="w-50 pr-10" appearance="outline">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City" [formControl]="city">
                                    <mat-error *ngIf="city.invalid" [class.invalid]="city.invalid">City is required</mat-error>
                                </mat-form-field>
                                <mat-form-field class="w-50 pl-10" appearance="outline">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput #postalCode maxlength="5" placeholder="Ex. 94105" [formControl]="zipcode">
                                    <mat-error *ngIf="zipcode.invalid" [class.invalid]="zipcode.invalid">Zipcode is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="p-0-i mat-elevation-z1 w-100 mt-15">
                    <mat-card-header class="w-100 p-20 pt-15 pb-5 mb-5 mat-elevation-z1">
                        <mat-card-title class="text-0087 fs-18 fw-500 lh-24 fs-16-sm lh-28-sm">Contact Information
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="d-flex-i mb-0-i">
                        <div class="d-flex-col w-100 p-15">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="Email" [formControl]="email">
                                <mat-error *ngIf="email.errors?.required" [class.invalid]="email.invalid">Email is required</mat-error>
                                <mat-error *ngIf="email.errors?.pattern" [class.invalid]="email.invalid">Email pattern is invalid!</mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Phone No</mat-label>
                                <input matInput placeholder="Phone no" [formControl]="phoneNumber">
                                <mat-error *ngIf="phoneNumber.invalid" [class.invalid]="phoneNumber.invalid">Phone number is required</mat-error>
                            </mat-form-field>
                            <section class="example-section" *ngIf="!isAuthenticated">
                                <mat-checkbox class="fw-500">Create an account</mat-checkbox>
                            </section>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="w-35 ml-25 d-flex-row w-100-sm d-block-sm ml-0-sm mt-20-sm">
                <mat-card class="p-0-i mat-elevation-z1 w-100">
                    <mat-card-header class="w-100 p-20 pt-15 pb-5 mb-5 mat-elevation-z1">
                        <mat-card-title class="text-0087 fs-18 fw-500 lh-24 fs-16-sm lh-28-sm">Order Summary
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="d-flex-col mb-0-i ff-roboto p-20">
                        <mat-list class="d-flex-col w-100 fs-14 lh-24 text-0087">
                            <mat-list-item class="d-flex-row w-100 fs-14 lh-24 text-0087 mb-15" *ngFor="let item of cartItms">
                                <span class="d-inline">{{item.name}}</span>
                                <span class="d-inline fw-500 ml-auto">{{item.unitPrice/100 | currency}}</span>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list>
                        <mat-list class="d-flex-col w-100 fs-14 lh-24 text-0087">
                            <mat-list-item class="d-flex-row w-100 fs-14 lh-24 text-0087 mb-15 mt-15">
                                <span class="d-inline">Subtotal ({{totalQuantity}} items)</span>
                                <span class="d-inline fw-500 ml-auto text-0006">{{subtotal/100 | currency}}</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="!isExclusiveSession" class="d-flex-row w-100 fs-14 lh-24 text-0087 mb-15 mt-15">
                                <span class="d-inline">Shipping Fee</span>
                                <span class="d-inline fw-500 ml-auto text-0006">{{deliveryFee?.deliveryFee |
                                    currency}}</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="!isExclusiveSession" class="d-flex-row w-100 fs-14 lh-24 text-0087 mb-15">
                                <span class="d-inline">Tax</span>
                                <span class="d-inline fw-500 ml-auto text-0006">{{cartItms | tax | currency}}</span>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list>
                        <mat-list class="d-flex-col w-100 fs-14 lh-24 text-0087">
                            <mat-list-item class="d-flex-row w-100 fw-500 fs-18 lh-24 text-0087 mb-15 mt-15">
                                <span class="d-inline">Total</span>
                                <span class="d-inline ml-auto text-0006">{{orderTotal | currency}}</span>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list>
                        <!-- <mat-list class="d-flex-col w-100 fs-14 lh-24 text-0087">
                            <mat-list-item class="d-flex-row w-100 fw-500 fs-14 lh-24 text-0087 mb-15 mt-15 jc-center">
                                <span class="d-inline w-100 ta-center link-primary">Apply a Discount Code</span>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list> -->
                        <mat-list class="d-flex-col w-100 fs-14 lh-24 text-0087">
                            <mat-list-item class="d-flex-row w-100 fw-500 fs-18 lh-24 text-0087 mb-15 mt-15">
                                <span class="d-inline">Payment Information</span>
                            </mat-list-item>
                            <div class="d-block w-100">
                                <div id="card-container"></div>
                            </div>
                            <mat-list-item class="d-block">
                                <p class="text-0087 fs-12 lh-24 d-flex-row">
                                    <mat-icon class="text-6BBE48 fs-16-i">lock</mat-icon>
                                    <span class="lh-20">SSL Secure Transaction</span>
                                </p>
                            </mat-list-item>
                            <mat-list-item class="d-block">
                                <button id="card-button" mat-raised-button color="primary" type="submit" class="pstn-relative w-100 tt-upper pt-15 pb-15">
                                    <mat-spinner diameter="20" class="color-white pstn-absolute t24 l35"
                                        [class.hidden]="!inProgress"></mat-spinner>
                                    <span *ngIf="!isExclusiveSession">Place Order</span>
                                    <span *ngIf="isExclusiveSession">Buy Subscription</span>
                                </button>
                            </mat-list-item>
                            <mat-list-item class="d-block">
                                <p class="text-0087 fs-12 lh-16 d-flex-row pt-10">
                                    <span>By completing your purchase you agree to the <a class="text-primary">Terms of
                                            Service</a></span>
                                </p>
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</form>