import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IItem } from 'src/app/models/item.model';
import { IItemCategory } from 'src/app/models/item-categories.model';

export interface AddShowcaseProductDialogData {
    data: IItem[];
    scheduleId: string;
}


@Component({
    selector: 'add-showcase-product-dialog',
    templateUrl: '../../views/store/add-showcase-product.dialog.component.html',
    styleUrls: ['../../../assets/css/store/add-showcase-product.dialog.component.css']
})
export class AddShowcaseProductDialogComponent implements OnInit {

    inProgress: boolean = false;
    categoryProgress: boolean = true;

    productList: IItem[] = [];

    selection: IItem[] = [];

    allCategories: IItemCategory[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddShowcaseProductDialogComponent>,
        public itemService: ItemService,
        public authService: AuthenticationService,
        public accountService: AccountService,
        public retailerStoreService: RetailerStoreService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: AddShowcaseProductDialogData) {
        this.productList = data.data;
        this.productList.forEach( (prod) =>{
            if(prod['is_showcase']){
                this.selection.push(prod)
            }
        })
    }

    ngOnInit(): void {
      this.getAllCategories()
    }

    onCancelClick() {
        this.dialogRef.close();
    }

    isAllSelected() {
        const numSelected = this.selection.length;
        const numRows = this.productList.length;
        return numSelected === numRows;
    }

    masterToggle() {

        var $table: any = document.getElementById('showcaseallItems');
        var $rows: any[] = $table.rows;

        if (this.isAllSelected()) {
            this.selection = [];
            Array.prototype.forEach.call($rows, (row:any) => {
                row.classList.remove('selected');
            });
            return;
        }

        this.selection = this.productList.map(function(item){return item;});
        Array.prototype.forEach.call($rows, (row:any) => {
            row.classList.add('selected');
        });
    }

    toggleSelection(row: IItem, $event: any) {

       var index = this.selection.some( (item) =>{
            return item.id === row.id
        });

        let NotCheckEvent = $event?.target?.closest('tr');
        var currentTarget = NotCheckEvent || document.getElementById($event.source.id).parentElement.parentElement;

        if (!index) {
            this.selection.push(row);
            currentTarget.classList.add('selected');
            if( NotCheckEvent ){
                this.productList = this.productList.map((prod) =>{
                    if( prod.id === row.id ){
                        return { ...prod, is_showcase : true }
                    }
                    return { ...prod }
                })
            }
            return
        }

        if( NotCheckEvent ){
            this.productList = this.productList.map((prod) =>{
                if( prod.id === row.id ){
                    return { ...prod, is_showcase : false }
                }
                return { ...prod }
            })
        }

        this.selection = this.selection.filter( (item) =>{
            return item.id !== row.id
        })
        currentTarget.classList.remove('selected');
    }

    isSelected(row: IItem) {
        var index = this.selection.some( (item) =>{
            return item.id === row.id
        });
        return index;
    }

    addSelectionToStream(): void {
        if (this.selection.length == 0) {
            this.snackBar.open('No item selected to add!', 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
            return;
        }

        this.inProgress = true;
        const productIds = this.selection.map((item) => { return item.id; });

        const userId = this.authService.currentUserId;
        this.retailerStoreService.updateSheduleShowcaseProduct(userId, this.data.scheduleId, productIds)
            .toPromise()
            .then((res: any) => {
                this.inProgress = false;
                this.snackBar.open('Product showcase updated', 'close', { duration: 10000, panelClass: ['mat-success-bg'] });
                this.dialogRef.close();
            })
            .catch((err: HttpErrorResponse) => {
                this.inProgress = false;
                const errorDetail = err.error.error || err.error || err.statusText;
                this.snackBar.open(errorDetail, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
            });
    }

    getAllCategories(){
        this.itemService.categories().subscribe( (res:any) => {
            this.allCategories = res;
            this.mapCategoryTitle()
        })
    }

    mapCategoryTitle() {
        this.productList = this.productList.map( (list) => {
            let category = this.allCategories.find( (cat) => {
                return cat.id === list.category_id
            })
            return { ...list,'category':category.category  };
        })
        this.categoryProgress = false;
    }
}
