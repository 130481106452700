import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IConversation } from '../models/conversession.model';

@Injectable({
    providedIn: 'root'
})
export class ConversessionService {

    protected apiServer = AppConfig.settings.apiServer;
    protected localStorageKeys = AppConfig.settings.localStorage.keys;

    constructor(private http: HttpClient) { }

    getConversessionsByScheduleId(scheduleId: string): Observable<IConversation[]> {
        const apiurl = `${this.apiServer.basepath}/users/schedule/${scheduleId}/comments`;
        return this.http.get<IConversation[]>(apiurl).pipe(
            map((res: IConversation[]) => {
                return res;
            }));
    }

    postConversationByScheduleIdUserId(scheduleId: string, userId: string, content: string): Observable<IConversation[]> {
        const apiurl = `${this.apiServer.basepath}/users/${userId}/schedule/${scheduleId}/comment`;
        return this.http.post<IConversation[]>(apiurl, { content: content }).pipe(
            map((res: IConversation[]) => {
                return res;
            }));
    }
}