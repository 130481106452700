import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ICreatePaymentResponse } from 'src/app/models/create-payment-response.model';
import { IExclusiveSessionSubscriptionResponse } from 'src/app/models/exclusive-session-subscription-response.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
    selector: 'checkout-success',
    templateUrl: '../../views/shops/checkout-success.shops.component.html',
    styleUrls: ['../../../assets/css/shops/checkout.shops.component.css']
})
export class CheckoutSuccessComponent implements OnInit {

    pr: ICreatePaymentResponse;
    isSubscription:boolean = false;
    sbpn: IExclusiveSessionSubscriptionResponse;

    constructor(
        private session: SessionService,
        private datePipe: DatePipe) { }

    ngOnInit(): void {
        if (this.session.isSubscription){
            this.isSubscription = this.session.isSubscription;
            this.sbpn = this.session.subscriptionResponse;
            return;
        }
        this.pr = this.session.paymentresponse;
    }

}