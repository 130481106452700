import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleEnum } from 'src/app/enums/role.enum';
import { IOrderDetail } from 'src/app/models/order-detail.model';
import { AuthenticationService } from 'src/app/services';
import { OrderService } from 'src/app/services/order.service';

@Component({
    selector: 'order-detail',
    templateUrl: '../../views/common/order-detail.component.html',
})
export class OrderDetailComponent implements OnInit, OnDestroy {

    isRetailer: boolean;
    retailerDomain: string;
    orderId: string;
    orderDetail: IOrderDetail;
    taxRate: number = 2;
    loadingOrderDetailInfo: boolean = true;
    orderStatusCode: any;

    constructor(
        private activeRoute: ActivatedRoute,
        private authService: AuthenticationService,
        private orderService: OrderService) { }

    ngOnInit() {
        this.isRetailer = this.authService.currentUserRole == RoleEnum.retailer;
        this.retailerDomain = this.activeRoute.parent.snapshot.paramMap.get('domain');
        this.orderId = this.activeRoute.snapshot.paramMap.get('orderId');
        this.orderService.getOrderDetailByOrderId(this.orderId)
            .toPromise()
            .then(res => {
                this.orderDetail = res;
                this.loadingOrderDetailInfo = false;
            });
    }

    get orderTotal() {
        const subtotal = this.orderDetail?.quantity * this.orderDetail?.base_price_money.amount;
        const taxAmount = subtotal * this.taxRate / 100;
        const total = subtotal + this.orderDetail?.tax.amount + this.orderDetail?.delivery_fee.amount; // + this.deliveryFee?.deliveryFee || 0;
        return total;
    }

    onActionSelectionChanged(event$) {
        this.loadingOrderDetailInfo = true;
        const changedStatus = parseInt(event$.value);
        const data = {
            "location_id": this.orderDetail.location_id,
            "order_status_code": changedStatus,
            "type": this.orderDetail.type,
            "version": this.orderDetail.version,
            "uid": this.orderDetail.uid
        };


        this.orderService
            .updateOrderStatusByOrderIdStatusCode(this.orderId, data)
            .toPromise()
            .then(res => {
                this.orderDetail = res;
                this.loadingOrderDetailInfo = false;
            })
            .catch(err => {
                this.loadingOrderDetailInfo = false;
            });
    }

    ngOnDestroy(): void { }
}