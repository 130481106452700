import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JWTTokenService } from './jwt-token.service';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private jwtTokenService: JWTTokenService,
        private router: Router) { }

    canActivate(): boolean {
        if (this.jwtTokenService.isRefreshTokenExpired()) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}