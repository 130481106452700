import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { HttpErrorResponse } from '@angular/common/http';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { AppConfig } from 'src/app/app.config';

@Component({
    selector: 'create-stream-dialog',
    templateUrl: '../../views/store/create-stream.dialog.component.html',
    styleUrls: ['../../../assets/css/store/create-stream.dialog.component.css']
})
export class CreateStreamDialogComponent implements OnInit {

    public Editor = ClassicEditor;

    thumbnailPicture: File;
    thumbnailImageFileError:string;


    inProgress: boolean = false;

    createStreamForm: FormGroup;

    streamType = new FormControl('showcase', [Validators.required]);
    streamTitle = new FormControl('', [Validators.required]);
    scheduleDate = new FormControl('', [Validators.required]);
    startTime = new FormControl('', [Validators.required]);
    streamDuration = new FormControl('', [Validators.required]);
    streamDurationOther = new FormControl('', []);

    subscriptionPrice = new FormControl('', []);
    description;

    streamThumbnail: File;
    streamThumbnailIsInvalid: boolean = false;

    streamDurationList = new Set(['10m', '15m', '20m', '45m', '60m', '90m', 'other']);
    streamDurationNotOther: boolean = true;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<CreateStreamDialogComponent>,
        public itemService: ItemService,
        public authService: AuthenticationService,
        public accountService: AccountService,
        public retailerStoreService: RetailerStoreService,
        private snackBar: MatSnackBar) {
        this.createStreamForm = this.formBuilder.group({
            streamType: this.streamType,
            streamTitle: this.streamTitle,
            scheduleDate: this.scheduleDate,
            startTime: this.startTime,
            streamDuration: this.streamDuration,
            streamDurationOther: this.streamDurationOther,
            streamThumbnail: new FormControl('', [])
        });
    }

    ngOnInit(): void { }

    addDurationFromInput(event: MatChipInputEvent) {
        if (event.value) {
            this.streamDurationList.add(event.value);
            (event as any).chipInput!.clear();
        }
    }

    updateSelectedDuration(duration: string) {
        this.streamDuration.setValue(duration);
        this.streamDurationNotOther = true;
        if (duration.indexOf('other') > -1) {
            this.streamDurationNotOther = false;
        }
    }

    getErrorMessage(fieldKey: string) {

        if ((this.streamType.value as string).indexOf('exclusive') > -1) {
            if (!this.subscriptionPrice.value) {
                this.subscriptionPrice.setErrors({required: true}); 
            }
        }

        switch (fieldKey) {
            case 'streamType':
                if (this.streamType.hasError('required')) return 'Stream type is required';
                break;
            case 'streamTitle':
                if (this.streamTitle.hasError('required')) return 'Stream title is required';
                break;
            case 'scheduleDate':
                if (this.scheduleDate.hasError('required')) return 'Schedule date is required';
                break;
            case 'startTime':
                if (this.startTime.hasError('required')) return 'Start time is required';
                break;
            case 'streamDuration':
                if (this.streamDuration.hasError('required')) return 'Stream duration is required';
                break;
            case 'streamDurationOther':
                if ((this.streamDuration.value as string).indexOf('other') > -1) {
                    if (this.streamDurationOther.hasError('required')) return 'Stream duration other is requred';
                }
                break;
            case 'subscriptionPrice':
                if (this.subscriptionPrice.hasError('required')) return 'Subscription price is requred';
                break;
        }
    }

    onStreamThumbnailChange($event) {
        this.thumbnailImageFileError = '';

        if ($event.target.files.length > 0) {
            const file = $event.target.files[0];

            this.streamThumbnailIsInvalid = false;

            const maxSize = AppConfig.settings.appConfig.imageFileMaxSize;
            const fileSize = file.size / 1024;
            if (fileSize > maxSize) {
                this.thumbnailImageFileError = `Thumbnail picture file size can not exceed more than ${maxSize}KB`;
                return;
            }

            this.streamThumbnail = file;
        }else{
            this.streamThumbnailIsInvalid = true;
        }
    }

    onCancelClick() {
        this.dialogRef.close();
    }

    onEditorChange( { editor }: ChangeEvent ) {
        const data = editor.getData();
        this.description = data;
    }

    onSubmit() {
        this.streamThumbnailIsInvalid = false;
        if (!this.streamThumbnail) {
            this.streamThumbnailIsInvalid = true;
            return;
        };


        this.inProgress = true;
        let formdata = new FormData();
        formdata.append('title', this.streamTitle.value);
        formdata.append('type', this.streamType.value);

        const startTime = new Date(new Date(this.scheduleDate.value).toDateString() + ' ' + this.startTime.value).toJSON();
        formdata.append('startTime', startTime);

        let duration: string = this.streamDuration.value;
        if (duration.indexOf('other') > -1) {
            duration = this.streamDurationOther.value;
        }
        duration =  (typeof duration === 'string') ? duration.replace('m', '') : duration ;
        formdata.append('streamDuration', duration);

        formdata.append('streamThumbnail', this.streamThumbnail);

        if ((this.streamType.value as string).indexOf('exclusive') > -1) {
            formdata.append('description', this.description);
            formdata.append('amount', (Number.parseFloat(this.subscriptionPrice.value) * 100).toString());
        }

        const userId = this.authService.currentUserId;
        this.retailerStoreService.createStream(userId, formdata)
            .toPromise()
            .then((res: any) => {
                this.inProgress = false;
                this.snackBar.open('New stream schedule is created', 'close', { duration: 10000, panelClass: ['mat-success-bg'] });
                this.dialogRef.close({created: true, streamId: res.data.streamId});
            })
            .catch((err: HttpErrorResponse) => {
                this.inProgress = false;
                const errorDetail = err.error.error || err.error || err.statusText;
                this.snackBar.open(errorDetail, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
            });
    }
}
