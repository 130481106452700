import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { IConsumer, IRetailer } from 'src/app/models';
import { AccountService, RouteService } from 'src/app/services';

import { MustMatch } from '../../helpers/validators/must-match.validator';

@Component({
  selector: 'app-signup',
  templateUrl: '../../views/signup/signup.component.html',
  styleUrls: ['../../../assets/css/signup/signup.component.css'],
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  inProgress = false;
  retailerOnly: boolean = false;
  returnUrl: string;
  selectedTabIndex: any = 0;

  firstName = new FormControl('', [Validators.required])
  lastName = new FormControl('', [])
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]);
  confirmPassword = new FormControl('', [Validators.required, Validators.minLength(8)]);
  storeName = new FormControl('');
  domainName = new FormControl('');
  role = new FormControl('0');

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private routeService: RouteService,
    private snackBar: MatSnackBar,
    public router:Router
    ) {
      let setState = this.router.getCurrentNavigation().extras.state;
      if( setState !== undefined && setState.key === 'sell' ) {
        this.selectedTabIndex = 1;
        this.role.setValue(1)
      }else{
        this.selectedTabIndex = 0;
      }
      
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
      confirmPassword: this.confirmPassword,
      storeName: [''],
      domainName: this.domainName
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    if( this.role.value === 1 ){
      this.updateStoreNameDomainNameValidation();
    }
  }

  getErrorMessage(fieldKey: string) {
    switch (fieldKey) {
      case 'email':
        if (this.email.hasError('required')) return 'Email is required';
        return this.email.hasError('email') ? 'Not a valid email' : '';
        break;
      case 'firstName':
        if (this.firstName.hasError('required')) return 'First name is required';
        break;
      case 'password':
        if (this.password.hasError('required')) return 'Password is required';
        if (this.password.errors.minLength) return 'Minimum 8 characters, include uppercase, lowercase, numbers and a special charecter.';
        if (this.password.errors.pattern) return 'Minimum 8 characters, include uppercase, lowercase, numbers and a special charecter.';
        break;
      case 'confirmPassword':
        if (this.confirmPassword.hasError('required')) return 'Confirm password is required';
        if (this.confirmPassword.errors.mustMatch) return 'Passwords must match';
        break;
      case 'domainName':
        if (this.domainName.hasError('required')) return 'Domain name is required';
        if (this.domainName.errors.pattern) return 'Not a valid domain.';
        break;
      case 'storeName':
        if (this.storeName.errors) return 'Store name is required';
        break;
    }
  }

  updateSelectedRole($event: any) {
    this.role.setValue($event)
    this.updateStoreNameDomainNameValidation();
  }

  onSubmit() {
    this.updateStoreNameDomainNameValidation();
    this.inProgress = true;
    if (this.registerForm.invalid) {
      this.inProgress = false;
      return;
    }

    if (this.role.value === 1) {
      let retailer: IRetailer = {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        email: this.email.value,
        passwordHash: this.password.value,
        storeName: this.storeName.value,
        domain: this.domainName.value,
        roles: ['retailer']
      };

      this.accountService.registerRetailer(retailer).toPromise<string>().then(msg => {
        // handle success response
        this.inProgress = false;

        // navigate successfull registration to verification page
        this.routeService.verificationData = {email: this.email.value};
        this.router.navigate(['/verify']);
      
      }).catch((err: HttpErrorResponse) => {
        // handle error response
        const errorDetail = err.error.error || err.error;
        this.inProgress = false;
        this.snackBar.open(errorDetail.error || errorDetail || 'Error!','Close',{duration: 10000, panelClass: ['mat-error-bg']});
      });

      return;
    }


    let consumer: IConsumer = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value,
      passwordHash: this.password.value,
      roles: ['consumer']
    };

    this.accountService.registerConsumer(consumer).toPromise<string>().then(msg => {
      // handle success response
      this.inProgress = false;

      // navigate successfull registration to verification page
      this.routeService.verificationData = {email: this.email.value};
      this.router.navigate(['/verify']);

    }).catch((err: HttpErrorResponse) => {
      // handle error response
      const errorDetail = err.error.error || err.error;
      this.inProgress = false;
      this.snackBar.open(errorDetail.error || errorDetail || 'Error!','Close',{duration: 10000, panelClass: ['mat-error-bg']});
    });
  }

  updateStoreNameDomainNameValidation() {
    let storeIsRequired = this.role.value == 1 && !this.storeName.value;
    let domainIsRequired = this.role.value == 1;

    if (storeIsRequired) {
      this.storeName.setErrors([Validators.required]);
    } else {
      this.storeName.setErrors(null);
    }

    if (domainIsRequired) {
      const domainRegEx = /^()?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      this.registerForm.controls.domainName.setValidators([Validators.required, Validators.pattern(domainRegEx)]);
      this.registerForm.controls.domainName.updateValueAndValidity();
    } else {
      this.registerForm.controls.domainName.setValidators(null)
      this.registerForm.controls.domainName.updateValueAndValidity();
    }
  }
}
