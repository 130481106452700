import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IDeliveryFee } from '../models/delivery-fee.model';
import { IItem } from '../models/item.model';
import { ILiveDemonstrationSchedule } from '../models/live-demonstration-schedule.model';
import { IPickedForYouSchedule } from '../models/picked-for-u-schedule.model';
import { IRecentlyAiredSchedule } from '../models/recently-aired-items.model';
import { IRetailerInfo } from '../models/retailer-info.model';
import { ISchedule } from '../models/schedule.model';
import { ISearchResultDetail } from '../models/search-result-detail.model';
import { ISpecialStream } from '../models/special-stream.model';
import { IUpcomingSchedule } from '../models/up-coming-items.model';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    protected apiServer = AppConfig.settings.apiServer;
    protected localStorageKeys = AppConfig.settings.localStorage.keys;

    constructor(private http: HttpClient) { }

    opts = [];

    searchShopsScheduleBy(searchKryword: string, type?:number) {

        var apiUrl = `${this.apiServer.basepath}/search/shops-schedules/${encodeURIComponent(searchKryword)}`;
        if (type) apiUrl += `?search_type=${type}`;
        
        return this.http.get<any>(apiUrl).pipe(tap(data => this.opts = data));
    }
}