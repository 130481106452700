import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'comingSoon',
  template: `<div class="page">
    <div class="wrapper">
      <h1>
        <img
          style="width: 300px"
          src="./assets/images/mpz-logo.svg"
          class="logo"
        />
      </h1>
      <h2></h2>
      <p>
        coming soon<span class="dot">.</span>
        <span class="dot">.</span>
        <span class="dot">.</span>
      </p>
      <div class="icons">
        <img class="facebook_icon" src="./assets/images/facebook_icon.png" />
        <img class="instagram_icon" src="./assets/images/instagram_icon.png" />
        <img class="twitter_icon" src="./assets/images/twitter_icon.png" />
        <img class="youtube_icon" src="./assets/images/youtube_icon.png" />
        <img class="pinterist_icon" src="./assets/images/pinterist_icon.png" />
      </div>
    </div>
  </div>`,
  styles: [
    `
      .page {
        background: white;
        color: #fff;
        width: 100%;
        height: 100vh;
      }
      .wrapper {
        color: #fff;
      }

      @keyframes fadeIn {
        from {
          top: 20%;
          opacity: 0;
        }
        to {
          top: 100;
          opacity: 1;
        }
      }

      @-webkit-keyframes fadeIn {
        from {
          top: 20%;
          opacity: 0;
        }
        to {
          top: 100;
          opacity: 1;
        }
      }

      .wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        animation: fadeIn 1000ms ease;
        -webkit-animation: fadeIn 1000ms ease;
      }

      h1 {
        font-size: 50px;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 0;
        line-height: 1;
        font-weight: 700;
        color: #951d8a;
        text-align: center;
      }

      .dot {
        color: black;
      }

      p {
        text-align: center;
        margin: 18px;
        font-family: 'Muli', sans-serif;
        font-weight: normal;
        font-size: 20px;
        color: black;
      }

      .icons {
        text-align: center;
        padding-to: 40px;
      }

      .icons i {
        color: #00091b;
        background: #fff;
        height: 15px;
        width: 15px;
        padding: 13px;
        margin: 0 10px;
        border-radius: 50px;
        border: 2px solid #fff;
        transition: all 200ms ease;
        text-decoration: none;
        position: relative;
      }

      .icons i:hover,
      .icons i:active {
        color: #fff;
        background: none;
        cursor: pointer !important;
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        text-decoration: none;
      }
    `,
  ],
})
export class ComingSoonComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
