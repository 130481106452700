<div class="stream-list-page-wrapper">
    <div class="bg-f5f5f5 w-100 p-30 pl-sm-20 pr-sm-20 p-20-sm">
        <div class="mb-10 d-flex">
            <a href="/account/orders" class="text-primary fs-14 fw-400 lh-16" *ngIf="!isRetailer">Orders</a>
            <a href="/store/{{retailerDomain}}/orders" class="text-primary fs-14 fw-400 lh-16" *ngIf="isRetailer">Orders</a>
            <mat-icon class="text-0006 lh-unset">chevron_right</mat-icon>
            <a href="/account/orders/{{orderDetail?.order_id}}"
                class="text-0006 fs-14 fw-400 lh-16">{{orderDetail?.order_id}}</a>
        </div>
        <div class="d-flex-row w-100 pstn-relative">
            <h1 class="h1-dark fs-22 fx-aln-slf-cntr">Order Details</h1>
            <mat-spinner diameter="20" class="color-primary pstn-absolute r4 mt-50n"
                [class.hidden]="!loadingOrderDetailInfo"></mat-spinner>
            <div class="ml-auto d-flex-col jc-fx-center" *ngIf="isRetailer">
                <mat-form-field appearance="outline" class="p-0 m-0 ml-auto" *ngIf="orderDetail?.availableStatausOptions.length > 0">
                    <mat-label>Action</mat-label>
                    <mat-select (selectionChange)="onActionSelectionChanged($event)">
                        <mat-option *ngFor="let option of orderDetail?.availableStatausOptions"
                            value="{{option.order_status_code}}">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <button mat-raised-button color="primary" class="tt-upper mt-10-i">Mark as Shipped</button> -->
            </div>
        </div>
        <div class="w-100 mt-20 b-s1-00012 brds-4px p-25 d-flex-col bg-white">
            <h1 class="m-0-i d-block fs-14-i fw-500-i lh-24-i text-0087">Order #{{orderDetail?.order_id}}</h1>
            <h4 class="m-0-i mt-15-i mb-15-i d-block fs-16-i fw-600-i lh-24-i text-0087">
                <span [class.text-0088FF]="orderDetail?.status.toLowerCase()=='new'"
                    [class.text-FF8F00]="orderDetail?.status.toLowerCase()=='in progres'"
                    [class.text-00C14E]="orderDetail?.status.toLowerCase()=='ready'"
                    [class.text-707070]="orderDetail?.status.toLowerCase()=='completed'">{{orderDetail?.status}}</span>
            </h4>
            <div class="w-100 d-flex-row flex-wrap fxd-col-sm">
                <div class="text-0087 fs-12 fw-400 mb-sm-20 flex-bss-33">
                    <h2 class="m-0-i d-block fs-14-i fw-500-i lh-24-i text-0087">Order Details</h2>
                    <p class="m-0-i mt-5-i mb-5-i">{{orderDetail?.quantity}} Item ({{orderDetail?.type}})</p>
                    <p class="m-0-i mt-5-i mb-5-i"><strong>Order Placed:</strong>
                        {{orderDetail?.shipmentOrPickUpDetails.placed_at | date : 'MMM dd, yyyy hh:mm a'}}</p>
                    <p class="m-0-i mt-5-i mb-5-i"><strong>Order by:</strong>
                        {{orderDetail?.shipmentOrPickUpDetails.recipient.display_name}}</p>
                    <p class="m-0-i mt-5-i mb-5-i"><strong>Email:</strong>
                        {{orderDetail?.shipmentOrPickUpDetails.recipient.email_address}}</p>
                </div>
                <div class="text-0087 fs-12 fw-400 mb-sm-20 flex-bss-33">
                    <h2 class="m-0-i d-block fs-14-i fw-500-i lh-24-i text-0087">Shipping Address</h2>
                    <p class="m-0-i mt-5-i mb-5-i">{{orderDetail?.shipmentOrPickUpDetails.recipient.display_name}}</p>
                    <p class="m-0-i mt-5-i mb-5-i">
                        <span>{{orderDetail?.shipmentOrPickUpDetails.recipient.address.address_line_1}}</span>
                        <span *ngIf="orderDetail?.shipmentOrPickUpDetails.recipient.address.address_line_2">
                            {{orderDetail?.shipmentOrPickUpDetails.recipient.address.address_line_2}}</span>
                    </p>
                    <p class="m-0-i mt-5-i mb-5-i">
                        <span>{{orderDetail?.shipmentOrPickUpDetails.recipient.address.locality}}</span>
                        <span
                            *ngIf="orderDetail?.shipmentOrPickUpDetails.recipient.address.administrative_district_level_1">,
                            {{orderDetail?.shipmentOrPickUpDetails.recipient.address.administrative_district_level_1}}</span>
                        <span *ngIf="orderDetail?.shipmentOrPickUpDetails.recipient.address.postal_code">
                            {{orderDetail?.shipmentOrPickUpDetails.recipient.address.postal_code}}</span>
                    </p>
                    <p class="m-0-i mt-5-i mb-5-i">{{orderDetail?.shipmentOrPickUpDetails.recipient.phone_number}}</p>
                </div>
                <div class="text-0087 fs-12 fw-400 mb-sm-20 flex-bss-33">
                    <h2 class="m-0-i d-block fs-14-i fw-500-i lh-24-i text-0087">Seller Information</h2>
                    <p class="m-0-i mt-5-i mb-5-i">{{orderDetail?.retailerInfo.storeName}}</p>
                    <p class="m-0-i mt-5-i mb-5-i">
                        <span>{{orderDetail?.retailerInfo.address_line_1}}</span>
                        <span *ngIf="orderDetail?.retailerInfo.address_line_2">
                            {{orderDetail?.retailerInfo.address_line_2}}</span>
                    </p>
                    <p class="m-0-i mt-5-i mb-5-i">
                        <span>{{orderDetail?.retailerInfo.state}}</span>
                        <span *ngIf="orderDetail?.retailerInfo.cityOrTown">,
                            {{orderDetail?.retailerInfo.cityOrTown}}</span>
                        <span *ngIf="orderDetail?.retailerInfo.zipCode"> {{orderDetail?.retailerInfo.zipCode}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="w-100 mt-25 b-s1-00012 brds-4px p-25 d-flex-col bg-white">
            <div class="w-100 d-flex-row pt-15 pb-15">
                <div class="text-0087 fs-14 fw-400 flex-bss-60"><span class="text-0054">Item</span></div>
                <div class="text-0087 fs-14 fw-400 flex-bss-20 ta-right"><span class="text-0054">Qty</span></div>
                <div class="text-0087 fs-14 fw-400 flex-bss-20 ta-right"><span class="text-0054">Amount</span></div>
            </div>
            <mat-divider></mat-divider>
            <div class="w-100 d-flex-row">
                <div class="text-0087 fs-14 fw-400 d-flex-row flex-bss-60 pt-10 pb-10">
                    <img [src]="orderDetail?.item_image_id | asyncImgSrc | async"
                        class="responsive-image w-64px h-52px obj-ft-contain b-s1 brds-2px bc-00004 mr-20">
                    <p class="m-0">
                        <strong class="d-blocl">{{orderDetail?.item_name}}</strong>
                        <span class="d-block">
                            <span *ngIf="orderDetail?.itemVariations"
                                class="mr-10">{{orderDetail?.itemVariations[0]}}</span>
                            <span *ngIf="orderDetail?.itemVariations && orderDetail?.itemVariations.length == 2"
                                class="mr-10"> - Size {{orderDetail?.itemVariations[1]}}</span>
                            <span *ngIf="orderDetail?.itemSku">SKU - <span>{{orderDetail?.itemSku}}</span></span>
                        </span>
                    </p>
                </div>
                <div class="text-0087 fs-14 fw-400 flex-bss-20 ta-right"><span
                        class="text-0054">{{orderDetail?.quantity}}</span></div>
                <div class="text-0087 fs-14 fw-400 flex-bss-20 ta-right"><span
                        class="text-0054">{{orderDetail?.base_price_money.amount/100 | currency}}</span></div>
            </div>
            <mat-divider></mat-divider>
            <div class="w-100 d-flex-row pt-15 pb-15">
                <div class="text-0087 fs-12 fw-400 d-flex-row flex-bss-100 pt-10 pb-10">
                    <a class="text-primary">Add Tracking Information</a>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="w-100 d-flex-row jc-fx-end pt-15 pb-15">
                <div class="text-0087 fs-14 fw-400 flex-bss-30 flex-bss-70-sm pt-10 pb-10">
                    <div class="w-100 d-inline-block">
                        <span class="float-left">Subtotal</span>
                        <span class="float-right ta-right">{{orderDetail?.quantity*orderDetail?.base_price_money.amount/100
                            | currency}}</span>
                    </div>
                    <div class="w-100 d-inline-block">
                        <span class="float-left">Delivery Free</span>
                        <span class="float-right ta-right">{{orderDetail?.delivery_fee.amount | currency}}</span>
                    </div>
                    <div class="w-100 d-inline-block">
                        <span class="float-left">Tax</span>
                        <span class="float-right ta-right">{{orderDetail?.tax.amount/100 | currency}}</span>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="w-100 d-inline-block fw-600-i mt-15 mb-15">
                        <span class="float-left">Total</span>
                        <span class="float-right ta-right">{{orderTotal/100 | currency}}</span>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="w-100 d-inline-block mt-15">
                        <span class="float-left">{{orderDetail?.card_brand}} {{orderDetail?.last_4}}</span>
                        <span class="float-right ta-right" *ngIf="orderDetail?.isPaid">Paid</span>
                    </div>
                    <div class="w-100 d-inline-block">
                        <span class="float-left">{{orderDetail?.shipmentOrPickUpDetails.placed_at | date : 'MMM dd, yyyy
                            hh:mm a'}} </span>
                        <span class="float-right ta-right">{{orderDetail?.order_total.amount/100}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>