<div class="searchbar-container pstn-relative">
  <form class="pstn-relative">
    <mat-progress-bar mode="indeterminate" class="color-primary pstn-absolute-i-b0-l0 h-1px-i"
      [class.hidden]="!inProgress"></mat-progress-bar>
    <input type="text" placeholder="Search for products, categories or stores" class="fs-16-i" matInput
      [(ngModel)]="searchKeyword" name="searchKeyword" (click)="onSearchKeywordChanged()"
      (keyup)="onSearchKeywordChanged()" (keyup.enter)="navigateSearchPage()" autocomplete="off">
    <mat-icon class="search-icon" (click)="navigateSearchPage()">search</mat-icon>
  </form>
</div>
<div id="gsaContainer" class="mat-elevation-z1 pstn-absolute-l0 bg-white w-100 ff-roboto mat-elevation-z10"
      [class.hidden]="!(filteredOptions | async)" [class.d-block]="(filteredOptions | async)">
      <div id="gsacList" *ngFor="let option of filteredOptions | async" class="d-block w-100">
        <div *ngIf="option.demos?.length > 0" class="d-flex-col w-100">
          <div class="tt-upper w-100 p-15 fs-10 fw-500 lh-16 text-0006">Product Demos</div>
          <a *ngFor="let pdemo of option.demos" href="/shops/{{pdemo.user[0].domain}}/{{parseSessionUrlPath(pdemo.type)}}/{{pdemo._id}}"
              class="m-0-i d-flex-row w-100 pl-15 pt-0 pb-10 fs-14 fw-400 text-0087 lh-24 of-hidden">
            <mat-icon class="text-0006">smart_display</mat-icon>
            <span class="d-flex ml-10">{{pdemo.title}}</span>
          </a>
        </div>
        <mat-divider *ngIf="option.demos?.length > 0" class="mt-5 mb-5 h-1px-i"></mat-divider>
        <div *ngIf="option.shops?.length > 0" class="d-flex-col w-100">
          <div class="tt-upper w-100 p-15 fs-10 fw-500 lh-16 text-0006">Shops</div>
          <a *ngFor="let shopItem of option.shops"  href="/shops/{{shopItem.domain}}"
            class="m-0-i d-flex-row w-100 pl-15 pt-0 pb-10 fs-14 fw-400 text-0087 lh-24 of-hidden">
            <mat-icon class="text-0006">store</mat-icon>
            <span class="d-flex ml-10">{{shopItem.storeName}}</span>
          </a>
        </div>
        <div *ngIf="option.shops?.length > 0 || option.demos?.length > 0" class="d-flex-col w-100">
          <div class="tt-upper w-100 p-10 fs-12 fw-400 lh-16 ta-center bg-f5f5f5">
            <a href="/search?q={{searchKeyword}}" class="text-primary crsr-pointer">Show all results</a>
          </div>
        </div>
        <div *ngIf="option.demos?.length == 0 && option.shops?.length == 0" class="d-flex-col w-100">
          <div class="w-100 p-20 fs-16 fw-400 lh-24 text-0006">
            Your search - {{searchKeyword}} - did not match any results.
          </div>
        </div>
      </div>
    </div>