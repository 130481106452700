import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { IPickedForYouSchedule } from 'src/app/models/picked-for-u-schedule.model';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'picked-items',
  templateUrl: '../../views/home/picked-items.home.component.html',
  styleUrls: ['../../../assets/css//home/picked-items.home.component.css'],
})
export class PickedItemsComponent implements OnInit, OnDestroy {
  schedules: IPickedForYouSchedule[] = [];
  private subscriptions = new Subscription();

  startIndex: number = 0;
  endIndex: number = 3;

  constructor(private shopService: ShopService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.shopService
        .allSchedulesInPickedForYou()
        .subscribe((list: IPickedForYouSchedule[]) => {
          this.schedules = list;
        })
    );
  }

  parseApiImageUrl(url: string): string {
    return url;
  }

  hasInProgressStatus(statusEnumValue: StreamStatusEnum): boolean {
    return statusEnumValue == StreamStatusEnum.IN_PROGRESS;
  }

  indexBetweenRange(index) {
    if (index >= this.startIndex && index <= this.endIndex) {
      return true;
    }

    return false;
  }

  displayNextItems() {
    if (this.endIndex + 1 > this.schedules.length) {
      this.startIndex = 0;
      this.endIndex = 3;
      return;
    }

    this.startIndex = this.startIndex + 1;
    this.endIndex = this.endIndex + 1;
  }

  displayPrevItems() {
    if (this.startIndex - 1 < 0) {
      this.startIndex = this.schedules.length - 4;
      this.endIndex = this.schedules.length - 1;
      return;
    }

    this.startIndex = this.startIndex - 1;
    this.endIndex = this.endIndex - 1;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
