<div class="w-100 p-20 d-flex-col ff-roboto-i">
  <div class="mb-15 d-flex-row flex-wrap">
    <a href="/" class="text-primary fs-14 fw-500 mr-5">Shops</a>
    <mat-icon class="text-0006 lh-unset mr-10">chevron_right</mat-icon>
    <a href="/shop/{{ retailerDomain }}/" class="text-primary fs-14 fw-500">{{
      retailerDomain
    }}</a>
  </div>
  <div class="w-100">
    <div class="w-100 d-flex-col p-0-i">
      <div class="w-100 banner-img-container">
        <img
          class="w-100 banner-img"
          [src]="retailerInfo?.storeBannerUrl | apiImgPath : { dsb: true }"
          (error)="
            onStoreBannerImageError(
              $event,
              '/assets/images/store_shop_banner.png'
            )
          "
          alt="{{ retailerDomain }}"
        />
      </div>

      <mat-spinner
        *ngIf="loadingItems"
        class="m-0 ml-auto mr-auto mt-30 mb-45"
      ></mat-spinner>

      <div class="w-100 d-flex-row d-flex-col-sm" *ngIf="!loadingItems">
        <div
          id="storeLogoContainer"
          class="pl-35 pr-25 pstn-relative d-flex h-fit-content mw-124px-sm mh-124px-sm p-0-sm ml-sm-auto mr-sm-auto"
        >
          <img
            [src]="retailerInfo?.logoUrl | apiImgPath : { nl: true }"
            (error)="
              onStoreBannerImageError($event, '/assets/images/nologo.jpg')
            "
            class="mt-50n mw-124px mh-124px b-s1-00024 brds-100 obj-ft-fill-i"
          />
        </div>
        <div class="pt-15 pb-25 d-flex-col">
          <h1 class="h1-dark fs-20 tt-capitalize">
            {{ retailerInfo?.storeName }}
          </h1>
          <h2 class="h2-dark">
            <span *ngFor="let pnItem of retailerProductNatures; let i = index">
              {{ pnItem.category }}
              <span *ngIf="i !== retailerProductNatures.length - 1">.</span>
            </span>
          </h2>
          <p class="w-100 d-flex-row flex-wrap p3-mdm-dark">
            <mat-icon>place</mat-icon>
            <span class="ml-5 lh-24">
              <span *ngIf="retailerInfo?.addressLine1"
                >{{ retailerInfo?.addressLine1 }},
              </span>
              <span
                *ngIf="
                  retailerInfo?.addressLine2 &&
                  retailerInfo?.addressLine2 != 'null'
                "
                >{{ retailerInfo?.addressLine2 }},
              </span>
              <span *ngIf="retailerInfo?.state"
                >{{ retailerInfo?.state }}
              </span>
              <span *ngIf="retailerInfo?.cityOrTown"
                >{{ retailerInfo?.cityOrTown }}
              </span>
              <span *ngIf="retailerInfo?.zipCode">
                - {{ retailerInfo?.zipCode }}</span
              >
            </span>
          </p>
          <p class="w-100 d-flex-row flex-wrap link-primary fw-400">
            <mat-icon class="text-0006">mail_outline</mat-icon>
            <span class="ml-5 mr-25 lh-24">{{ retailerInfo?.email }}</span>
            <mat-icon class="text-0006">call</mat-icon>
            <span class="ml-5 lh-24">{{ retailerInfo?.phoneNumber }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 mt-40" *ngIf="ucItems.length > 0">
    <mat-card>
      <mat-card-header
        class="d-block w-100 p-0"
        fxLayout="row"
        fxLayoutAlign="space-between"
      >
        <mat-card-title class="h1-dark">Upcoming Live Demos</mat-card-title>
        <a routerLink="#" class="link-primary lh-23-i">View All</a>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="pt-15 d-flex-row-i flex-wrap w-100">
        <mat-card
          class="ml-5 mr-5 mb-20-i flex-bss-24 flex-bss-46-sm flex-bss-31-md p-0-i"
          *ngFor="let item of ucItems"
        >
          <a
            class="d-flex pstn-relative m-0-i w-100 lb-card-img-contaier"
            href="/shops/{{ item?.user?.domain }}/sessions/{{ item._id }}"
          >
            <img
              class="w-100 h-100 mb-0-i lb-card-img"
              src="{{ combineBackendApiUrl(item?.streamThumbnailUrl) }}"
              (error)="
                onStoreBannerImageError(
                  $event,
                  '/assets/images/thumbnail-default.jpg'
                )
              "
            />
          </a>
          <mat-card-content class="mb-50-i p-15-i">
            <span class="fs-10 fw-600 text-0004 ff-roboto">{{
              item?.startTime
                | date
                  : "dd MMM hh:mm
                            a"
            }}</span>
            <h5 class="p3-dark-fwm mt-10-i fs-13-i">{{ item?.title }}</h5>
          </mat-card-content>
          <mat-card-footer class="p-15-i d-flex-row-i ml-0-i pstn-absolute-b16">
            <a class="d-flex-row" href="/shops/{{ item?.user?.domain }}">
              <img
                *ngIf="item.user.logoUrl"
                src="{{ combineBackendApiUrl(item?.user?.logoUrl) }}"
                (error)="
                  onStoreBannerImageError($event, '/assets/images/nologo.jpg')
                "
                class="w-32px brds-100 b-s1 bc-00012"
              />
              <mat-icon
                *ngIf="!item.user.logoUrl"
                class="w-32px brds-100 b-s1 bc-00012"
                >storefront
              </mat-icon>
              <span
                *ngIf="item.user.storeName"
                class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
                >{{ item?.user?.storeName }}</span
              >
              <span
                *ngIf="!item.user.storeName"
                class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
                >Store</span
              >
            </a>
          </mat-card-footer>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="w-100 mt-40" *ngIf="raItems.length > 0">
    <mat-card>
      <mat-card-header
        class="d-block w-100 p-0"
        fxLayout="row"
        fxLayoutAlign="space-between"
      >
        <mat-card-title class="h1-dark">Recently Aired</mat-card-title>
        <a routerLink="#" class="link-primary lh-23-i">View All</a>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="pt-15 d-flex-row-i flex-wrap w-100">
        <mat-card
          class="ml-5 mr-5 mb-20-i flex-bss-24 flex-bss-46-sm flex-bss-31-md p-0-i"
          *ngFor="let item of raItems"
        >
          <a
            class="d-flex pstn-relative m-0-i w-100 lb-card-img-contaier"
            href="/shops/{{ item?.user?.domain }}/sessions/{{ item._id }}"
          >
            <img
              class="w-100 h-100 mb-0-i lb-card-img"
              src="{{ combineBackendApiUrl(item.streamThumbnailUrl) }}"
              (error)="
                onStoreBannerImageError(
                  $event,
                  '/assets/images/thumbnail-default.jpg'
                )
              "
            />
          </a>
          <mat-card-content class="mb-50-i p-15-i">
            <span class="fs-10 fw-600 text-0004 ff-roboto">{{
              item?.startTime
                | date
                  : "dd MMM hh:mm
                            a"
            }}</span>
            <h5 class="p3-dark-fwm mt-10-i fs-13-i">{{ item?.title }}</h5>
          </mat-card-content>
          <mat-card-footer class="p-15-i d-flex-row-i ml-0-i pstn-absolute-b16">
            <a class="d-flex-row" href="/shops/{{ item?.user?.domain }}">
              <img
                *ngIf="item.user.logoUrl"
                src="{{ combineBackendApiUrl(item.user?.logoUrl) }}"
                class="w-32px brds-100 b-s1 bc-00012"
                (error)="
                  onStoreBannerImageError($event, '/assets/images/nologo.jpg')
                "
              />
              <mat-icon
                *ngIf="!item.user.logoUrl"
                class="w-32px brds-100 b-s1 bc-00012"
                >storefront
              </mat-icon>
              <span
                *ngIf="item.user.storeName"
                class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
                >{{ item?.user?.storeName }}</span
              >
              <span
                *ngIf="!item.user.storeName"
                class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
                >Store</span
              >
            </a>
          </mat-card-footer>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
</div>
