<h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">Update profile information</h1>
<div mat-dialog-content>
    <form name="storeInformationForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()" method="post"
        enctype="multipart/form-data">
        <div class="pb-10 pt-10">
            <div class="pb-10 pt-10">
                <div class="form-item">
                    <label class="p3-dark-fwm mb-15 d-block text-black">User Information</label>
                    <div class="row d-block">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>First Name</mat-label>
                                <input matInput placeholder="First name" [formControl]="firstName">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>Last Name</mat-label>
                                <input matInput placeholder="Last name" [formControl]="lastName">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>Email Address</mat-label>
                                <input matInput placeholder="Email address" [formControl]="emailAddress">
                            </mat-form-field>
                            <mat-hint class="d-block w-100 mt-10 pl-10-i mt-125n-em">If changed, a verification mail will be sent to new email and will not be activated until verified.</mat-hint>
                        </div>
                    </div>
                </div>
                <div class="form-item mt-25" *ngIf="!isRetailerAccount">
                    <label class="p3-dark-fwm mb-15 d-block text-black">Profile Picture (optional)</label>
                    <div class="p-15 bg-blk-004">
                        <input id="profilePicture" class="mat-stroke-button-file-input" type="file"
                            (change)="onProfileImageChange($event)" />
                            <p class="mat-error m-0-i mt-10-i fs-12 lh-24" *ngIf="profileImageFileError">{{profileImageFileError}}</p>
                    </div>
                    <mat-hint class="d-block w-100 mt-10 pl-10-i">Please upload a square image with minimum
                        200x200px
                        resolution and size smaller than
                        1mb.</mat-hint>
                </div>
                <div class="form-item mt-25" *ngIf="isRetailerAccount">
                    <label class="p3-dark-fwm mb-15 d-block text-black">Store Logo (optional)</label>
                    <div class="p-15 bg-blk-004">
                        <input id="storeLogo" class="mat-stroke-button-file-input" type="file"
                            (change)="onStoreLogoImageChange($event)" />
                            <p class="mat-error m-0-i mt-10-i fs-12 lh-24" *ngIf="storeLogoImageFileError">{{storeLogoImageFileError}}</p>
                    </div>
                    <mat-hint class="d-block w-100 mt-10 pl-10-i">Please upload a square image with minimum 200x200px resolution and size smaller than 1mb.</mat-hint>
                </div>
                <div class="form-item mt-25" *ngIf="isRetailerAccount">
                    <label class="p3-dark-fwm mb-15 d-block text-black">Store Banner Image (optional)</label>
                    <div class="p-15 bg-blk-004">
                        <input id="storeBannerImage" class="mat-stroke-button-file-input" type="file"
                            (change)="onStoreBannerImageChange($event)" />
                            <p class="mat-error m-0-i mt-10-i fs-12 lh-24" *ngIf="storeBannerImageFileError">{{storeBannerImageFileError}}</p>
                    </div>
                    <mat-hint class="d-block w-100 mt-10 pl-10-i">Please upload an image with minimum 1920x480px resolution and size smaller than 2mb.</mat-hint>
                </div>
                <div class="form-item mt-25">
                    <label class="p3-dark-fwm mb-15 d-block text-black">Contact Information</label>
                    <div class="row d-block">
                        <div *ngIf="isRetailerAccount" class="col-12">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>Store Name</mat-label>
                                <input matInput placeholder="Store Name" [formControl]="storeName">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>Phone No (Optional)</mat-label>
                                <input matInput placeholder="phoneNumber" [formControl]="phoneNumber">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>Address Line 1</mat-label>
                                <input matInput placeholder="Address Line 1" [formControl]="addressLine1" required>
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>Address Line 2 (optional)</mat-label>
                                <input matInput placeholder="addressLine2" [formControl]="addressLine2">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>State</mat-label>
                                <input matInput placeholder="state" [formControl]="state" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row d-block">
                        <div class="col-6 pr-5">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>City</mat-label>
                                <input matInput placeholder="City" [formControl]="cityOrTown" required>
                            </mat-form-field>
                        </div>
                        <div class="col-6 pl-5">
                            <mat-form-field appearance="outline" class="d-block-i">
                                <mat-label>Zip</mat-label>
                                <input matInput placeholder="zip" [formControl]="zipCode" required minlength="4">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div mat-dialog-actions class="pstn-relative jc-fx-end ">
                <mat-spinner diameter="25" class="color-primary pstn-absolute t24 l4" [class.hidden]="!inProgress">
                </mat-spinner>
                <button mat-flat-button type="button" class="tt-upper mr-15 text-primary" (click)="closeDialog()"><span>Cancel</span></button>
                <button mat-raised-button color="primary" type="submit" class="tt-upper"><span>Update Profile</span></button>
            </div>
        </div>
    </form>
</div>