<div class="stream-list-page-wrapper">
  <div class="bg-f5f5f5 w-100 p-30 pl-sm-20 pr-sm-20 p-20-sm">
    <div class="mb-10 d-flex" *ngIf="isRetailer">
      <a
        href="/store/{{ retailerDomain }}/subscriptions"
        class="text-primary fs-14 fw-400 lh-16"
        *ngIf="requestForSubscriptionsPage"
        >Subscriptions</a
      >
      <a
        href="/store/{{ retailerDomain }}/subscribers"
        class="text-primary fs-14 fw-400 lh-16"
        *ngIf="requestForSubscribersPage"
        >Subscribers</a
      >
      <mat-icon class="text-0006 lh-unset">chevron_right</mat-icon>
      <a class="text-0006 fs-14 fw-400 lh-16">{{
        subscriptionDetail?.subscriptionId
      }}</a>
    </div>
    <div class="mb-10 d-flex" *ngIf="!isRetailer">
      <a href="/account/subscriptions" class="text-primary fs-14 fw-400 lh-16"
        >Subscriptions</a
      >
      <mat-icon class="text-0006 lh-unset">chevron_right</mat-icon>
      <a class="text-0006 fs-14 fw-400 lh-16">{{
        subscriptionDetail?.subscriptionId
      }}</a>
    </div>
    <div class="d-flex-row w-100 pstn-relative">
      <h1
        class="h1-dark fs-22 fx-aln-slf-cntr"
        *ngIf="requestForSubscriptionsPage"
      >
        Subscription Details
      </h1>
      <h1
        class="h1-dark fs-22 fx-aln-slf-cntr"
        *ngIf="requestForSubscribersPage"
      >
        Subscriber Details
      </h1>
      <mat-spinner
        diameter="20"
        class="color-primary pstn-absolute r4 mt-50n"
        [class.hidden]="!loadingSubscriptionIdDetailInfo"
      ></mat-spinner>
    </div>
    <div class="w-100 mt-20 b-s1-00012 brds-4px p-25 d-flex-col bg-white">
      <div class="bg-ecf4ef w-100 p-10 brds-4px d-flex-row mb-20">
        <mat-icon class="text-00C14E">check_circle</mat-icon>
        <h3 class="fs-14-i fw-400 text-0087 lh-30 ml-10-i m-0-i">
          {{
            subscriptionDetail?.orderPlaced
              | date
                : "MMM
                    dd, yyyy"
          }}
          at {{ subscriptionDetail?.orderPlaced | date : "hh:mm a" }}
        </h3>
      </div>
      <h1 class="m-0-i d-block fs-14-i fw-400-i lh-24-i text-0087">
        Subscription #{{ subscriptionDetail?.subscriptionId }}
      </h1>
      <h4 class="d-block fs-14-i fw-400-i lh-24-i text-0006">
        <span>Completed</span>
      </h4>
      <div class="w-100 d-flex-row flex-wrap fxd-col-sm">
        <div class="text-0087 fs-12 fw-400 mb-sm-20 flex-bss-33">
          <h2 class="m-0-i d-block fs-14-i fw-400-i lh-24-i text-0087">
            Subscription Details
          </h2>
          <p class="m-0-i mt-5-i mb-5-i">
            {{ subscriptionDetail?.quantity }} Item (Exclusive Session)
          </p>
          <p class="m-0-i mt-5-i mb-5-i">
            Subscribed at:
            {{
              subscriptionDetail?.orderPlaced
                | date
                  : "MMM dd,
                        yyyy hh:mm a"
            }}
          </p>
          <p class="m-0-i mt-5-i mb-5-i">
            Subscribed by:
            {{ subscriptionDetail?.subscriberInformation.firstName }}
            <span
              class="ml-5"
              *ngIf="subscriptionDetail?.subscriberInformation.lastName"
              >{{ subscriptionDetail?.subscriberInformation.lastName }}</span
            >
          </p>
          <p class="m-0-i mt-5-i mb-5-i">
            Email: {{ subscriptionDetail?.subscriberInformation.email }}
          </p>
        </div>
        <div class="text-0087 fs-12 fw-400 mb-sm-20 flex-bss-33">
          <h2 class="m-0-i d-block fs-14-i fw-400-i lh-24-i text-0087">
            Billing Address
          </h2>
          <p class="m-0-i mt-5-i mb-5-i">
            {{ subscriptionDetail?.subscriberInformation.firstName }}
            <span
              class="ml-5"
              *ngIf="subscriptionDetail?.subscriberInformation.lastName"
              >{{ subscriptionDetail?.subscriberInformation.lastName }}</span
            >
          </p>
          <p class="m-0-i mt-5-i mb-5-i">
            <span>{{
              subscriptionDetail?.subscriberInformation.addressLine1
            }}</span>
            <span
              *ngIf="subscriptionDetail?.subscriberInformation.addressLine2"
            >
              {{ subscriptionDetail?.subscriberInformation.addressLine1 }}</span
            >
          </p>
          <p class="m-0-i mt-5-i mb-5-i">
            {{ subscriptionDetail?.subscriberInformation.phoneNumber }}
          </p>
        </div>
        <div class="text-0087 fs-12 fw-400 mb-sm-20 flex-bss-33">
          <h2 class="m-0-i d-block fs-14-i fw-500-i lh-24-i text-0087">
            Seller Information
          </h2>
          <p class="m-0-i mt-5-i mb-5-i">
            {{ subscriptionDetail?.retailerInformation.storeName }}
          </p>
          <p class="m-0-i mt-5-i mb-5-i">
            <span>{{
              subscriptionDetail?.retailerInformation.address_line_1
            }}</span>
            <span *ngIf="subscriptionDetail?.retailerInformation.addressLine2">
              {{ subscriptionDetail?.retailerInformation.addressLine2 }}</span
            >
          </p>
          <p class="m-0-i mt-5-i mb-5-i">
            <span>{{ subscriptionDetail?.retailerInformation.state }}</span>
            <span *ngIf="subscriptionDetail?.retailerInformation.cityOrTown"
              >, {{ subscriptionDetail?.retailerInformation.cityOrTown }}</span
            >
            <span *ngIf="subscriptionDetail?.retailerInformation.zipCode">
              {{ subscriptionDetail?.retailerInformation.zipCode }}</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="w-100 mt-25 b-s1-00012 brds-4px p-25 d-flex-col bg-white">
      <div class="w-100 d-flex-row pt-15 pb-15">
        <div class="text-0087 fs-14 fw-400 flex-bss-60">
          <span class="text-0054">Item</span>
        </div>
        <div class="text-0087 fs-14 fw-400 flex-bss-20 ta-right">
          <span class="text-0054">Qty</span>
        </div>
        <div class="text-0087 fs-14 fw-400 flex-bss-20 ta-right">
          <span class="text-0054">Amount</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="w-100 d-flex-row">
        <div class="text-0087 fs-14 fw-400 d-flex-row flex-bss-60 pt-10 pb-10">
          <img
            [src]="subscriptionDetail?.streamThumbnailUrl"
            class="responsive-image w-64px h-52px obj-ft-contain b-s1 brds-2px bc-00004 mr-20"
          />
          <p class="m-0">
            <strong class="d-blocl">{{
              subscriptionDetail?.sessionTitle
            }}</strong>
            <span class="d-block">
              <span>(Access {{ subscriptionDetail?.accessHour }} hours)</span>
              <span *ngIf="subscriptionDetail?.accessHour < 48" class="mr-10">
                (Expired)</span
              >
            </span>
          </p>
        </div>
        <div class="text-0087 fs-14 fw-400 flex-bss-20 ta-right">
          <span class="text-0054">{{ subscriptionDetail?.quantity }}</span>
        </div>
        <div class="text-0087 fs-14 fw-400 flex-bss-20 ta-right">
          <span class="text-0054">{{
            subscriptionDetail?.amount / 100 | currency
          }}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="w-100 d-flex-row jc-fx-end pt-15 pb-15">
        <div
          class="text-0087 fs-14 fw-400 flex-bss-30 flex-bss-70-sm pt-10 pb-10"
        >
          <div class="w-100 d-inline-block">
            <span class="float-left">Subtotal</span>
            <span class="float-right ta-right">{{
              (subscriptionDetail?.quantity * subscriptionDetail?.amount) / 100
                | currency
            }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="w-100 d-inline-block fw-600-i mt-15 mb-15">
            <span class="float-left">Total</span>
            <span class="float-right ta-right">{{
              orderTotal / 100 | currency
            }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="w-100 d-inline-block mt-15">
            <span class="float-left"
              >{{ subscriptionDetail?.card_brand }}
              {{ subscriptionDetail?.card_last4 }}</span
            >
            <span class="float-right ta-right">Paid</span>
          </div>
          <div class="w-100 d-inline-block">
            <span class="float-left"
              >{{
                subscriptionDetail?.orderPlaced | date : "MMM dd, yyyy hh:mm a"
              }}
            </span>
            <span class="float-right ta-right">{{
              subscriptionDetail?.amount / 100 | currency
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
