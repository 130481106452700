<div>
    <h1 class="mat-dialog-title h1-dark fs-20-i fw-500-i">Product Details</h1>
    <div class="mat-dialog-content">
        <div class="pb-10 pt-10">
            <div class="pb-10 pt-10">
                <div class="d-flex-row flex-wrap fxd-col-sm pstn-relative">
                    <div class="w-50 w-100-sm container-16-9">
                        <img class="img-16-9 b-s1 bc-00012 brds-4px" [src]="data.productItem.image_id | asyncImgSrc | async">
                    </div>
                    <div class="d-flex-col ff-roboto pl-40 pl-sm-0 bs-bb mw-50 mw-100-sm w-100-sm pl-sm-0 pt-sm-30">
                        <img *ngIf="itemLoading" src="../../../assets/images/ajax-loader.gif" alt="ajax-loader">
                        <p class="text-0006 fs-12 lh-24 fw-400 mb-5-i">
                            <span *ngIf="itemDetailvariations?.sku">SKU 354864</span>
                            <span *ngIf="itemDetailvariations?.sku"> | </span>
                            <span>{{data.retailerDomain}}</span>
                        </p>
                        <p class="text-black fs-24 lh-32 fw-500 mb-5-i">
                            {{itemDetail?.name}}
                        </p>
                        <p class="text-primary fs-24 lh-32 fw-400 mb-20-i">
                            <span>{{itemDetailvariations?.price_money?.amount/100 | currency}}</span>
                        </p>
                        <mat-form-field appearance="outline" *ngIf="colors.length > 0">
                            <mat-label>Color</mat-label>
                            <mat-select [(ngModel)]="colorModel">
                                <mat-option *ngFor="let color of colors" value="{{color}}">{{color}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" *ngIf="sizes.length > 0">
                            <mat-label>Size</mat-label>
                            <mat-select [(ngModel)]="sizeModel">
                                <mat-option *ngFor="let size of sizes" value="{{size}}">{{size}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="mb-20-i" appearance="outline">
                            <mat-label>Quantity</mat-label>
                            <input matInput value="1" min="1" type="number" [(ngModel)]="quantityModel">
                        </mat-form-field>
                        <button mat-raised-button color="primary" class="tt-upper w-100 pstn-relative" (click)="onBuyNowClicked()">
                            <mat-spinner diameter="20" class="color-white pstn-absolute t24 l25"
                                [class.hidden]="!inProgress"></mat-spinner>
                            <span>Buy Now</span>
                        </button>
                        <div class="d-flex-row w-100 pt-30 fs-13 fw-400 text-0087 lh-24">
                            <mat-icon>lock</mat-icon>
                            <span class="mat-h6 fs-13 lh-30 ml-5">Secured Payment</span>
                            <mat-icon class="ml-25 text-primary fs-16-i lh-30">help_outline</mat-icon>
                            <span class="mat-h6 fs-13 ml-0 lh-30">Usually delivered in 3 days</span>
                        </div>
                    </div>
                </div>
            </div>
            <div mat-dialog-actions class="pstn-relative m-0-24-neg p-0-24-i bt-s1-i bc-00012-i mt-15">
                <mat-tab-group mat-align-tabs="start">
                    <mat-tab label="PRODUCT DETAILS" class="tt-upper">
                        <div class="p-25">
                            {{itemDetail?.description}}
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>