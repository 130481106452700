<div
  *ngIf="schedules.length > 0"
  id="pickedItemsSection"
  class="w-100 pl-80 pr-80 p-20 pl-sm-20 pr-sm-20 p-20-sm bg-F5F5F5"
>
  <mat-card class="p-0-i">
    <mat-card-header
      class="d-block w-100 p-15 pb-0"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <mat-card-title class="h1-dark">
        <h3 class="m-0-i h1-dark">Picked for You</h3>
      </mat-card-title>
      <a routerLink="#" class="link-primary lh-23-i">View All</a>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content class="fx-wrap-sm p-5">
      <div class="d-flex-row fx-wrap-sm w-100 pstn-relative">
        <div class="navigate-prev" (click)="displayPrevItems()">
          <mat-icon class="">arrow_back_ios</mat-icon>
        </div>
        <div class="navigate-next" (click)="displayNextItems()">
          <mat-icon class="">arrow_forward_ios</mat-icon>
        </div>
        <mat-card
          class="m-5 d-flex-col flex-auto p-0-i slider-card-item"
          *ngFor="let item of schedules; let i = index"
          [class.hidden]="!indexBetweenRange(i)"
          [class.active]="indexBetweenRange(i)"
        >
          <a
            class="d-flex pstn-relative m-0-i w-100-i lb-card-img-contaier"
            href="/shops/{{ item.user.domain }}/sessions/{{ item._id }}"
          >
            <img
              class="mb-0-i lb-card-img"
              [src]="item.streamThumbnailUrl"
              onerror="this.src='./assets/images/thumbnail-default.jpg'"
            />
          </a>
          <mat-card-content class="mb-50-i p-15-i">
            <span class="fs-10 fw-600 text-0004 ff-roboto">{{
              item.startTime | date : "dd MMM hh:mm a"
            }}</span>
            <a
              class="p3-dark-fwm mt-10-i fs-13-i"
              href="/shops/{{ item.user.domain }}/sessions/{{ item._id }}"
            >
              <h5 class="p3-dark-fwm mt-10-i fs-13-i">{{ item.title }}</h5>
            </a>
          </mat-card-content>
          <mat-card-footer
            class="p-15-i d-block of-hidden w-100 ml-0-i pstn-absolute-b16"
          >
            <p class="m-0-i">
              <a class="d-flex-row" href="/shops/{{ item.user.domain }}">
                <img
                  [src]="item.user.logoUrl | apiImgPath : { nl: true }"
                  class="w-36px-i h-36px-i brds-100 b-s1 bc-00012"
                  onerror="this.src='./assets/images/nologo.jpg'"
                />
                <span
                  *ngIf="item.user.storeName"
                  class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i wp-nowrap"
                  >{{ item.user.storeName }}</span
                >
                <span
                  *ngIf="!item.user.storeName"
                  class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i wp-nowrap"
                  >Store</span
                >
              </a>
            </p>
          </mat-card-footer>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
