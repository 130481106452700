<section
  id="allShopsPageContent"
  class="w-100 pl-80 pr-80 p-20 p-sm-20 pl-sm-20 pr-sm-20 ff-roboto"
>
  <div class="w-100 b-s1-00024 brds-4px pstn-relative">
    <mat-spinner
      diameter="20"
      class="color-primary pstn-absolute-t0-r0-i mr-20 mt-10"
      [class.hidden]="!loading"
    >
    </mat-spinner>
    <mat-tab-group mat-align-tabs="start">
      <mat-tab class="tt-upper" label="{{ pageTitle }}">
        <div class="d-inline-block d-flex-col-sm w-100 pl-10">
          <mat-form-field class="mr-10 mnw-320px" appearance="outline">
            <input
              type="search"
              matInput
              placeholder="Search"
              [(ngModel)]="searchKeyword"
              (keyup)="filterShopListItems()"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
          <mat-form-field class="mr-10" appearance="outline">
            <mat-label>Product Category</mat-label>
            <mat-select
              (selectionChange)="filterShopListItems()"
              [(ngModel)]="categoryId"
            >
              <mat-option value="-1">All</mat-option>
              <mat-option
                *ngFor="let nat of productNatures"
                value="{{ nat.id }}"
                >{{ nat.category }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mt-15n">
          <div
            class="d-flex-row flex-wrap w-100 pstn-relative pr-10"
            *ngIf="shopList && dataSource?.length > 0"
          >
            <div
              class="b-s1-00024 brds-4px m-10 mr-0 d-flex-col flex-bss-22 mxw-31-33-30-19 flex-gs-1-0 p-0-i slider-card-item pstn-relative"
              *ngFor="let retailerInfo of dataSource"
            >
              <div class="w-100">
                <a
                  href="/shops/{{ retailerInfo?.details?.domain }}"
                  class="d-flex-col lb-card-img-contaier"
                >
                  <img
                    class="w-100 h-142px brds-tl-tr-4px lb-card-img"
                    [src]="retailerInfo?.details?.storeBannerUrl"
                    (error)="
                      onImageError(
                        $event,
                        '/assets/images/thumbnail-default.jpg'
                      )
                    "
                    alt="{{ retailerInfo?.details?.domain }}"
                  />
                </a>
              </div>
              <div class="w-100 d-flex-col mb-50">
                <div class="h-64px w-64px pstn-relative m-auto mt-50n">
                  <a href="/shops/{{ retailerInfo?.details?.domain }}">
                    <img
                      [src]="retailerInfo?.details?.logoUrl"
                      (error)="
                        onImageError($event, '/assets/images/nologo.jpg')
                      "
                      class="h-64px w-64px b-s1-00024 brds-100 obj-ft-contain bg-white"
                    />
                  </a>
                </div>
                <div class="p-20 d-flex-col ta-center">
                  <h1
                    class="fs-14-i fw-500-i lh-24-i text-primary tt-capitalize"
                  >
                    <a
                      class="fs-14-i fw-500-i lh-24-i text-primary tt-capitalize"
                      href="/shops/{{ retailerInfo?.details?.domain }}"
                      >{{ retailerInfo?.details?.storeName }}</a
                    >
                  </h1>
                  <h2
                    class="mt-10 fs-12-i fw-500-i lh-16-i text-black ta-center"
                  >
                    <span
                      *ngFor="
                        let pnItem of retailerInfo?.details?.productNatures;
                        let i = index
                      "
                    >
                      {{ getProductNatureNameById(pnItem) }}
                      <span
                        *ngIf="
                          i !== retailerInfo?.details?.productNatures.length - 1
                        "
                        >.</span
                      >
                    </span>
                  </h2>
                  <mat-divider></mat-divider>
                  <p
                    class="w-100 d-flex-row fs-12 fw-400 lh-16 text-0006 pt-10"
                  >
                    <mat-icon class="fs-20-i w-20px-i h-20px-i">place</mat-icon>
                    <span class="ml-5 lh-24 d-flex-row flex-wrap">
                      <span *ngIf="retailerInfo?.details?.addressLine1"
                        >{{ retailerInfo?.details?.addressLine1 }}
                      </span>
                      <span *ngIf="retailerInfo?.details?.addressLine2"
                        >{{ retailerInfo?.details?.addressLine1 }}
                      </span>
                      <span *ngIf="retailerInfo?.details?.state"
                        >{{ retailerInfo?.details?.state }}
                      </span>
                      <span *ngIf="retailerInfo?.details?.cityOrTown"
                        >{{ retailerInfo?.details?.cityOrTown }}
                      </span>
                      <span *ngIf="retailerInfo?.details?.zipCode">
                        - {{ retailerInfo?.details?.zipCode }}</span
                      >
                    </span>
                  </p>
                  <div class="w-100 d-flex-col fs-12 fw-400 lh-16 text-primary">
                    <div class="d-flex-row w-100">
                      <mat-icon class="text-0006 fs-20-i w-20px-i h-20px-i"
                        >mail_outline
                      </mat-icon>
                      <span class="ml-5 mr-25 lh-24">{{
                        retailerInfo?.details?.email
                      }}</span>
                    </div>
                    <div class="d-flex-row flex-wrap w-100 pt-10">
                      <mat-icon class="text-0006 fs-20-i w-20px-i h-20px-i"
                        >call</mat-icon
                      >
                      <span class="ml-5 lh-24">{{
                        retailerInfo?.details?.phoneNumber
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="w-100 d-flex-row p-15 ta-center fs-10 fw-500 lh-16 tt-upper bt-s1 pstn-absolute-b0-l0 bc-00012"
                  [class.bg-blk-004]="!retailerInfo?.details?.nexLiveDemo"
                  [class.bg-primary-0012]="
                    retailerInfo?.details?.nexLiveDemo &&
                    retailerInfo?.details?.nexLiveDemo.status != 1
                  "
                  [class.bg-primary]="
                    retailerInfo?.details?.nexLiveDemo &&
                    retailerInfo?.details?.nexLiveDemo.status == 1
                  "
                >
                  <p
                    class="m-auto-i text-0006"
                    *ngIf="!retailerInfo?.details?.nexLiveDemo"
                  >
                    No Live Demo Scheduled
                  </p>
                  <p
                    class="m-auto-i text-0006"
                    *ngIf="
                      retailerInfo?.details?.nexLiveDemo &&
                      retailerInfo?.details?.nexLiveDemo.status == 1
                    "
                  >
                    <span class="text-ff74">Next Live Demo - </span>
                    <span class="text-white">On Air Now</span>
                  </p>
                  <p
                    class="m-auto-i text-0006"
                    *ngIf="
                      retailerInfo?.details?.nexLiveDemo &&
                      retailerInfo?.details?.nexLiveDemo.status != 1
                    "
                  >
                    <span class="text-0006">Next Live Demo - </span>
                    <span class="text-0087">{{
                      retailerInfo?.details?.nexLiveDemo.startTime
                        | date
                          : "MMM
                                            dd,
                                            hh:mm a"
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mat-paginator
          (page)="pageChanged($event)"
          [length]="shopList.length"
          [pageSize]="tablePageSize"
          [pageSizeOptions]="[2, 5, 10, 25, 100]"
        ></mat-paginator>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
