import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoleEnum } from '../enums/role.enum';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService) { }

  get isSignupSignPage(): boolean {
    if (this.router.url.indexOf('/register') > -1
      || this.router.url.indexOf('/login') > -1
      || this.router.url.indexOf('/verify') > -1
      || this.router.url.indexOf('/activate') > -1
      || this.router.url.indexOf('/account/reset-password') > -1) {
      return true;
    }

    return false;
  }

  get isUserPage(): boolean {
    if (this.router.url.indexOf('/store') > -1
      && this.authenticationService.currentUserRole === RoleEnum.retailer) {
      return true;
    }
    else if (this.router.url.indexOf('/account') > -1) {
      // if (this.authenticationService.currentUserRole === RoleEnum.retailer) {
        return true;
      // }
    }

    return false;
  }

  get activeRoute(): string {
    return this.router.url;
  }

  private _verificationData: any;
  set verificationData(data: any) {
    this._verificationData = data;
  }

  get verificationData(): any {
    return this._verificationData;
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

}
