import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IItem } from '../models/item.model';
import { ISchedule } from '../models/schedule.model';
import { ISubscriberListItem } from '../models/subscriber-list-item.model';

@Injectable({
  providedIn: 'root',
})
export class RetailerStoreService {
  protected apiServer = AppConfig.settings.apiServer;
  protected localStorageKeys = AppConfig.settings.localStorage.keys;

  constructor(private http: HttpClient) {}

  updateStoreInformation(
    userId: string,
    locationFormData: FormData
  ): Observable<any> {
    return this.http
      .patch<any>(
        `${this.apiServer.basepath}/users/${userId}/location`,
        locationFormData
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  createStream(userId: string, streamFormData: FormData): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiServer.basepath}/retailer/${userId}/schedule`,
        streamFormData
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  allSchedules(userId: string): Observable<ISchedule[]> {
    return this.http
      .get(`${this.apiServer.basepath}/retailer/${userId}/schedules`)
      .pipe(
        map((scheduleList: ISchedule[]) => {
          return scheduleList;
        })
      );
  }

  allSchedulesByDomain(domain: string): Observable<ISchedule[]> {
    const apiUrl = `${
      this.apiServer.basepath
    }/retailer/${domain}/${encodeURIComponent('schedules-by-domain')}`;
    return this.http.get(apiUrl).pipe(
      map((scheduleList: ISchedule[]) => {
        return scheduleList;
      })
    );
  }

  scheduleByUserIdScheduleId(
    userId: string,
    scheduleId: string
  ): Observable<ISchedule> {
    const apiUrl = `${this.apiServer.basepath}/retailer/${userId}/schedule/${scheduleId}`;
    return this.http.get(apiUrl).pipe(
      map((schedule: ISchedule) => {
        return schedule;
      })
    );
  }

  showcaseProductsByScheule(
    userId: string,
    scheduleId: string
  ): Observable<IItem[]> {
    return this.http
      .get(
        `${this.apiServer.basepath}/retailer/${userId}/schedule/${scheduleId}/items`
      )
      .pipe(
        map((res: any) => {
          return res.items;
        })
      );
  }

  showcaseProductsByScheuleIdUserDomain(
    userDomain: string,
    scheduleId: string
  ): Observable<IItem[]> {
    const apiUrl = `${
      this.apiServer.basepath
    }/retailer/${userDomain}/${encodeURIComponent(
      'schedules-by-domain'
    )}/${scheduleId}/items`;
    return this.http.get(apiUrl).pipe(
      map((res: any) => {
        return res.items;
      })
    );
  }

  updateSheduleShowcaseProduct(
    userId: string,
    scheduleId: string,
    productIds: string[]
  ): Observable<any> {
    return this.http
      .patch<any>(
        `${this.apiServer.basepath}/retailer/${userId}/schedule/${scheduleId}/items`,
        { productIds: productIds }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateStartStreamingStatus(
    userId: string,
    scheduleId: string,
    startTime: string
  ) {
    const apiurl: string = `${this.apiServer.basepath}/retailer/${userId}/schedule/${scheduleId}/start-streaming`;
    return this.http.patch<any>(apiurl, { startTime: startTime }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateStopStreamingStatus(
    userId: string,
    scheduleId: string,
    endTime: string
  ) {
    const apiurl: string = `${this.apiServer.basepath}/retailer/${userId}/schedule/${scheduleId}/stop-streaming`;
    return this.http.patch<any>(apiurl, { endTime: endTime }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  geSubscriberListItemByUserId(
    userId: string
  ): Observable<ISubscriberListItem[]> {
    const apiurl = `${this.apiServer.basepath}/retialer/${userId}/subscribers`;
    return this.http.get<ISubscriberListItem[]>(apiurl).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
