import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'delete-item-dialog',
  templateUrl: '../../views/item/delete-item.dialog.component.html',
})
export class DeleteItemDialogComponent implements OnInit {
  product;
  constructor(
    public dialogRef: MatDialogRef<DeleteItemDialogComponent>,
    public itemService: ItemService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.product = data;
  }

  ngOnInit(): void {}

  deleteItem() {
    this.itemService
      .deleteItem(this.product.id)
      .toPromise()
      .then(() => {
        this.snackBar.open('Item deleted', 'Close', {
          duration: 10000,
          panelClass: ['mat-success-bg'],
        });
        this.dialogRef.close();
      })
      .catch((err: HttpErrorResponse) => {
        const errorDetail = err.error.error || err.error;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
