import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { IItemCategory } from 'src/app/models/item-categories.model';
import { IShopListItem } from 'src/app/models/shop-list-item.model';
import { ItemService } from 'src/app/services';
import { ShopService } from 'src/app/services/shop.service';

@Component({
    selector: 'all-shops',
    templateUrl: '../../views/shops/all-shops.component.html',
    styleUrls: ['../../../assets/css/shops/all-shops.component.css']
})
export class AllShopsComponent implements OnInit {

    pageTitle: string = 'All Shops';

    loading: boolean = true;

    searchKeyword: string;
    categoryId: string;

    shopList: IShopListItem[] = [];
    shopListOriginal: any[] = [];

    tablePageSize: number = 10;
    tablePageIndex: number = 0;

    dataSource: IShopListItem[] = [];
    productNatures: IItemCategory[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private shopService: ShopService,
        private itemService: ItemService) { }

    ngOnInit(): void {
        this.categoryId = this.activatedRoute.snapshot.paramMap.get('categoryId');

        this.itemService.categories().subscribe((productnatures: any) => {
            this.shopService.allShops()
                .toPromise()
                .then((shopList) => {

                    this.loading = false;
                    this.productNatures = productnatures;

                    if (shopList.length > 0) {
                        this.shopListOriginal = shopList
                        this.shopList = this.shopListOriginal;
                        //const items = [...this.shopList];
                        //this.dataSource = items.splice(this.tablePageIndex * this.tablePageSize, this.tablePageSize);
                        this.filterShopListItems();

                    }
                }).catch(err => {
                    this.loading = false;
                });
        })
    }

    filterShopListItems(): void {
        var self = this;
        var filterList = self.shopListOriginal.filter(o => {
            if (!self.searchKeyword) return true;

            const details = o.details;
            return (details.storeName && details.storeName.toString().toLowerCase().indexOf(self.searchKeyword.toLowerCase()) > -1)
                || (details.addressLine1 && details.addressLine1.toString().toLowerCase().indexOf(self.searchKeyword.toLowerCase()) > -1)
                || (details.addressLine2 && details.addressLine2.toString().toLowerCase().indexOf(self.searchKeyword.toLowerCase()) > -1)
                || (details.cityOrTown && details.cityOrTown.toString().toLowerCase().indexOf(self.searchKeyword.toLowerCase()) > -1)
                || (details.state && details.state.toString().toLowerCase().indexOf(self.searchKeyword.toLowerCase()) > -1)
                || (details.zipCode && details.zipCode.toString().toLowerCase().indexOf(self.searchKeyword.toLowerCase()) > -1)
                || (details.email && details.email.toString().toLowerCase().indexOf(self.searchKeyword.toLowerCase()) > -1)
                || (details.phoneNumber && details.phoneNumber.toString().toLowerCase().indexOf(self.searchKeyword.toLowerCase()) > -1);
            // return Object.keys(details).some(k => {
            //     return details[k] && details[k].toString().toLowerCase().includes(self.searchKeyword.toLowerCase())
            // });
        });

        if (self.categoryId) {
            filterList = filterList.filter(o => {
                if (self.categoryId == "-1" || !self.categoryId) return true;
                return o.details?.productNatures
                    && o.details?.productNatures?.length > 0
                    && o.details?.productNatures?.indexOf(self.categoryId) > -1;
            });
        }

        const items = [...filterList];
        self.dataSource = items.splice(self.tablePageIndex * self.tablePageSize, self.tablePageSize);
    }

    onImageError(event$: any, defaultImagePath: string): void {
        event$.target.src = defaultImagePath;
    }

    getProductNatureNameById(id: string) {
        const category = this.productNatures.find(x => x.id === id);
        if (!category) return '';
        return category.category;
    }
  
    pageChanged(event: PageEvent) {
        this.tablePageIndex = event.pageIndex;
        this.tablePageSize = event.pageSize;

        //const items = [...this.shopList];
        //this.dataSource = items.splice(this.tablePageIndex * this.tablePageSize, this.tablePageSize);
        this.filterShopListItems();
    }
}