import { Component, Input } from '@angular/core';

@Component({ 
  selector: 'trending-items', 
  templateUrl: '../../views/home/trending-items.home.component.html', 
  styleUrls: ['../../../assets/css//home/trending-items.home.component.css'] 
})
export class TrendingItemsComponent {
  
    @Input() imageName: string;
    @Input() description: string;
    @Input() price: string;
  
  constructor() {}
}
