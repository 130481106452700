import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/app/helpers/validators/must-match.validator';
import { AccountService, AuthenticationService } from 'src/app/services';

@Component({
    selector: 'app-update-password',
    templateUrl: '../../views/login/update-password.component.html',
    styleUrls: ['../../../assets/css/login/login.component.css']
})
export class UpdatePasswordComponent implements OnInit {

    loginForm: FormGroup;
    resetAccountPasswordForm: FormGroup;
    inProgress: Boolean = false;
    passwordRecoveryCode: string;
    passwordRecoveryError: boolean = false;
    passwordResetCompleted: boolean = false;

    newPassword = new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&~#])[A-Za-z\d$@$!%*?&~#].{7,}')]);
    confirmPassword = new FormControl('', [Validators.required, Validators.minLength(8)]);

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private accountService: AccountService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private snackBar: MatSnackBar) { }

    ngOnInit(): void {

        this.passwordRecoveryCode = this.activeRoute.snapshot.paramMap.get('code');
        if (!this.passwordRecoveryCode) {
            this.passwordRecoveryError = true;
            return;
        }

        if (this.authService.currentUserId) this.router.navigate(['/']);

        this.loginForm = this.formBuilder.group({
            password: this.newPassword,
            confirmPassword: this.confirmPassword,
            storeName: [''],
            domainName: ['']
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    getErrorMessage(fieldKey: string) {
        switch (fieldKey) {
            case 'newPassword':
                if (this.newPassword.hasError('required')) return 'Password is required';
                if (this.newPassword.errors.minLength) return 'Minimum 8 characters, include uppercase, lowercase, numbers and a special charecter.';
                if (this.newPassword.errors.pattern) return 'Minimum 8 characters, include uppercase, lowercase, numbers and a special charecter.';
                break;
            case 'confirmPassword':
                if (this.confirmPassword.hasError('required')) return 'Confirm password is required';
                if (this.confirmPassword.errors.mustMatch) return 'Passwords must match';
                break;
        }
    }

    onSubmit() {

        this.inProgress = true;
        this.passwordRecoveryError = false;

        if (this.loginForm.invalid) {
            this.inProgress = false;
            return;
        }

        const data = {
            token: this.passwordRecoveryCode,
            password: this.newPassword.value,
            confirmPassword: this.confirmPassword.value,
        };
        this.accountService.resetPassword(data).toPromise<any>().then(res => {
            // handle success response
            this.inProgress = false;
            this.passwordResetCompleted = true;
        }).catch((err: HttpErrorResponse) => {
            this.passwordRecoveryError = true;
            this.passwordResetCompleted = false;
        });
    }
}
