import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { ViewEncapsulation } from '@angular/core';
import { RoleEnum } from 'src/app/enums/role.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'consumer-personal-interest-dialog',
  templateUrl: '../../views/user/product-natures.dialog.user.component.html',
  styleUrls: ['../../../assets/css/user/product-natures.dialog.user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProductNaturesDialogComponent implements OnInit, OnDestroy {

  productNatures: string[];
  inProgress: boolean = false;
  itemCategories: any[];
  selectedCategories: any[];

  loadItems: boolean = true;

  title: string = "Personal interests";
  dialogHeader: string = "Please select the topics you are interested in.";
  actionInstruction: string = "You can always change your choice from user profile.";

  subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ProductNaturesDialogComponent>,
    public itemService: ItemService,
    public authService: AuthenticationService,
    public accountService: AccountService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: string[]) {
    this.productNatures = data;
    this.selectedCategories = this.productNatures;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {

    if (this.authService.currentUserRole == RoleEnum.retailer) {
      this.dialogRef.disableClose = true;
      this.title = "Nature of product or service";
      this.dialogHeader = "Please select the nature of the products or services of your store";
      this.actionInstruction = "You can always change your choices later from the store settings.";
    }

    this.subscription.add(this.itemService.categories().subscribe((res: any) => {
      this.itemCategories = res.map(item => { return { id: item.id, name: item.category } });
      this.loadItems = false;
    }));
  }

  toggleChipSelection($chip) {
    if (!this.selectedCategories) this["selectedCategories"] = [];
    const categoryId = $chip.value;
    if (this.selectedCategories.indexOf(categoryId) == -1) {
      this.selectedCategories.push(categoryId);
      return;
    }

    this.selectedCategories.splice(this.selectedCategories.indexOf(categoryId), 1);
  }

  onSaveAndContinueClick() {
    this.inProgress = true;
    const userId = this.authService.currentUserId;
    this.accountService.updateProductNatures(userId, this.selectedCategories)
      .toPromise()
      .then((res: any) => {
        this.inProgress = false;
        this.authService.updateUserProductNaturesInLocalStorage(this.selectedCategories);
        this.dialogRef.close();
      })
      .catch((err: HttpErrorResponse) => {
        this.inProgress = false;
        const errorDetail = err.error.error || err.error;
        this.snackBar.open(errorDetail, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
