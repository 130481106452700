import { Injectable } from '@angular/core';
import { ICartItem } from '../models/cart-item.model';
import * as AES from 'crypto-js/aes';
import * as cryptoEncUtf8 from 'crypto-js/enc-utf8';
import { AppConfig } from '../app.config';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    CART_ITEM_JSON_LOCAL_STORAGE_KEY: string = 'Y2FydGl0ZW1qc29u';

    addToCart(item: ICartItem) {

        const encryptedItemsJsonCache = localStorage.getItem(this.CART_ITEM_JSON_LOCAL_STORAGE_KEY);
        var itemsJson = '';
        if (encryptedItemsJsonCache) {
            itemsJson = AES.decrypt(encryptedItemsJsonCache, AppConfig.secretPassphrase).toString(cryptoEncUtf8);
        }

        var items: ICartItem[] = [];

        try {
            items = JSON.parse(itemsJson || null) || [];
            const index = items.map(function (e) { return e.itemId; }).indexOf(item.itemId);
            if (index > -1) {
                items[index] = item;
            } else {
                items.push(item);
            }

        } catch (e) {
            items.push(item);
        }

        const itemsJsonAfterUpdate = JSON.stringify(items);
        const encryptedItemsJsonAfterUpdate = AES.encrypt(itemsJsonAfterUpdate, AppConfig.secretPassphrase).toString();
        localStorage.setItem(this.CART_ITEM_JSON_LOCAL_STORAGE_KEY, encryptedItemsJsonAfterUpdate);
    }

    getItems() {

        const encryptedItemsJsonCache = localStorage.getItem(this.CART_ITEM_JSON_LOCAL_STORAGE_KEY);
        var itemsJson = '';
        if (encryptedItemsJsonCache) {
            itemsJson = AES.decrypt(encryptedItemsJsonCache, AppConfig.secretPassphrase).toString(cryptoEncUtf8);
        }
        var items: ICartItem[] = [];

        try {
            return JSON.parse(itemsJson || null) || [];
        } catch (e) {
            return items;
        }
    }

    clearCart() {
        localStorage.setItem(this.CART_ITEM_JSON_LOCAL_STORAGE_KEY, JSON.stringify([]));
    }
}