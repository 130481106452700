import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';
import { ItemService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-tax-dialog',
  templateUrl: '../../views/item/add-tax.dialog.component.html',
})
export class AddTaxDialogComponent implements OnInit {
  taxForm: FormGroup;
  inProgress: boolean = false;
  exitingTaxData: any;

  calculationPhases = [
    {
      value: 'TAX_SUBTOTAL_PHASE',
      title: 'TAX_SUBTOTAL_PHASE',
    },
    {
      value: 'TAX_TOTAL_PHASE',
      title: 'TAX_TOTAL_PHASE',
    },
  ];

  inclusionTypes = [
    {
      value: 'ADDITIVE',
      title: 'ADDITIVE',
    },
    {
      value: 'INCLUSIVE',
      title: 'INCLUSIVE',
    },
  ];

  appliesToCustomAmountsTypes = [
    {
      value: true,
      title: 'TRUE',
    },
    {
      value: false,
      title: 'FALSE',
    },
  ];

  name: FormControl;
  calculationPhase: FormControl;
  inclusionType: FormControl;
  percentage: FormControl;
  appliesToCustomAmounts: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddTaxDialogComponent>,
    public itemService: ItemService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.itemService.getTaxData().subscribe((taxData) => {
      this.exitingTaxData = taxData;

      this.name = new FormControl(
        !!this.exitingTaxData ? this.exitingTaxData.tax_data.name : '',
        [Validators.required]
      );
      this.calculationPhase = new FormControl(
        !!this.exitingTaxData
          ? this.exitingTaxData.tax_data.calculation_phase
          : '',
        [Validators.required]
      );
      this.inclusionType = new FormControl(
        !!this.exitingTaxData
          ? this.exitingTaxData.tax_data.inclusion_type
          : '',
        [Validators.required]
      );
      this.percentage = new FormControl(
        !!this.exitingTaxData ? this.exitingTaxData.tax_data.percentage : '',
        [Validators.required]
      );

      this.appliesToCustomAmounts = new FormControl(
        !!this.exitingTaxData
          ? this.exitingTaxData.tax_data.applies_to_custom_amounts
          : true,
        [Validators.required]
      );

      this.taxForm = this.formBuilder.group({
        name: this.name,
        calculationPhase: this.calculationPhase,
        inclusionType: this.inclusionType,
        percentage: this.percentage,
        appliesToCustomAmounts: this.appliesToCustomAmounts,
      });
    });
  }

  onSubmit() {
    this.inProgress = true;
    if (this.taxForm.invalid) {
      console.log(this.taxForm);
      return;
    }

    this.itemService
      .createTax({
        id: !!this.exitingTaxData ? this.exitingTaxData.id : '',
        name: this.name.value,
        calculationPhase: this.calculationPhase.value,
        inclusionType: this.inclusionType.value,
        percentage: this.percentage.value,
        appliesToCustomAmounts: this.appliesToCustomAmounts.value,
      })
      .toPromise()
      .then((res: any) => {
        this.inProgress = false;
        this.snackBar.open('Tax information updated', 'Close', {
          duration: 10000,
          panelClass: ['mat-success-bg'],
        });
        this.dialogRef.close();
      })
      .catch((err: HttpErrorResponse) => {
        this.inProgress = false;
        const errorDetail = err.error.error || err.error;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
