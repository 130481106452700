import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoleEnum } from 'src/app/enums/role.enum';
import { IItem } from 'src/app/models/item.model';
import { IOrderDetail } from 'src/app/models/order-detail.model';
import { AuthenticationService } from 'src/app/services';
import { OrderService } from 'src/app/services/order.service';
import { ShopService } from 'src/app/services/shop.service';
import { ProductDetailsDialogComponent } from '../product-details-dialog.component';

@Component({
    selector: 'showcased-products',
    styleUrls: ['../../../../assets/css/common/stream/stream-showcase-products.components.css'],
    templateUrl: '../../../views/common/session/stream-showcased-products.component.html',
})
export class StreamShowcasedProductsComponent implements OnInit, OnDestroy {

    @Input() streamId: string;
    @Input() retailerDomain: string;

    showcaseProductItems: IItem[] = [];

    constructor(
        public dialog: MatDialog,
        private shopService: ShopService) { }

    ngOnInit() {
        this.shopService
            .showcaseProductsByScheuleId(this.streamId)
            .toPromise()
            .then((items: IItem[]) => {
                this.showcaseProductItems = items.filter(function (item) { return item.price_money != null; });
            })
    }
    
    openProductDetailDialog(productItem) {
        this.dialog.open(
            ProductDetailsDialogComponent,
            {
                data: {
                    productId: productItem._id,
                    productItem: productItem,
                    retailerDomain: this.retailerDomain,
                    retailerStoreName: undefined
                },
                minWidth: '62%'
            });
    }

    ngOnDestroy(): void { }
}