import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { RoleEnum } from 'src/app/enums/role.enum';
import { IUserInfo } from 'src/app/models/user-info.model';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { ProductNaturesDialogComponent } from '../user/product-natures.dialog.user.component';
import { UpdateProfileDialogComponent } from '../user/update-profile.dialog.component';

@Component({
    selector: 'app-account',
    templateUrl: '../../views/account/account.component.html'
})
export class AccountComponent implements OnInit, OnDestroy {

    myAccount: IUserInfo;
    productNatures: any[];
    isRetailerAccount: boolean;
    userId: string;
    profileImgNotExist: boolean = false;
    profileImage: string;

    subscriptions = new Subscription();

    constructor(
        public dialog: MatDialog,
        private authService: AuthenticationService,
        private accountService: AccountService,
        private itemService: ItemService) { }

    ngOnInit(): void {

        this.userId = this.authService.currentUserId;
        this.isRetailerAccount = this.authService.currentUserRole == RoleEnum.retailer;
        this.loadAccountInfo();

    }

    loadAccountInfo() {
        this.itemService.categories().subscribe((productNatures: any) => {
            this.accountService
                .getUserInfoByUserId(this.userId)
                .toPromise()
                .then((retailerInfo) => {
                    this.productNatures = productNatures;
                    this.myAccount = retailerInfo;
                    this.profileImgNotExist = !this.myAccount?.profilePictureUrl && !this.myAccount?.logoUrl;
                    this.profileImage = retailerInfo.profilePictureUrl || retailerInfo.logoUrl;
                });
        });
    }

    getProductNatureNameById(id: string) {
        const category = this.productNatures.find(x => x.id === id);
        if (!category) return '';
        return category.category;
    }

    openUpdateProfileDialog() {
        if (this.myAccount) {
            const dialogRef = this.dialog.open(UpdateProfileDialogComponent, { data: this.myAccount });
            dialogRef.afterClosed().toPromise().then(res => {
                // this.loadAccountInfo();
                location.reload();
            });
        }
    }

    openEditProductNaturesDialog() {
        if (this.myAccount) {
            const dialogRef = this.dialog.open(ProductNaturesDialogComponent, { data: this.myAccount.productNatures });
            dialogRef.afterClosed().toPromise().then(res => {
                this.loadAccountInfo();
                location.reload();
            });
        }
    }

    profileError(){
        this.profileImgNotExist = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}