import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { ICreateOrderResponse } from '../models/create-order-response.model';
import { IOrderDetail } from '../models/order-detail.model';
import { IOrderListItem } from '../models/order-list-item';
import { IOrder } from '../models/order.model';
import { ISubscriptionDetail } from '../models/subscription-detail.model';
import { ISubscriptionListItem } from '../models/subscription-list-item.model';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class OrderService {

    protected apiServer = AppConfig.settings.apiServer;

    constructor(private http: HttpClient) { }

    createNewOrder(orderData: IOrder): Observable<ICreateOrderResponse> {
        const apiurl = `${this.apiServer.basepath}/orders`;
        return this.http.post<ICreateOrderResponse>(apiurl, orderData).pipe(
            map((res: any) => {
                return res;
            }));
    }

    subscribeExclusiveSession(subscriptionData: any, sessionId: string): Observable<any> {
        const apiurl = `${this.apiServer.basepath}/schedule/exclusive/${sessionId}/subscribe`;
        return this.http.post<any>(apiurl, subscriptionData).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getOrderListItemByUserId(userId: string): Observable<IOrderListItem[]> {
        const apiurl = `${this.apiServer.basepath}/orders/${userId}/lists`;
        return this.http.get<IOrderListItem[]>(apiurl).pipe(
            map((res: any) => {
                return res.order_list;
            }));
    }

    getOrderDetailByOrderId(orderId: string): Observable<IOrderDetail> {
        const apiurl = `${this.apiServer.basepath}/orders/${orderId}/details`;
        return this.http.get<IOrderDetail>(apiurl).pipe(
            map((res: any) => {
                return res;
            }));
    }

    geSubscriptionListItemByUserId(userId: string): Observable<ISubscriptionListItem[]> {
        const apiurl = `${this.apiServer.basepath}/users/${userId}/subscriptions`;
        return this.http.get<ISubscriptionListItem[]>(apiurl).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getSubscriptionDetailBySubscriptionId(subscriptionId: string): Observable<ISubscriptionDetail> {
        const apiurl = `${this.apiServer.basepath}/schedule/subscription/${subscriptionId}/details`;
        return this.http.get<ISubscriptionDetail>(apiurl).pipe(
            map((res: any) => {
                return res;
            }));
    }

    updateOrderStatusByOrderIdStatusCode(orderId: string, data: any): Observable<IOrderDetail> {
        const apiurl = `${this.apiServer.basepath}/orders/${orderId}/update-status`;
        return this.http.patch<IOrderDetail>(apiurl, data).pipe(
            map((res: IOrderDetail) => {
                return res;
            }));
    }
}