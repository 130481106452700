import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services';
import { RoleEnum } from 'src/app/enums/role.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';

@Component({
    selector: 'app-ssn-brdcrmb',
    templateUrl: '../../views/sessions/session-breadcrum.component.html'
})
export class SessionBreadcrumbComponent implements OnInit {

    isRetailerView: boolean = false;
    domain: string;
    sessionTitle: string;

    subscriptions = new Subscription();

    urlSegments: string[];

    constructor(
        private authService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private sessionManager: SessionComponentService,
        private router: Router) {
        this.urlSegments = this.router.url.split('/').filter(function (item) { return item; });
    }

    ngOnInit(): void {

        this.domain = this.activatedRoute.parent.snapshot.paramMap.get('storename')
            || this.activatedRoute.parent.snapshot.paramMap.get('domain');

        this.subscriptions.add(this.sessionManager.sessionTitle.subscribe((title: string) => { this.sessionTitle = title; }));

        if (this.urlSegments.indexOf('store') > -1
            && this.authService.currentUserRole === RoleEnum.retailer) {
            this.isRetailerView = true;
        }
    }
}