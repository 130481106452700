import { Injectable } from '@angular/core';
import { ICreatePaymentResponse } from '../models/create-payment-response.model';
import { IExclusiveSessionSubscriptionResponse } from '../models/exclusive-session-subscription-response.model';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class SessionService {

    constructor() { }

    get checkoutDomain(): string {
        return sessionStorage.getItem('checkoutDomain');
    }
    set checkoutDomain(domain:string) {
        sessionStorage.setItem('checkoutDomain', domain);
    }

    private _paymentresponse: ICreatePaymentResponse;
    get paymentresponse(): ICreatePaymentResponse {
        return this._paymentresponse;
    }
    set paymentresponse(response: ICreatePaymentResponse) {
        this._paymentresponse = response;
    }

    private _paymentErrorResponse: any;
    get paymentErrorResponse(): any {
        return this._paymentErrorResponse;
    }
    set paymentErrorResponse(response: any) {
        this._paymentErrorResponse = response;
    }

    private _isSubscription:boolean;
    get isSubscription(): boolean {
        return this._isSubscription;
    }
    set isSubscription(val: boolean){
        this._isSubscription = val;
    }

    private _subscriptionResponse:IExclusiveSessionSubscriptionResponse;
    get subscriptionResponse(): IExclusiveSessionSubscriptionResponse {
        return this._subscriptionResponse;
    }
    set subscriptionResponse(res: IExclusiveSessionSubscriptionResponse){
        this._subscriptionResponse = res;
    }

    private _subscriptionErrorResponse: any;
    get subscriptionErrorResponse(): any {
        return this._subscriptionErrorResponse;
    }
    set subscriptionErrorResponse(response: any) {
        this._subscriptionErrorResponse = response;
    }
}