import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ISchedule } from 'src/app/models/schedule.model';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';

@Component({
    selector: 'session-card',
    templateUrl: '../../../views/sessions/card/session-stream-card.component.html'
})
export class SessionStreamCardComponent implements OnInit, OnDestroy {

    subscription: Subscription = new Subscription();

    constructor(
        public dialog: MatDialog,
        private datePipe: DatePipe,
        private sessionManager: SessionComponentService) { }

    ngOnInit(): void {

        this.subscription.add(
            this.sessionManager.session.subscribe((session: ISchedule) => {

                this.hydrateStreamDuration(session.startTime, session.endTime);
            })
        );
    }

    hydrateStreamDuration(startTime: any, endTime: any) {
        
        const timeStart: any = new Date(startTime);
        const timeend: any = new Date(endTime);

        var diffMs = timeend - timeStart; // milliseconds between now & starttime
        var duration = Math.floor(diffMs / 60000); // in minutes
        if (duration > (60 * 24)) {
            duration = Math.round(duration / (60 * 24));
            const streamDuration = parseFloat(duration.toString()).toFixed(0);
            this.sessionManager.sessionDuration.emit(`${streamDuration}day`);
            return;
        }
        else if (duration > 59) {
            duration = Math.round(duration / (60));
            const streamDuration = parseFloat(duration.toString()).toFixed(0);
            this.sessionManager.sessionDuration.emit(`${streamDuration}hour`);
            return;
        }

        const streamDuration = parseFloat(duration.toString()).toFixed(0);
        this.sessionManager.sessionDuration.emit(`${streamDuration}min`);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}