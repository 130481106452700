import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import _ from 'lodash';
import * as moment from 'moment';

import { RoleEnum } from 'src/app/enums/role.enum';
import { AuthenticationService } from 'src/app/services';
import { NotificationService } from 'src/app/services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormattedNotification } from './notification-list.component';
import { NotificationStatusEnum } from 'src/app/enums/notification.enum';
import { Router } from '@angular/router';
import { ConversationService } from 'src/app/services/conversation.service';
import {
  HOME_ROUTE,
  UNSEEN_MESSAGE_COUNT_FETCH_INTERVAL,
  UNSEEN_NOTIFICATION_COUNT_FETCH_INTERVAL,
} from 'src/app/app.config';

@Component({
  selector: 'auth-menu',
  templateUrl: '../../views/header/authmenu.component.html',
  styleUrls: ['../../../assets/css/header/authmenu.component.css'],
})
export class AuthMenuComponent implements OnInit {
  currentUser: any;
  userDomain: string;
  isAuthenticated: boolean;

  isConsumer: boolean;
  isRetailer: boolean;
  isCreator: boolean;

  unseenNotificationsCount: number;
  unseenConversationCount: number;

  notificationList: Array<FormattedNotification>;
  showNotificationList: boolean;
  isFetchingNotification: boolean;
  homeRoute: string;

  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private conversationService: ConversationService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.unseenNotificationsCount = 0;
    this.unseenNotificationsCount = 0;
    this.isFetchingNotification = false;
    this.showNotificationList = false;
    this.homeRoute = HOME_ROUTE;
  }

  ngOnInit(): void {
    this.fetchAuthUser();
  }

  fetchAuthUser() {
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
      if (this.currentUser) {
        this.isAuthenticated = true;
        this.userDomain = this.currentUser.domain;
        this.isConsumer =
          this.authenticationService.isConsumer === RoleEnum.consumer;
        this.isRetailer =
          this.authenticationService.isRetailer === RoleEnum.retailer;
        this.isCreator =
          this.authenticationService.isCreator === RoleEnum.creator;

        this.setUpUnseenNotificationCountInterval();
        this.setUpUnseenMessageCountInterval();
      } else {
        this.isAuthenticated = false;
      }
    });
  }

  setUpUnseenNotificationCountInterval() {
    interval(UNSEEN_NOTIFICATION_COUNT_FETCH_INTERVAL).subscribe((x) => {
      this.notificationService
        .getUnseenNotificationsCount()
        .toPromise()
        .then((res: any) => {
          if (res.isSuccess) {
            this.unseenNotificationsCount = _.get(res, 'data.count', 0);
          }
        });
    });
  }

  setUpUnseenMessageCountInterval() {
    interval(UNSEEN_MESSAGE_COUNT_FETCH_INTERVAL).subscribe((x) => {
      this.conversationService
        .getAllUnseenConversationCount()
        .toPromise()
        .then((res: any) => {
          this.unseenConversationCount = res;
        });
    });
  }

  onMenuOpened() {
    this.fetchNotifications();
    this.markNotificationSeen();
  }

  onMenuClosed() {
    this.notificationList = [];
  }

  fetchNotifications() {
    this.isFetchingNotification = true;
    const defaultProfileImage = './assets/images/default-profile-pic.jpg';
    this.notificationService
      .getNotifications()
      .toPromise()
      .then((res: any) => {
        if (!res.isSuccess) {
          this.snackBar.open('Something went wrong!', 'Close', {
            duration: 10000,
            panelClass: ['mat-error-bg'],
          });
        } else {
          this.notificationList = _.map(res.data, (notification) => {
            const retailerStoreImage =
              _.get(notification, 'retailer.image') ?? defaultProfileImage;
            const creatorStoreImage =
              _.get(notification, 'creator.image') ?? defaultProfileImage;

            const retailerStoreName = _.get(notification, 'retailer.storeName');
            const creatorStoreName = _.get(notification, 'creator.storeName');
            const productName = _.get(notification, 'product.name');
            const projectId = _.get(notification, 'project._id');
            const product = _.get(notification, 'product');
            const statusFormatted = _.get(
              notification,
              'status',
              ''
            ).toLowerCase();
            const createdAtFormatted = moment(notification.createdAt).format(
              'hh:MM A'
            );

            return {
              _id: _.get(notification, '_id') ?? _.get(notification, 'id'),
              storeImage:
                notification.status === NotificationStatusEnum.INVITED
                  ? retailerStoreImage
                  : creatorStoreImage,
              storeName:
                notification.status === NotificationStatusEnum.INVITED
                  ? retailerStoreName
                  : creatorStoreName,
              creatorStoreName,
              productName,
              createdAt: notification.createdAt,
              status: notification.status,
              projectId,
              product,
              creator: notification.creator,
              retailer: notification.retailer,
              statusFormatted,
              createdAtFormatted,
            };
          });
          this.showNotificationList = _.size(this.notificationList);
        }
      })
      .catch((err) => {
        const errorDetail = err.error.error || err.error;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      })
      .finally(() => {
        this.isFetchingNotification = false;
      });
  }

  markNotificationSeen() {
    this.notificationService.markAllNotificationSeen().subscribe((res) => {
      console.log('Marked all notifications as seen.', res);
    });
  }

  openMessageContainer() {
    this.router.navigate(['/messages']);
  }
}
