import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DeleteSessionDialogData {
    sessionTitle: string;
}

@Component({
    selector: 'delete-session-dialog',
    templateUrl: '../../../views/common/session/delete-session-dialog.html'
})
export class DeleteSessionDialogComponent implements OnInit {

    inProgress: boolean;
    data: DeleteSessionDialogData;

    constructor(
        public dialogRef: MatDialogRef<DeleteSessionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogdata: DeleteSessionDialogData) {
        this.data = dialogdata;
    }

    ngOnInit(): void {
    }

    onCancelClick() {
        this.dialogRef.close({ close: false });
    }

    deleteSession() {
        this.inProgress = true;
        this.dialogRef.close({ delete: true });
    }
}
