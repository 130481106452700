import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateStreamDialogComponent } from './create-stream.dialog.component';
import { FormBuilder } from '@angular/forms';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { AuthenticationService } from 'src/app/services';
import { ISchedule } from 'src/app/models/schedule.model';
import { ItemService } from 'src/app/services/item.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from 'src/app/app.config';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'retailer-streams',
  templateUrl: '../../views/store/retailer-streams.store.component.html',
  styleUrls: ['../../../assets/css/store/retailer-streams.store.component.css'],
})
export class RetailerStreamsComponent implements OnInit, OnDestroy {
  domain: string;
  streamList: any[] = [];
  streamListBackup: any[] = [];
  loggedInUserId: string;

  tablePageSize: number = 10;
  tablePageIndex: number = 0;
  tableDatasource: any[] = [];

  streamType: string;
  searchKeyword: string;
  filterStatus: number;
  dateFrom: string;
  dateTo: string;

  streamLoading: boolean = true;

  constructor(
    @Inject(DOCUMENT) document,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public itemService: ItemService,
    private retailerStoreService: RetailerStoreService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loggedInUserId = this.authenticationService.currentUserId;
    this.domain = this.activatedRoute.parent.snapshot.paramMap.get('domain');
    this.loadStreamList();
  }

  openCreateNewStreamDialog(): void {
    const loggedInUserLocationId =
      this.authenticationService.currentUserLocationId;
    if (!loggedInUserLocationId) {
      this.snackBar.open(
        "You cann't create stream before updating your location data. Go to account setting to update",
        'Close',
        { duration: 10000, panelClass: ['mat-error-bg'] }
      );
      return;
    }

    const dialogref = this.dialog.open(CreateStreamDialogComponent);
    dialogref.afterClosed().subscribe((res) => {
      if (res && res.created) {
        //this.loadStreamList();
        this.router.navigate([
          `/store/${this.domain}/sessions/${res.streamId}`,
        ]);
      }
    });
  }

  loadStreamList(): void {
    this.retailerStoreService
      .allSchedules(this.loggedInUserId)
      .toPromise()
      .then((scheduleList: ISchedule[]) => {
        this.streamList = scheduleList.sort((a, b) => {
          return (
            new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
          );
        });
        this.streamListBackup = this.streamList;
        const items = [...this.streamList];
        this.tableDatasource = items.splice(
          this.tablePageIndex * this.tablePageSize,
          this.tablePageSize
        );
        this.streamLoading = false;
      });
  }

  parseDuration(starttime, endTime): string {
    const duration = Math.abs(
      (Math.abs(new Date(starttime).getTime() - new Date(endTime).getTime()) /
        36e5) *
        60
    ).toFixed(2);
    return `${duration}m`;
  }
  parseStatus(status: Number) {
    switch (status) {
      case 1:
        return 'On Air';
      case 2:
        return 'Completed';
      case undefined:
      case 0:
        return 'Scheduled';
    }
  }

  formatImageUrl(url: string): string {
    return url;
  }
  hasInProgressStatus(status: Number) {
    return status == 1;
  }
  hasClosedStatus(status: Number) {
    return status == 2;
  }
  hasScheduledStatus(status: Number) {
    return status == 0 || status == undefined || status == null;
  }
  pageChanged(event: PageEvent) {
    this.tablePageIndex = event.pageIndex;
    this.tablePageSize = event.pageSize;

    const items = [...this.streamList];
    this.tableDatasource = items.splice(
      this.tablePageIndex * this.tablePageSize,
      this.tablePageSize
    );
  }

  filterStreamList(): void {
    var self = this;
    var filterList = self.streamListBackup.filter((o) => {
      if (!self.searchKeyword) return true;
      return Object.keys(o).some((k) =>
        o[k].toString().toLowerCase().includes(self.searchKeyword.toLowerCase())
      );
    });

    filterList = filterList.filter((o) => {
      if (self.streamType == '-1' || !self.streamType) return true;
      return o.type.toLowerCase() == self.streamType.toLocaleLowerCase();
    });

    filterList = filterList.filter((o) => {
      if (self.filterStatus == -1 || !self.filterStatus) return true;
      return o.status == self.filterStatus;
    });

    filterList = filterList.filter(function (fitem) {
      if (!self.dateFrom || !self.dateFrom) return true;
      return (
        new Date(fitem.startTime).getTime() >= new Date(self.dateFrom).getTime()
      );
    });

    filterList = filterList.filter(function (fitem) {
      if (!self.dateTo || !self.dateTo) return true;
      return (
        new Date(fitem.startTime).getTime() <= new Date(self.dateTo).getTime()
      );
    });

    const items = [...filterList];
    self.tableDatasource = items.splice(
      self.tablePageIndex * self.tablePageSize,
      self.tablePageSize
    );
  }

  openSelectedStream(streamId: string) {
    this.router.navigate([`/store/${this.domain}/sessions/${streamId}`]);
  }

  ngOnDestroy(): void {}
}
