import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-consumer-view-store',
    template: '<router-outlet></router-outlet>',
    styleUrls: ['../../../assets/css/shops/consumer-view.store.component.css']
})
export class ConsumerViewStoreComponent implements OnInit, OnDestroy {

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

}
