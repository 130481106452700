import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'stop-stream-dialog',
    templateUrl: '../../views/store/stop-stream-dialog.html'
})
export class StopStreamingDialogComponent implements OnInit {

    inProgress:boolean;

    constructor(
        public dialogRef: MatDialogRef<StopStreamingDialogComponent>,) {
    }

    ngOnInit(): void {
    }

    onCancelClick() {
        this.dialogRef.close({ close: false });
    }

    endStream() {
        this.inProgress = true;
        this.dialogRef.close({ close: true });
    }
}
