import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { IItemCategory } from 'src/app/models';
import { IExclusiveSession } from 'src/app/models/exclusive-session.model';
import { ItemService } from 'src/app/services';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
    selector: 'all-exclusive-session',
    templateUrl: '../../views/sessions/all-exclusive-sessions.component.html'
})
export class AllExclusiveSessionComponent implements OnInit, OnDestroy {

    sessions: IExclusiveSession[] = [];
    loadingResult: boolean = true;
    productNatures: IItemCategory[];
    private subscriptions = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private scheduleService: ScheduleService,
        private itemService: ItemService,
        private datePipe: DatePipe) { }

    ngOnInit(): void {
        this.itemService.categories().toPromise().then((productNatures: any) => {
            this.scheduleService
                .loadAllExclusiveSessions()
                .toPromise()
                .then((sessions: any) => {
                    this.productNatures = productNatures;
                    this.sessions = sessions;
                    this.loadingResult = false;
                });
        });
    }

    getProductNatureNameById(id: string) {
        const category = this.productNatures.find(x => x.id === id);
        if (!category) return '';
        return category.category;
    }

    parseStreamStatus(statusEnumValue: StreamStatusEnum, startTime: Date): string {
        switch (statusEnumValue) {
            case StreamStatusEnum.IN_PROGRESS:
                return 'On Air';
            case StreamStatusEnum.PENDING:
                return this.datePipe.transform(startTime, 'hh:mm a MMM yy')
        }
    }

    hasInProgressStatus(statusEnumValue: StreamStatusEnum): boolean {
        return statusEnumValue == StreamStatusEnum.IN_PROGRESS;
    }

    onImageError(event$: any, defaultImagePath: string): void {
        event$.target.src = defaultImagePath;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
