import { Component, OnInit } from '@angular/core';
import { RoleEnum } from 'src/app/enums/role.enum';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'auth-menu',
  templateUrl: '../../views/header/authmenu.component.html',
  styleUrls: ['../../../assets/css/header/authmenu.component.css']
})
export class AuthMenuComponent implements OnInit {

  currentUser: any;
  userDomain: string;
  isAuthenticated: boolean;
  isRetailer:boolean = false;
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x
      if (this.currentUser) {
        this.isAuthenticated = true;
        this.userDomain = this.currentUser.domain;
        this.isRetailer = this.authenticationService.currentUserRole == RoleEnum.retailer;
      } else {
        this.isAuthenticated = false;
      }
    });
  }
}
