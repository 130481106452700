import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { CancelSessionDialogComponent } from '../../common/session/cancel-session-dialog.component';
import { DeleteSessionDialogComponent } from '../../common/session/delete-session-dialog.component';

@Component({
    selector: 'session-card-header',
    templateUrl: '../../../views/sessions/card/session-stream-card-header.component.html'
})
export class SessionStreamCardHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

    onAir: boolean = false;
    publisingStream: boolean = false;

    isPending: boolean;
    isComplete: boolean;

    sessionStartTime: Date;
    sessionDuration: number;
    sessionId: string;
    sessionTitle: string;
    storeName: string;
    isExclusive: boolean;

    conversationEnabled: boolean = true;

    subscriptions: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        public sessionManager: SessionComponentService,
        private scheduleService: ScheduleService,
        private snackBar: MatSnackBar) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.sessionManager.status.subscribe((status: number) => {
                this.onAir = status === StreamStatusEnum.IN_PROGRESS;
                this.isPending = status === StreamStatusEnum.PENDING;
                this.isComplete = status === StreamStatusEnum.CLOSED;
            })
        );
        this.subscriptions.add(
            this.sessionManager.sessionDuration.subscribe((duration: number) => {
                this.sessionDuration = duration;
            })
        );
        this.subscriptions.add(
            this.sessionManager.sessionStartTime.subscribe((startTime: any) => {
                this.sessionStartTime = startTime;
            })
        );
        this.subscriptions.add(
            this.sessionManager.publisingStream.subscribe((publishing: boolean) => {
                this.publisingStream = publishing;
            })
        );
        this.subscriptions.add(
            this.sessionManager.conversationEnabled.subscribe((enabled: boolean) => {
                this.conversationEnabled = enabled;
            })
        );
        this.subscriptions.add(
            this.sessionManager.sessionId.subscribe((id: string) => {
                this.sessionId = id;
            })
        );
        this.subscriptions.add(
            this.sessionManager.isExclusive.subscribe((res: boolean) => {
                this.isExclusive = res;
            })
        );
        this.subscriptions.add(this.sessionManager.sessionTitle.subscribe((res: string) => { this.sessionTitle = res; }));
        this.storeName = this.activatedRoute.parent.snapshot.paramMap.get('domain');
    }

    ngAfterViewInit(): void {
        this.sessionManager.configureConversationContentPlacement.emit(true);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    startStream(): void {
        this.sessionManager.startStream.emit(true);
    }

    stopStream(): void {
        this.sessionManager.stopStream.emit(true);
    }

    onConversationEnabledChanged($event) {
        const enabled = $event.checked;
        this.scheduleService
            .toggleExclusiveSessionEnableChatOption(this.sessionId, enabled)
            .toPromise()
            .then((res: any) => {
                if (res.isSuccess.toLowerCase() === "true") {
                    this.sessionManager.conversationEnabled.emit(enabled);
                }
            });
    }

    deleteStream() {

        const dialogRef = this.dialog.open(DeleteSessionDialogComponent, { data: { sessionTitle: this.sessionTitle } });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result.delete) {
                this.snackBar.open(`Deleting session ${this.sessionTitle}`, "Action", { duration: undefined });
                this.scheduleService
                    .deleteScheduleById(this.sessionId)
                    .toPromise()
                    .then((res: any) => {
                        if (res.isSuccess && res.isSuccess == "true") {
                            this.snackBar.open(`Deleted`, "Action", { duration: 2000 });
                            window.location.href = `/store/${this.storeName}/sessions`;
                        }
                        else {
                            this.snackBar.open(res.message, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
                        }
                    });
            }
        });
    }

    cancelStream() {
        const dialogRef = this.dialog.open(CancelSessionDialogComponent, { data: { sessionTitle: this.sessionTitle } });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result.cancel) {
                this.snackBar.open(`Canceling session ${this.sessionTitle}`, "Action", { duration: undefined });
                this.scheduleService
                    .cancelScheduleById(this.sessionId)
                    .toPromise()
                    .then((res: any) => {
                        if (res.isSuccess && res.isSuccess == "true") {
                            this.snackBar.open(`Canceled`, "Action", { duration: 2000 });
                            window.location.href = `/store/${this.storeName}/sessions`;
                        }
                        else {
                            this.snackBar.open(res.message, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
                        }
                    });
            }
        });
    }
}