import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IPayment } from '../models/payment.model';
import { CartService } from './cart.service';
import { PaymentService } from './payment.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class SquaurePaymentService {

    constructor(
        private paymentService: PaymentService,
        private session: SessionService,
        private cartService: CartService,
        private router:Router){}

    private _applicationId: string;
    get applicationId(): string {
        return this._applicationId;
    }
    set applicationId(id: string) {
        this._applicationId = id;
    }

    private _locationId: string;
    get locationId(): string {
        return this._locationId;
    }
    set locationId(id: string) {
        this._locationId = id;
    }

    async initializeCard(payments: any): Promise<any> {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    };

    async tokenize(paymentMethod: any): Promise<any> {

        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }
            throw new Error(errorMessage);
        }
    };

    async handlePaymentMethodSubmission(paymentMethod: any, paymentData: IPayment, spinnerDialogRef: any): Promise<any> {

        try {
            //console.log("in  handlePaymentMethodSubmission: ");
            const token = await this.tokenize(paymentMethod);
            paymentData.source_id = token;

            //console.log("in  handlePaymentMethodSubmission: got token ");

            const paymentResults = await this.paymentService.createPayment(paymentData).toPromise();
            //const paymentResults = await this.paymentService.createPayment(token).toPromise();

            //console.log("in  handlePaymentMethodSubmission: created payment ");
            this.session.paymentresponse = paymentResults;
            //console.log("payment response is: "+paymentResults);
            spinnerDialogRef.close();
            this.cartService.clearCart();
            this.router.navigate([`/shops/checkout/success`]);

        } catch (e) {
            const myArray = Object.values(paymentData);
            //console.log("payment failed: amount "+paymentData.amount_money.amount+" orderid: "+paymentData.order_id+" sourceid: "+paymentData.source_id+" locationid: "+paymentData.location_id);
            spinnerDialogRef.close();
            this.session.paymentErrorResponse = e;
            //this.router.navigate([`/shops/checkout/failed`]);
            this.router.navigate([`/shops/checkout/broke`]);
        }
    }
}