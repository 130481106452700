<div class="mw-580px-i mnw-320px">
    <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">Update profile information</h1>
    <div mat-dialog-content>
        <form name="storeInformationForm" [formGroup]="storeInfoForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
            <div class="pb-10 pt-10">
                <div class="pb-10 pt-10">
                    <div class="form-item">
                        <label class="p3-dark-fwm mb-15 d-block text-black">Profile Picture (optional)</label>
                        <div class="p-15 bg-blk-004">
                            <input id="profilePicture" class="mat-stroke-button-file-input" type="file" (change)="onProfileImageChange($event)" />
                        </div>
                        <mat-hint class="d-block w-100 mt-10 pl-10-i">Please upload a square image with minimum 200x200px resolution and size smaller than 1mb.
                        </mat-hint>
                    </div>
                    <div class="form-item mt-25">
                        <label class="p3-dark-fwm mb-15 d-block text-black">Contact Information</label>
                        <div class="row d-block">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Phone No (Optional)</mat-label>
                                    <input matInput placeholder="phoneNumber" [formControl]="phoneNumber">
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput placeholder="Address Line 1" [formControl]="addressLine1" required>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Address Line 2 (optional)</mat-label>
                                    <input matInput placeholder="addressLine2" [formControl]="addressLine2">
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>State</mat-label>
                                    <input matInput placeholder="state" [formControl]="state" required>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row d-block">
                            <div class="col-6 pr-5">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City" [formControl]="cityOrTown" required>
                                </mat-form-field>
                            </div>
                            <div class="col-6 pl-5">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Zip</mat-label>
                                    <input matInput placeholder="zip" [formControl]="zipCode" required>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div mat-dialog-actions class="pstn-relative">
                    <mat-spinner diameter="25" class="color-primary pstn-absolute t24 l4" [class.hidden]="!inProgress">
                    </mat-spinner>
                    <button mat-flat-button class="tt-upper mr-10 text-primary" (click)="closeDialog()"><span>Skip Now</span></button>
                    <button mat-raised-button color="primary" type="submit" class="tt-upper">
                        <span>Update Profile</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>