import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';

@Pipe({
    name: 'asyncImgSrc'
})
export class AsyncImgSrcPipe implements PipeTransform {

    constructor(private http: HttpClient) { }

    transform(imageId: string) {
        return new Observable<any>((observer) => {
            
            // handle null image id
            if (!imageId) {
                observer.next('/assets/images/default-product-image.png');
                return { unsubscribe() { } };
            }

            // This is a tiny blank image
            observer.next('/assets/images/ajax-loader.gif');

            // The next and error callbacks from the observer
            const { next, error } = observer;
            const apiUrl: string = `${AppConfig.settings.apiServer.basepath}/items/${imageId}`;
            this.http.get(apiUrl).subscribe((response:any) => {
                observer.next(response.url);
            });
            return { unsubscribe() { } };
        });
    }
}