import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  ActivateaccountComponent,
  HomeComponent,
  LoginComponent,
  LogoutComponent,
  SignupComponent,
  SignupVerificationComponent,
} from './components';
import { AccountComponent } from './components/account/account.component';
import { SubscriptionDetailComponent } from './components/account/subscription-detail.component';
import { SubscriptionsComponent } from './components/account/subscriptions.component';
import { OrderDetailComponent } from './components/common/order-detail.component';
import { ResetPasswordComponent } from './components/login/reset-password.component';
import { UpdatePasswordComponent } from './components/login/update-password.component';
import { SearchComponent } from './components/search/search.component';
import { CheckoutFailedComponent } from './components/shops/checkout-failed.shops.component';
import { SessionComponent } from './components/session/session.component';
import { StoreComponent } from './components/store/store.component';
import { AuthGuardService } from './services/auth-gaurd.service';
import { StoreAuthGuardService } from './services/store-auth-gaurd.service';
import { ConsumerOrdersComponent } from './components/shops/consumer-orders.component';
import { RetailerOrdersComponent } from './components/store/retailer-orders.component';
import { RetailerStreamsComponent } from './components/store/retailer-streams.store.component';
import { CheckoutComponent } from './components/shops/checkout.shops.component';
import { CheckoutSuccessComponent } from './components/shops/checkout-success.shops.component';
import { AllShopsComponent } from './components/shops/all-shops.component';
import { NewInShopsComponent } from './components/shops/new-in-shops.component';
import { AllExclusiveSessionComponent } from './components/session/all-exclusive-sessions.component';
import { ItemListComponent } from './components/item/item-list.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'register', component: SignupComponent },
  { path: 'register/retailer', component: SignupComponent },
  { path: 'verify', component: SignupVerificationComponent },
  { path: 'activate/:code/:isMobile', component: ActivateaccountComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'search', component: SearchComponent },
  { path: 'sessions/exclusive/all', component: AllExclusiveSessionComponent },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'account/orders',
    component: ConsumerOrdersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'account/orders/:orderId',
    component: OrderDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'account/subscriptions',
    component: SubscriptionsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'account/subscriptions/:subscriptionId',
    component: SubscriptionDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'account/:domain/subscriptions',
    component: SubscriptionsComponent,
    canActivate: [StoreAuthGuardService],
  },
  {
    path: 'account/:domain/subscriptions/:subscriptionId',
    component: SubscriptionDetailComponent,
    canActivate: [StoreAuthGuardService],
  },
  { path: 'account/reset-password', component: ResetPasswordComponent },
  {
    path: 'account/reset-password/update/:code',
    component: UpdatePasswordComponent,
  },
  {
    path: 'store/:domain',
    component: StoreComponent,
    canActivate: [StoreAuthGuardService],
    children: [
      {
        path: 'orders',
        component: RetailerOrdersComponent,
        canActivate: [StoreAuthGuardService],
      },
      {
        path: 'orders/:orderId',
        component: OrderDetailComponent,
        canActivate: [StoreAuthGuardService],
      },
      {
        path: 'subscribers',
        component: SubscriptionsComponent,
        canActivate: [StoreAuthGuardService],
      },
      {
        path: 'subscribers/:subscriberId',
        component: SubscriptionDetailComponent,
        canActivate: [StoreAuthGuardService],
      },
      {
        path: 'sessions',
        component: RetailerStreamsComponent,
        canActivate: [StoreAuthGuardService],
      },
      {
        path: 'sessions/:sessionId',
        component: SessionComponent,
        canActivate: [StoreAuthGuardService],
      },
      {
        path: 'items',
        component: ItemListComponent,
        canActivate: [StoreAuthGuardService],
      },
    ],
  },
  { path: 'shops/all', component: AllShopsComponent },
  { path: 'shops/all/:categoryId', component: AllShopsComponent },
  { path: 'shops/new-in', component: NewInShopsComponent },
  { path: 'shops/checkout', component: CheckoutComponent },
  { path: 'shops/checkout/success', component: CheckoutSuccessComponent },
  { path: 'shops/checkout/failed', component: CheckoutFailedComponent },
  {
    path: 'shops/:storename',
    component: StoreComponent,
    children: [
      { path: 'sessions/:sessionId', component: SessionComponent },
      { path: 'sessions/exclusive/:sessionId', component: SessionComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
