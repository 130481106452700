import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from '../environments/environment';
import { IAppConfig } from './models/app-config.model';

import  * as AES  from 'crypto-js/aes';
import * as cryptoEncUtf8 from 'crypto-js/enc-utf8';

@Injectable()
export class AppConfig {

    public static settings: IAppConfig;
    private httpClient: HttpClient;

    constructor(handler: HttpBackend) {
        this.httpClient = new HttpClient(handler);
    }

    load() {
        const jsonFile = `assets/config/config.${environment.name}.json`;
        return new Promise<void>((resolve, reject) => {
            this.httpClient.get(jsonFile).toPromise().then((response: IAppConfig) => {
                AppConfig.settings = <IAppConfig>response;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }

    static get secretPassphrase(): string {
        return AppConfig.settings.appConfig.aesSecretPassphrase;
    }

    static get currentUserObjectFromLocalStorage(): any {

        const encryptedUserData = localStorage.getItem(AppConfig.settings.localStorage.keys.currentUser);
        if (!encryptedUserData) return null;

        const decryptedUserData = AES.decrypt(encryptedUserData, this.secretPassphrase).toString(cryptoEncUtf8);
        const userJson = JSON.parse(decryptedUserData as any);
        return userJson;
    }

    static getEncryptedUserDataByUserObject(user: any): string {
        const json = JSON.stringify(user);
        const encryptedUserData = AES.encrypt(json, this.secretPassphrase).toString();
        return encryptedUserData;
    }
}