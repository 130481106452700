import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'footer-nav',
  templateUrl: '../../views/footer/footer-nav.component.html',
  styleUrls: ['../../../assets/css/footer/footer-nav.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
