<div class="mw-580px-i mnw-320px">
    <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">Update store information</h1>
    <div mat-dialog-content>
        <form name="storeInformationForm" [formGroup]="storeInfoForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
            <div class="pb-10 pt-10">
                <div class="pb-10 pt-10">
                    <div class="form-item">
                        <label class="p3-dark-fwm mb-15 d-block text-black">Store Logo (optional)</label>
                        <div class="p-15 bg-blk-004">
                            <input id="storeLogo" class="mat-stroke-button-file-input" type="file" (change)="onStoreLogoChange($event)" />
                        </div>
                        <mat-hint class="d-block w-100 mt-10 pl-10-i">Please upload a square image with minimum
                            200x200px
                            resolution and size smaller than
                            1mb.</mat-hint>
                    </div>
                    <div class="form-item mt-25">
                        <label class="p3-dark-fwm mb-15 d-block text-black">Store Banner Image (optional)</label>
                        <div class="p-15 bg-blk-004">
                            <input id="storeBanner" class="mat-stroke-button-file-input" type="file" (change)="onStoreBannerChange($event)" />
                        </div>
                        <mat-hint class="d-block w-100 mt-10 pl-10-i">Please upload an image with minimum 1920x480px
                            resolution and size smaller than 2mb.</mat-hint>
                    </div>
                    <div class="form-item mt-25">
                        <label class="p3-dark-fwm mb-15 d-block text-black">Contact Information</label>
                        <div class="row d-block">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Phone No</mat-label>
                                    <input matInput placeholder="phoneNumber" [formControl]="phoneNumber" required>
                                    <mat-error *ngIf="phoneNumber.invalid">{{getErrorMessage('phoneNumber')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput placeholder="Address Line 1" [formControl]="addressLine1" required>
                                    <mat-error *ngIf="addressLine1.invalid">{{getErrorMessage('addressLine1')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Address Line 2 (optional)</mat-label>
                                    <input matInput placeholder="addressLine2" [formControl]="addressLine2">
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>State</mat-label>
                                    <input matInput placeholder="state" [formControl]="state" required>
                                    <mat-error *ngIf="state.invalid">{{getErrorMessage('state')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row d-block">
                            <div class="col-6 pr-5">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City" [formControl]="cityOrTown" required>
                                    <mat-error *ngIf="cityOrTown.invalid">{{getErrorMessage('cityOrTown')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 pl-5">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Zip</mat-label>
                                    <input matInput placeholder="zip" [formControl]="zipCode" required>
                                    <mat-error *ngIf="zipCode.invalid">{{getErrorMessage('zipCode')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div mat-dialog-actions class="pstn-relative">
                    <mat-spinner diameter="25" class="color-primary pstn-absolute t24 l4" [class.hidden]="!inProgress">
                    </mat-spinner>
                    <button mat-raised-button color="primary" type="submit" class="tt-upper">
                        <span>Update Store Info</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>