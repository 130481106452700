import { Pipe, PipeTransform } from '@angular/core';
import { ICartItem } from '../models/cart-item.model';

@Pipe({
  name: 'tax'
})
export class TaxPipe implements PipeTransform {

  transform(cartItms: ICartItem[]): any {
    let total_tax = 0
    cartItms.forEach((y) => {
        if( y.tax_details ){
            total_tax += ( Number(y.tax_details.percentage)/100 ) * (y.unitPrice*y.quantity)
        }
    });
    return total_tax/100
  }
}
