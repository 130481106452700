import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, publishLast, refCount, startWith, switchMap } from 'rxjs/operators';
import { SearchTypeEnum } from 'src/app/enums/search-type.enum';
import { ISearchResultTypeAhead } from 'src/app/models/search-result-typeahead.model';
import { SearchService } from 'src/app/services/search-service';

@Component({
  selector: 'app-searchbar',
  templateUrl: '../../views/header/searchbar.component.html',
  styleUrls: ['../../../assets/css/header/searchbar.component.css']
})
export class SearchbarComponent implements OnInit, OnDestroy {

  inProgress: boolean = false;

  searchKeyword: string;
  filteredOptions: Observable<ISearchResultTypeAhead[]> = new Observable((observer) => { observer.next([]) });
  private subscriptions = new Subscription();

  constructor(
    private eRef: ElementRef,
    private router: Router,
    private searchService: SearchService) { }

  ngOnInit(): void { }

  onSearchKeywordChanged() {
    this.inProgress = false;
    if (this.searchKeyword) {
      this.inProgress = true;
      this.filteredOptions = this.searchService.searchShopsScheduleBy(this.searchKeyword, SearchTypeEnum.TYPE_AHEAD).pipe(
        map(response => {
          this.inProgress = false;
          return [response];
        }),
        publishLast(),
        refCount());
    }
  }

  resetfilteredOptions() {
    this.filteredOptions = new Observable((observer) => { observer.next([]) });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.resetfilteredOptions();
    }
  }

  navigateSearchPage() {
    if (this.searchKeyword) {
      this.router.navigate([`/search`], { queryParams: { q: this.searchKeyword } });
    }
  }

  parseSessionUrlPath(sessionType: string) {
    
    if (sessionType.toLowerCase().indexOf('showcase') > -1)
      return 'sessions';

    return 'sessions/exclusive';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
