import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AccountService, RouteService } from 'src/app/services';

@Component({
  selector: 'activate-account',
  templateUrl: '../../views/signup/activate.signup.component.html',
  styleUrls: ['../../../assets/css/signup/signup.component.css'],
})
export class ActivateaccountComponent implements OnInit {
  activationCode: string;
  verificationComplete: boolean = false;
  errorMsg: string = '';
  isMobile = false;

  constructor(
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  openLogin = () => {
    window.location.href = this.isMobile
      ? `marketplace0://?screenName=Auth&screenData=SIGN_IN`
      : '/login';
  };

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.activationCode = routeParams.get('code');
    this.isMobile = routeParams.get('isMobile') === 'true';
    this.accountService
      .verifyEmail(this.activationCode)
      .toPromise<string>()
      .then((res) => {
        this.verificationComplete = true;
      })
      .catch((err: HttpErrorResponse) => {
        if (err.error.error == 'Already Verified') {
          this.verificationComplete = true;
        } else {
          this.verificationComplete = true;
          this.snackBar.open(err.error.error, 'Close', {
            duration: 10000,
            panelClass: ['mat-error-bg'],
          });
        }
      });
  }
}
