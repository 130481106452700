<section
  id="searchPageContent"
  class="w-100 pl-80 pr-80 p-20 p-sm-20 pl-sm-20 pr-sm-20 ff-roboto"
>
  <div class="mb-15 d-flex">
    <h1 class="text-black fs-24-i fw-500-i lh-24-i m-0-i">
      Search Results for "{{ searchKeyword }}"
    </h1>
  </div>
  <div class="w-100 b-s1-00024 brds-4px pstn-relative">
    <mat-spinner
      diameter="20"
      class="color-primary pstn-absolute-t0-r0-i mr-20 mt-10"
      [class.hidden]="!loadingResult"
    ></mat-spinner>
    <mat-tab-group mat-align-tabs="start">
      <mat-tab class="tt-upper" label="Product Demos">
        <div class="pl-15 pt-25">
          <h4
            class="ml-5-i fs-16-i fw-500-i text-0087 lh-24-i mt-10"
            *ngIf="searchResult && searchResult?.demos"
          >
            {{ searchResult?.demos.length }} results found
          </h4>
          <div
            class="d-flex-row flex-wrap w-100 pstn-relative"
            *ngIf="searchResult && searchResult?.demos.length > 0"
          >
            <mat-card
              class="ml-5-i mr-5-i flex-bss-24 flex-bss-100-sm flex-bss-32-md p-0-i mb-20 bt-0-i"
              *ngFor="let item of searchResult?.demos"
            >
              <a
                class="d-flex pstn-relative m-0-i w-100 lb-card-img-contaier"
                href="/shops/{{ item.user[0].domain }}/{{
                  parseSessionUrlPath(item.type)
                }}/{{ item._id }}"
              >
                <img
                  class="w-100 h-100 mb-0-i lb-card-img"
                  src="{{ combineBackendApiUrl(item.streamThumbnailUrl) }}"
                />
              </a>
              <mat-card-content class="mb-50-i p-15-i">
                <span
                  *ngIf="hasInProgressStatus(item.status)"
                  class="bg-primary fs-10 fw-500 brds-50px text-white ff-roboto p-2px-8px"
                  >{{ parseStreamStatus(item.status, item.startTime) }}</span
                >
                <span
                  *ngIf="!hasInProgressStatus(item.status)"
                  class="fs-10 fw-600 text-0004 ff-roboto"
                  >{{ parseStreamStatus(item.status, item.startTime) }}</span
                >
                <h5 class="p3-dark-fwm mt-10-i fs-13-i">
                  <a
                    class="p3-dark-fwm mt-10-i fs-13-i"
                    href="/shops/{{ item.user[0].domain }}/{{
                      parseSessionUrlPath(item.type)
                    }}/{{ item._id }}"
                    >{{ item.title }}</a
                  >
                </h5>
              </mat-card-content>
              <mat-card-footer
                class="p-15-i d-flex-row-i ml-0-i pstn-absolute-b16"
              >
                <a class="d-flex-row" href="/shops/{{ item.user[0].domain }}">
                  <img
                    *ngIf="item.user[0].logoUrl"
                    src="{{ combineBackendApiUrl(item.user[0].logoUrl) }}"
                    class="w-32px brds-100 b-s1 bc-00012"
                  />
                  <mat-icon
                    *ngIf="!item.user[0].logoUrl"
                    class="w-32px brds-100 b-s1 bc-00012"
                  >
                    storefront</mat-icon
                  >
                  <span
                    *ngIf="item.user[0].storeName"
                    class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
                    >{{ item.user[0].storeName }}</span
                  >
                  <span
                    *ngIf="!item.user[0].storeName"
                    class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
                    >Store</span
                  >
                </a>
              </mat-card-footer>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab class="tt-upper" label="Shops">
        <div class="pl-15 pt-25">
          <h4
            class="ml-5-i fs-16-i fw-500-i text-0087 lh-24-i mt-10"
            *ngIf="searchResult && searchResult?.shops"
          >
            {{ searchResult?.shops.length }} results found
          </h4>
          <div
            class="d-flex-row flex-wrap w-100 pstn-relative"
            *ngIf="searchResult && searchResult?.shops.length > 0"
          >
            <div
              class="b-s1-00024 brds-4px ml-5-i mr-5-i flex-bss-24 flex-bss-100-sm flex-bss-32-md p-0-i mb-20 mw-280px pstn-relative bt-0"
              *ngFor="let retailerInfo of searchResult?.shops"
            >
              <div class="w-100">
                <a href="/shops/{{ retailerInfo?.domain }}">
                  <img
                    class="w-100 h-142px brds-tl-tr-4px"
                    src="{{
                      combineBackendApiUrl(retailerInfo?.storeBannerUrl)
                    }}"
                    alt="{{ retailerInfo.domain }}"
                  />
                </a>
              </div>
              <div class="w-100 d-flex-col mb-50">
                <div class="h-64px w-64px pstn-relative m-auto mt-50n">
                  <a href="/shops/{{ retailerInfo?.domain }}">
                    <img
                      src="{{
                        combineBackendApiUrl(retailerInfo?.logoUrl, true)
                      }}"
                      class="h-64px w-64px b-s1-00024 brds-100 obj-ft-contain bg-white"
                    />
                  </a>
                </div>
                <div class="p-20 d-flex-col ta-center">
                  <h1
                    class="fs-14-i fw-500-i lh-24-i text-primary tt-capitalize"
                  >
                    <a
                      class="fs-14-i fw-500-i lh-24-i text-primary tt-capitalize"
                      href="/shops/{{ retailerInfo?.domain }}"
                      >{{ retailerInfo?.storeName }}</a
                    >
                  </h1>
                  <h2
                    class="mt-10 fs-12-i fw-500-i lh-16-i text-black ta-center"
                  >
                    <span
                      *ngFor="
                        let pnItem of retailerInfo.productNatures;
                        let i = index
                      "
                    >
                      {{ getProductNatureNameById(pnItem) }}
                      <span *ngIf="i !== retailerInfo.productNatures.length - 1"
                        >.</span
                      >
                    </span>
                  </h2>
                  <mat-divider></mat-divider>
                  <p
                    class="w-100 d-flex-row fs-12 fw-400 lh-16 text-0006 pt-10"
                  >
                    <mat-icon class="fs-20-i w-20px-i h-20px-i">place</mat-icon>
                    <span class="ml-5 lh-24 d-flex-row flex-wrap">
                      <span *ngIf="retailerInfo?.addressLine1"
                        >{{ retailerInfo?.addressLine1 }}
                      </span>
                      <span *ngIf="retailerInfo?.addressLine2"
                        >{{ retailerInfo?.addressLine1 }}
                      </span>
                      <span *ngIf="retailerInfo?.state"
                        >{{ retailerInfo?.state }}
                      </span>
                      <span *ngIf="retailerInfo?.cityOrTown"
                        >{{ retailerInfo?.cityOrTown }}
                      </span>
                      <span *ngIf="retailerInfo?.zipCode">
                        - {{ retailerInfo?.zipCode }}</span
                      >
                    </span>
                  </p>
                  <div class="w-100 d-flex-col fs-12 fw-400 lh-16 text-primary">
                    <div class="d-flex-row w-100">
                      <mat-icon class="text-0006 fs-20-i w-20px-i h-20px-i"
                        >mail_outline
                      </mat-icon>
                      <span class="ml-5 mr-25 lh-24">{{
                        retailerInfo?.email
                      }}</span>
                    </div>
                    <div class="d-flex-row flex-wrap w-100 pt-10">
                      <mat-icon class="text-0006 fs-20-i w-20px-i h-20px-i"
                        >call</mat-icon
                      >
                      <span class="ml-5 lh-24">{{
                        retailerInfo?.phoneNumber
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="w-100 d-flex-row p-15 ta-center fs-10 fw-500 lh-16 tt-upper bt-s1 pstn-absolute-b0-l0 bc-00012"
                  [class.bg-blk-004]="!retailerInfo.nexLiveDemo"
                  [class.bg-primary-0012]="
                    retailerInfo.nexLiveDemo &&
                    retailerInfo.nexLiveDemo.status != 1
                  "
                  [class.bg-primary]="
                    retailerInfo.nexLiveDemo &&
                    retailerInfo.nexLiveDemo.status == 1
                  "
                >
                  <p
                    class="m-auto-i text-0006"
                    *ngIf="!retailerInfo.nexLiveDemo"
                  >
                    No Live Demo Scheduled
                  </p>
                  <p
                    class="m-auto-i text-0006"
                    *ngIf="
                      retailerInfo.nexLiveDemo &&
                      retailerInfo.nexLiveDemo.status == 1
                    "
                  >
                    <span class="text-ff74">Next Live Demo - </span>
                    <span class="text-white">On Air Now</span>
                  </p>
                  <p
                    class="m-auto-i text-0006"
                    *ngIf="
                      retailerInfo.nexLiveDemo &&
                      retailerInfo.nexLiveDemo.status != 1
                    "
                  >
                    <span class="text-0006">Next Live Demo - </span>
                    <span class="text-0087">{{
                      retailerInfo.nexLiveDemo.startTime
                        | date
                          : "MMM dd,
                                            hh:mm a"
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab class="tt-upper" label="Projects">
        <div class="pl-15 pt-25">
          <h4
            class="ml-5-i fs-16-i fw-500-i text-0087 lh-24-i mt-10"
            *ngIf="searchResult && searchResult?.demos"
          >
            {{ searchResult?.projects.length }} results found
          </h4>
          <div
            class="d-flex-row flex-wrap w-100 pstn-relative"
            *ngIf="searchResult && searchResult?.projects.length > 0"
          >
            <mat-card
              class="ml-5-i mr-5-i flex-bss-24 flex-bss-100-sm flex-bss-32-md p-0-i mb-20 bt-0-i"
              *ngFor="let project of searchResult?.projects"
              (click)="displayProject(project)"
              style="cursor: pointer"
            >
              <div
                class="d-flex pstn-relative m-0-i w-100 lb-card-img-contaier"
              >
                <img
                  class="w-100 h-100 mb-0-i lb-card-img"
                  src="{{ project.thumbnail }}"
                />
              </div>
              <mat-card-content class="mb-50-i p-15-i">
                <span class="fs-10 fw-600 text-0004 ff-roboto">{{
                  project.type === "SHOWCASE_PROJECT" ? "showcase" : "retailer"
                }}</span>
                <h5 class="p3-dark-fwm mt-10-i fs-13-i">
                  <a
                    class="p3-dark-fwm mt-10-i fs-13-i"
                    href="{{ homeRoute }}"
                    >{{ project.name }}</a
                  >
                </h5>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
