<div
  class="d-block w-100 pl-100 pr-100 ff-roboto pl-sm-0 pr-sm-0 hidden-sm header-nav-container"
  fxLayout="row"
  fxLayoutAlign="center"
>
  <a
    mat-button
    href="/store/{{ retailerDomain }}/orders"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'orders'"
    >Orders</a
  >
  <a
    mat-button
    href="/store/{{ retailerDomain }}/items"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'products'"
    >Products</a
  >
  <a
    mat-button
    href="/store/{{ retailerDomain }}/sessions"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'streams'"
    >Streams</a
  >
  <a
    mat-button
    href="/store/{{ retailerDomain }}/subscribers"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'subscribers'"
    >Subscribers</a
  >
  <a
    mat-button
    href="/account/{{ retailerDomain }}/subscriptions"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'subscriptions'"
    >My Subscriptions</a
  >
  <a
    mat-button
    href="/account/"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'account'"
    >Settings</a
  >
</div>
