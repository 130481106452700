<div class="d-flex-row w-100" *ngIf="sessionManager.isConsumerView">
    <h1 *ngIf="!onAir" class="mat-card-title d-flex-row-i text-0087 fs-18 ff-roboto fw-500 lh-24 fs-16-sm lh-28-sm mb-sm-30 mnh-48px">
        <mat-icon>schedule</mat-icon>
        <span class="ml-10 lh-24">
            {{ isComplete? 'Recorded' : 'Scheduled' }} - <span class="fw-400" id="scheduledTimeSpan">{{sessionStartTime | date : 'dd MMM hh:mm
                a'}}</span>
        </span>
    </h1>
    <h1 *ngIf="onAir" class="mat-card-title d-flex-row-i text-0087 fs-18 ff-roboto fw-500 lh-24 fs-16-sm lh-28-sm mb-sm-30 mnh-48px">
        <mat-icon class="text-b00020">circle</mat-icon>
        <span class="ml-10 lh-24 tt-upper">Live </span>
    </h1>
    <div class="ml-auto ta-right">
        <span class="text-0006 fs-12 lh-16 fw-400">Duration: {{sessionDuration}}</span>
    </div>
</div>
<div class="d-flex-row d-flex-col-sm w-100 pstn-relative" *ngIf="sessionManager.isRetailerView">
    <h1 class="mat-card-title w-90 d-flex-row-i text-0087 fs-18 ff-roboto fw-500 lh-24 fs-16-sm lh-28-sm mb-sm-30 mnh-48px">
        <mat-icon>schedule</mat-icon>
        <span class="ml-10 lh-24">
            {{ isComplete? 'Recorded' : 'Scheduled' }} - <span class="fw-400">{{sessionStartTime | date : 'dd MMM hh:mm a'}}</span>
        </span>
    </h1>
    <mat-slide-toggle [labelPosition]="'before'" class="text-0006 fs-14 fw-400 mr-5 crsr-pointer mr-15 pstn-sm-absolute-b0 ml-sm-30"
        [color]="primary" [checked]="conversationEnabled" (change)="onConversationEnabledChanged($event)"
        *ngIf="isExclusive">Conversation</mat-slide-toggle>
    <div class="ml-auto hidden-sm mt-5n" *ngIf="!publisingStream">
        <button mat-raised-button color="primary" class="tt-upper" (click)="startStream()">Start Stream</button>
    </div>
    <div class="ml-auto hidden-sm mt-5n" *ngIf="publisingStream">
        <button mat-raised-button color="primary" class="tt-upper" (click)="stopStream()">Stop Stream</button>
    </div>
    <div class="pstn-sm-absolute-r0">
        <button mat-icon-button [matMenuTriggerFor]="belowMenu" id="streamMenuButton" class="text-0006">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #belowMenu="matMenu" xPosition="before" yPosition="below">
            <button mat-menu-item *ngIf="!publisingStream" class="tt-upper" (click)="startStream()">Start Stream</button>
            <button mat-menu-item *ngIf="publisingStream" class="tt-upper" (click)="stopStream()">Stop Stream</button>
            <button mat-menu-item>Edit Stream Info</button>
            <button mat-menu-item *ngIf="isPending" (click)="cancelStream()">Cancel Stream</button>
            <button mat-menu-item *ngIf="isComplete" (click)="deleteStream()">Delete Stream</button>
        </mat-menu>
    </div>
</div>