import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { IExclusiveSession } from 'src/app/models/exclusive-session.model';
import { ISpecialStream } from 'src/app/models/special-stream.model';
import { ScheduleService } from 'src/app/services/schedule.service';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'excl-live-session',
  templateUrl: '../../views/home/excl-live-session.home.component.html',
  styleUrls: ['../../../assets/css/home/excl-live-session.home.component.css'],
})
export class ExclusiveLiveSessionHomeComponent implements OnInit, OnDestroy {
  exclusiveSession: IExclusiveSession[] = [];

  specialStreamList: ISpecialStream[] = [];
  private subscriptions = new Subscription();

  constructor(
    private scheduleService: ScheduleService,
    private shopService: ShopService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.shopService
        .loadSpecialStream()
        .subscribe((streams: ISpecialStream[]) => {
          this.specialStreamList = streams;
        })
    );

    this.subscriptions.add(
      this.scheduleService
        .loadAllFeaturedExclusiveSession()
        .subscribe((res: IExclusiveSession[]) => {
          this.exclusiveSession = res;
        })
    );
  }

  combineBackendApiUrl(relativePath: string) {
    if (!relativePath) return '/assets/images/default-product-image.png';
    return relativePath;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
