<div class="mw-580px-i mnw-320px">
    <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">Setup live stream</h1>
    <div class="mat-dialog-content">
        <form name="createStreamForm" [formGroup]="createStreamForm" (ngSubmit)="onSubmit()" method="post" enctype="multipart/form-data">
            <div class="pb-10 pt-10">
                <div class="pb-10 pt-10">
                    <div class="form-item">
                        <label class="p3-dark-fwm mb-15 d-block text-black">Stream Type</label>
                        <mat-radio-group aria-label="Select an option" color="primary" [formControl]="streamType">
                            <mat-radio-button value="showcase" class="mr-10">Showcase Product</mat-radio-button>
                            <mat-radio-button value="exclusive">Exclusive Session</mat-radio-button>
                        </mat-radio-group>
                        <div class="mat-form-field-subscript-wrapper pstn-relative-i" [class.hidden]="!streamType.invalid">
                            <p class="mat-error m-0-i">{{getErrorMessage('streamType')}}</p>
                        </div>
                    </div>
                    <div class="form-item mt-15">
                        <div class="row d-block">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Stream Title</mat-label>
                                    <input matInput placeholder="Stream title" [formControl]="streamTitle" required>
                                    <mat-error *ngIf="streamTitle.invalid">{{getErrorMessage('streamTitle')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row d-flex fade-out" [class.fade-in]="streamType.value=='exclusive'">
                            <div class="col-6">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Subscription Price</mat-label>
                                    <input type="number" matInput placeholder="Subscription price" [formControl]="subscriptionPrice" id="subscriptionPriceInptu">
                                    <mat-error *ngIf="subscriptionPrice.invalid">{{getErrorMessage('subscriptionPrice')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-hint class="d-block w-100 mt-10 pl-10-i fs-10 fw-400">Access to the session will be limited to 48 hours if purchased before the end of live session and 24 hours if purchased after live session or for any number of subsequent purchases</mat-hint>
                            </div>
                        </div>
                        <div class="row d-block">
                            <div class="col-6 pr-5">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Schedule Date</mat-label>
                                    <input matInput [matDatepicker]="picker" [formControl]="scheduleDate" required>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error *ngIf="scheduleDate.invalid">{{getErrorMessage('scheduleDate')}}
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div class="col-6 pl-5">
                                <mat-form-field appearance="outline" class="d-block-i">
                                    <mat-label>Start Time</mat-label>
                                    <input type="time" matInput placeholder="Start time" [formControl]="startTime" required>
                                    <mat-error *ngIf="startTime.invalid">{{getErrorMessage('startTime')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row d-flex">
                            <div class="col-12">
                                <label class="p3-dark-fwm mb-15 d-block text-black">Stream Duration</label>
                                <mat-chip-list #durationList [formControl]="streamDuration">
                                    <mat-chip *ngFor="let duration of streamDurationList" [selected]="duration" [value]="duration" (click)="updateSelectedDuration(duration)">
                                        {{duration}}
                                    </mat-chip>
                                </mat-chip-list>
                                <mat-form-field appearance="outline" class="d-block-i mt-15" [class.hidden]="streamDurationNotOther">
                                    <mat-label>Stream Duration</mat-label>
                                    <input matInput type="number" min="0" placeholder="Stream Duration" [formControl]="streamDurationOther" required class="">
                                    <mat-error *ngIf="streamDurationOther.invalid">
                                        {{getErrorMessage('streamDurationOther')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row d-flex mt-25">
                            <div class="col-12">
                                <div class="form-item">
                                    <label class="p3-dark-fwm mb-15 d-block text-black">Stream Thumbnail</label>
                                    <div class="p-15 bg-blk-004 pstn-relative">
                                        <input id="streamThumbnail" class="mat-stroke-button-file-input" type="file" (change)="onStreamThumbnailChange($event)" required />
                                        <div class="mat-form-field-subscript-wrapper pstn-relative-i" [class.hidden]="!streamThumbnailIsInvalid">
                                            <p class="mat-error m-0-i">Stream thumbnail is required</p>
                                        </div>
                                        <p class="mat-error m-0-i mt-10-i fs-12 lh-24" *ngIf="thumbnailImageFileError">{{thumbnailImageFileError}}</p>
                                    </div>
                                    <mat-hint class="d-block w-100 mt-10 pl-10-i">Please upload a thumbnail for the stream with minimum 640 x 360px with 16:9 aspect ratio</mat-hint>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex mt-25 fade-out" [class.fade-in]="streamType.value=='exclusive'">
                            <div class="col-12">
                                <div class="form-item">
                                    <label class="p3-dark-fwm mb-15 d-block text-black">Stream Details</label>
                                    <ckeditor [editor]="Editor" tagName="textarea" (change)="onEditorChange($event)"></ckeditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mat-dialog-actions pstn-relative m-0-24-neg p-0-24-i bt-s1-i bc-00012-i mt-15">
                    <mat-spinner diameter="25" class="color-primary pstn-absolute t24 l4" [class.hidden]="!inProgress">
                    </mat-spinner>
                    <button mat-button class="tt-upper text-primary ml-auto-i" (click)="onCancelClick()">
                        <span>Cancel</span>
                    </button>
                    <button mat-raised-button color="primary" type="submit" class="tt-upper ml-0-i">
                        <span>Create Stream</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>