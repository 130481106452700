import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app/app.config';
import { ISchedule } from 'src/app/models/schedule.model';
import { ScheduleService } from 'src/app/services/schedule.service';
import { Subscription } from 'rxjs';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';
import { DatePipe } from '@angular/common';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IItem } from 'src/app/models/item.model';
import { ItemService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { AddShowcaseProductDialogComponent } from '../store/add-showcase-product.dialog.component';

@Component({
    selector: 'rv-session',
    templateUrl: '../../views/sessions/retailer-view-session.component.html'
})
export class RetailerViewSessionComponent implements OnInit, OnDestroy, AfterViewInit {

    streamingIsInProgress: boolean = false;
    retailerDomain: string;
    sessionId: string;
    stream: ISchedule;

    removeInProgress: boolean = false;
    removeId: string = '';

    isExclusive: boolean = false;
    hasSubscription: boolean = false;
    isSubscriptionRequired: boolean = true;
    subscriptionInfoLoaded: boolean = false;
    sessionDetail: string;

    allProductItems: IItem[] = [];
    showcaseProductItems: IItem[] = [];

    subscriptions = new Subscription();

    constructor(
        private datePipe: DatePipe,
        private itemService: ItemService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private retailerStoreService: RetailerStoreService,
        private sessionManager: SessionComponentService) { }

    ngOnInit(): void {

        this.retailerDomain = this.activatedRoute.parent.snapshot.paramMap.get('domain');
        this.sessionId = this.activatedRoute.snapshot.paramMap.get('sessionId');

        this.loadStreamInfo();
    }

    ngAfterViewInit() {
        this.subscriptions.add(this.sessionManager.isExclusive.subscribe((exclusive: boolean) => { this.isExclusive = exclusive;}));
    }

    loadStreamInfo(): void {
        const userId = this.authenticationService.currentUserId;
        this.retailerStoreService
            .scheduleByUserIdScheduleId(userId, this.sessionId)
            .toPromise()
            .then((res: ISchedule) => {

                // handle schedule not found response
                if (!res) {
                    this.router.navigate(['/']);
                    return
                }

                this.stream = res;
                this.sessionManager.sessionCreatorId.emit(res.retailerId);
                this.sessionManager.status.emit(res.status);
                this.sessionManager.session.emit(res);
                this.sessionManager.sessionTitle.emit(res.title);
                this.sessionManager.sessionStartTime.emit(res.startTime);
                this.sessionManager.isExclusive.emit(res.type.toLowerCase() == 'exclusive');
                this.sessionManager.sessionStartTimeLiteral.emit(this.getSessionStartTimeLiteral(res.status, res.startTime, res.endTime));
                this.sessionManager.conversationEnabled.emit(res.isExclusiveSessionChatEnabled);
                this.sessionManager.sessionId.emit(res.id);

                this.loadAllProductItems();
                this.loadShowcasedProductItems();
            })
            .catch((err: any) => {
                this.router.navigate(['/']);
                return
            })
    }

    loadAllProductItems(): void {
        const userId = this.authenticationService.currentUserId;
        this.subscriptions.add(
            this.itemService.allItemsByUserId(userId)
                .subscribe((items: any) => {
                    this.allProductItems = items.items;
                })
        );
    }
    loadShowcasedProductItems(): void {
        const userId = this.authenticationService.currentUserId;
        this.subscriptions.add(
            this.retailerStoreService.showcaseProductsByScheule(userId, this.stream.id)
                .subscribe((items: any) => {
                    this.showcaseProductItems = items.filter(function (item) { return item.price_money != null; });
                })
        );
    }
    
    removeItem(id: string) {
        this.removeId = id;
        this.addSelectionToStream(id)
    }

    addSelectionToStream(id: string): void {
        this.removeInProgress = true;
        let productIds = this.showcaseProductItems.map((item) => {
            if( item.id !== id ){
                return item.id
            }
        });

        productIds = productIds.filter( (item) => {
            return item != undefined
        })

        const userId = this.authenticationService.currentUserId;
        this.retailerStoreService.updateSheduleShowcaseProduct(userId, this.stream.id, productIds)
            .toPromise()
            .then((res: any) => {
                this.loadShowcasedProductItems()
                this.removeInProgress = false;
                this.snackBar.open('Product has been removed!', 'close', { duration: 10000, panelClass: ['mat-success-bg'] });
            })
            .catch((err: HttpErrorResponse) => {
                this.removeInProgress = false;
                const errorDetail = err.error.error || err.error || err.statusText;
                this.snackBar.open(errorDetail, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
            });
    }

    openAddShowcaseProductDialog(): void {
        let productList = this.allProductItems.map( (list) => {
            let isShowcase = this.showcaseProductItems.find( (item) => {
                return item.id === list.id
            })
            return { ...list,'is_showcase':typeof isShowcase === "undefined" ? false : true  };
        })
        const scheduleId = this.stream.id;
        const dialogRef = this.dialog.open(
            AddShowcaseProductDialogComponent,
            {
                data: {
                    data: productList,
                    scheduleId: scheduleId
                },
                width: '80%'
            });

        dialogRef.afterClosed().subscribe(result => {
            this.loadShowcasedProductItems();
        });
    }

    getSessionStartTimeLiteral(status: any, startTime: any, endTime: any): string {

        if (status == "2") {
            return `Started at - ${this.datePipe.transform(new Date(startTime), 'MMM dd, yyyy')}`;
        }

        const timeStart: any = new Date(startTime);
        const now: any = new Date();

        if (now > timeStart) {
            return `Started at - ${this.datePipe.transform(new Date(startTime), 'MMM dd, yyyy hh:mm a')}`;
        }


        var diffMs = timeStart - now; // milliseconds between now & starttime
        var duration = Math.floor(diffMs / 60000); // in minutes
        if (duration > (60 * 24)) {
            duration = Math.round(duration / (60 * 24));
            const streamDuration = parseFloat(duration.toString()).toFixed(0);
            return `Starts in - ${streamDuration}d`;
        }
        else if (duration > 59) {
            duration = Math.round(duration / (60));
            const streamDuration = parseFloat(duration.toString()).toFixed(0);
            return `Starts in - ${streamDuration}h`;
        }

        const streamDuration = parseFloat(duration.toString()).toFixed(0);
        return `Starts in - ${streamDuration}m`;
    }

    ngOnDestroy(): void { }
}