<div
  class="page-wrapper w-100 h-100 d-flex pstn-absolute"
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <div class="page-banner w-40 h-100 bg-primary d-inline-block hidden-sm">
    <div class="d-block w-100">
      <a mat-icon-button class="m-10-i d-block" href="{{ homeRoute }}">
        <mat-icon class="text-white">arrow_back</mat-icon>
        <span class="h1-white ml-10">Back to Marketplace</span>
      </a>
    </div>
    <div class="w-100 ta-center">
      <img src="./assets/images/shopping_bg.png" class="w-75 m-auto mt-60" />
    </div>
    <div class="pstn-absolute-b0-l0 ml-35 mb-30">
      <h1 class="h1-white mb-25">Contact</h1>
      <span class="d-block h3-dwhite">+1 425 280 3473</span>
      <span class="d-block h3-dwhite">info@macurian.shop</span>
      <span class="d-block h3-dwhite mt-30">Follow us on</span>
      <div class="d-block mt-10">
        <img
          class="facebook_icon mr-10"
          height="25px"
          src="./assets/images/facebook_icon.png"
        />
        <img
          class="instagram_icon mr-10"
          height="22px"
          src="./assets/images/instagram_icon.png"
        />
        <img
          class="twitter_icon mr-10"
          height="21px"
          src="./assets/images/twitter_icon.png"
        />
        <img
          class="youtube_icon mr-10"
          height="20px"
          src="./assets/images/youtube_icon.png"
        />
        <img
          class="pinterist_icon"
          height="25px"
          src="./assets/images/pinterist_icon.png"
        />
      </div>
    </div>
  </div>
  <div
    class="page-form w-60 w-100-sm h-100 bg-white d-inline-block pstn-relative"
  >
    <div class="form-wrapper">
      <div
        class="dialog-form-overlap pstn.abolute-100h-100h p-140 pt-50 pb-0-i p-sm-30"
      >
        <div class="form-content form-light mw-400px">
          <a href="{{ homeRoute }}">
            <img src="../../assets/images/marketplace-logo.png" />
          </a>
          <div class="row d-block" [class.hidden]="verificationComplete">
            <div class="col-12 ml-5 mr-5 p-100-i">
              <div class="m-auto-i d-flex jc-center">
                <mat-spinner diameter="20"></mat-spinner>
                <span class="ml-20-i lh-22 fw-500 text-primary"
                  >Verifying your account..</span
                >
              </div>
            </div>
          </div>
          <form
            class="mw-400px"
            name="registerForm"
            [class.hidden]="!verificationComplete"
          >
            <h1 class="h1-dark mt-20-i">Account activated successfully</h1>
            <div class="row d-block p-b-20">
              <div class="col-12 ml-5 mr-5">
                <div class="md-form mb-40">
                  <p class="p3-dark fs-16-i lh-24">
                    Thank you, your email has been verified and your account is
                    now active. Please use the link below to login to your
                    account.
                  </p>
                </div>
              </div>
            </div>
            <div class="row d-block p-b-20">
              <div class="col-12 ml-5 mr-5">
                <div class="md-form ta-left">
                  <a
                    mat-raised-button
                    color="primary"
                    id="button-form-submit"
                    (click)="openLogin()"
                    class="pstn-relative w-95-i tt-upper pt-5-i pb-10-i"
                  >
                    LOG IN TO YOUR ACCOUNT
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          class="pstn-absolute-b0-l0 ml-35 mw-400px m-auto-sm mb-30 hidden d-block-sm pstn-sm-relative"
        >
          <h1 class="h1-white mb-25 clr-sm-dark">Contact</h1>
          <span class="d-block h3-dwhite clr-sm-ddark">+1 425 280 3473</span>
          <span class="d-block h3-dwhite clr-sm-ddark">info@macurian.shop</span>
          <span class="d-block h3-dwhite mt-30 mt-sm-15 clr-sm-ddark"
            >Follow us on</span
          >
          <div class="d-block mt-10 clr-sm-primary mb-sm-15">
            <img
              class="facebook_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="25px"
              src="./assets/images/facebook_icon.png"
            />
            <img
              class="instagram_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="22px"
              src="./assets/images/instagram_icon.png"
            />
            <img
              class="twitter_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="21px"
              src="./assets/images/twitter_icon.png"
            />
            <img
              class="youtube_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="20px"
              src="./assets/images/youtube_icon.png"
            />
            <img
              class="pinterist_icon bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="25px"
              src="./assets/images/pinterist_icon.png"
            />
          </div>
        </div>
        <div class="signup-footer verifiy pstn-sm-relative ta-sm-center">
          <p class="h4-dark">
            Copyright &copy; 2021 Macurian Inc. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
