import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { AppConfig } from '../app.config';

@Injectable({
    providedIn: 'root'
})
export class JWTTokenService {

    get currentUserObj() { return AppConfig.currentUserObjectFromLocalStorage; }
    get jwtToken(): string { return this.currentUserObj ? this.currentUserObj.jwtToken : undefined; }
    get refreshTokenValue(): string { return this.currentUserObj ? this.currentUserObj.refreshToken : undefined; }
    get refreshTokenExpires(): number { return this.currentUserObj ? new Date(this.currentUserObj.refreshTokenExpires).getTime() : undefined; }

    get decodedToken(): any { return this.jwtToken ? jwt_decode(this.jwtToken) : undefined; }

    constructor() { }

    getUser() {
        return this.decodedToken ? this.decodedToken.username : null;
    }

    getEmailId() {
        return this.decodedToken ? this.decodedToken.email : null;
    }

    getExpiryTime() {
        return this.decodedToken ? this.decodedToken.exp : null;
    }

    isRefreshTokenExpired(): boolean {

        if (this.refreshTokenExpires == undefined
            || this.refreshTokenExpires == null) {
            return true;
        }

        return (this.refreshTokenExpires - (new Date()).getTime()) < (1000 * 60 * 60);
    }

    isTokenExpired(): boolean {
        const expiryTime: number = this.getExpiryTime();
        if (expiryTime == undefined
            || expiryTime == null) {
            return true;
        }

        return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    }

    get authorizationToken(): string {
        if (!this.isTokenExpired()) {
            return this.jwtToken;
        }
        return undefined;
    }

    get refreshToken(): string {
        if (this.refreshTokenValue) {
            return this.refreshTokenValue;
        }
        return undefined;
    }
}