import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IItemCategory } from 'src/app/models';
import { ItemService } from 'src/app/services';

@Component({
  selector: 'header-nav',
  templateUrl: '../../views/header/header-nav.component.html',
  styleUrls: ['../../../assets/css/header/header-nav.component.css']
})
export class HeaderNavComponent implements OnInit, OnDestroy {

  categories: IItemCategory[] = [];

  subscription: Subscription = new Subscription();

  constructor(private itemService: ItemService) { }

  ngOnInit(): void {
    this.subscription.add(this.itemService.categories().subscribe((res: any) => { this.categories = res }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
