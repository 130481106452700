<div class="d-flex-col w-100 p-20 pl-sm-20 pr-sm-20 p-20-sm">
    <app-ssn-brdcrmb></app-ssn-brdcrmb>
    <div class="d-flex-row flex-1 d-block-sm pstn-relative">
        <session-card class="w-70 mr-10 w-100-sm"></session-card>
        <session-coversation id="streamChattingContainer" class="w-30 ml-10 d-flex-row w-100-sm d-block-sm ml-0-sm"></session-coversation>
    </div>
</div>
<div class="w-100 p-20 pl-sm-20 pr-sm-20 p-20-sm pt-10 mat-elevation-z1" *ngIf="!isExclusive">
    <div class="d-flex">
        <div class="w-100">
            <mat-card class="p-0-i" id="showcaseCard">
                <mat-card-header id="showcaseCardHeader" class="w-100 p-20 pt-15 pb-5 mat-elevation-z1">
                    <mat-card-title class="text-0087 fs-18 ff-roboto fw-500 lh-24 fs-16-sm lh-20-sm">Products Showcased
                    </mat-card-title>
                    <div class="ml-auto">
                        <button mat-button class="tt-upper text-primary fs-14 fw-500 ff-roboto" id="addProductButton"
                            (click)="openAddShowcaseProductDialog()">Add Product</button>
                    </div>
                </mat-card-header>
                <mat-card-content id="showcaseContainer" class="w-100">
                    <div *ngIf="showcaseProductItems.length == 0" id="emptyShowCaseContent"
                        class="d-flex-col w-100 jc-ai-center pb-100 pt-100">
                        <div class="w-h-48px">
                            <mat-icon class="fs-48-i text-0012">inventory_2</mat-icon>
                        </div>
                        <p class="ff-roboto fs-12 fw-400 lh-16 text-0006 pt-25 ta-center">You need to add one or more
                            products to
                            showcase before you can start the live stream<br />
                            Users will be able to see and buy these products</p>
                        <button mat-raised-button (click)="openAddShowcaseProductDialog()" color="primary"
                            class="tt-upper fs-14 fw-500 ff-roboto mt-20">Add
                            Product</button>
                    </div>
                    <div *ngIf="showcaseProductItems.length > 0" class="d-flex-row flex-wrap w-100 p-15">
                        <mat-card *ngFor="let pitem of showcaseProductItems"
                            class="m-5 flex-bss-24 flex-bss-100-sm flex-bss-32-md p-0-i">
                            <mat-card-header class="d-flex-col w-100 jc-fx-end p-10">
                                <!-- <mat-icon class="mr-15">edit</mat-icon> -->
                                <img *ngIf="removeInProgress && removeId === pitem.id" class="m-5" src="../../../assets/images/ajax-loader.gif" />
                                <mat-icon class="crsr-pointer" (click) ="removeItem(pitem.id)">clear</mat-icon>
                            </mat-card-header>
                            <img mat-card-image [src]="pitem.image_id | asyncImgSrc | async"
                                class="mh-224px obj-ft-contain m-0-i w-100-i">
                            <mat-card-content class="ff-roboto p-20-i">
                                <p class="text-0087 fs-14 fw-400 lh-18 mb-25-i">{{pitem.name}}</p>
                                <p class="fs-21 fw-500 lh-32 mb-10 text-black">${{pitem.price_money?.amount / 100}}</p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>