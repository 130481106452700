import { Pipe, PipeTransform } from '@angular/core';
import { AppConfig } from '../app.config';

export interface ApiImagePathPipeParams {
  dpi: boolean;
  nl: boolean;
  dsb: boolean;
  nt:boolean;
}

@Pipe({
  name: 'apiImgPath'
})
export class ApiImagePathPipe implements PipeTransform {

  public transform(relativePath: string, params: ApiImagePathPipeParams) {
    
    if (relativePath && relativePath.indexOf('undefined') == -1) return `${AppConfig.settings.apiServer.basepath}${relativePath}`;
    
    if (params) {
      
      if (params.dpi) return '/assets/images/default-product-image.png';
      if (params.nl) return '/assets/images/nologo.jpg';
      if (params.dsb) return '/assets/images/store_shop_banner.png';
      if (params.nt) return '/assets/images/thumbnail-default.jpg';
    }
  }
}