import { Injectable } from '@angular/core';
import { StorageKeyEnum } from '../enums/storage.key.enum';

import  * as AES  from 'crypto-js/aes';
import * as cryptoEncUtf8 from 'crypto-js/enc-utf8';
import { AppConfig } from '../app.config';

@Injectable({
    providedIn: 'root'
})
export class RememberMeService {

    constructor() { }

    get userName(): string {
        
        const usernamecache = localStorage.getItem(StorageKeyEnum.rememberMeUser);
        if (!usernamecache) return usernamecache;

        const decryptedUserName = AES.decrypt(usernamecache, AppConfig.secretPassphrase).toString(cryptoEncUtf8);
        return decryptedUserName;
    }

    set userName(userName: string){
        const encryptedUserName = AES.encrypt(userName, AppConfig.secretPassphrase).toString();
        localStorage.setItem(StorageKeyEnum.rememberMeUser, encryptedUserName);
    }
}