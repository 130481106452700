import { Component, Inject, OnInit } from '@angular/core';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { IItem } from 'src/app/models/item.model';
import { OrderService } from 'src/app/services/order.service';
import { IOrderListItem } from 'src/app/models/order-list-item';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { RoleEnum } from 'src/app/enums/role.enum';

@Component({
    selector: 'consumer-orders',
    templateUrl: '../../views/shops/consumer-orders.component.html',
    styleUrls: ['../../../assets/css/store/retailer-streams.store.component.css']
})
export class ConsumerOrdersComponent implements OnInit {

    domain: string;
    searchKeyword: string;
    filterStatus: any;

    orderListItems: any[] = [];
    orderListItemsOriginal: any[] = [];
    tablePageSize: number = 10;
    tablePageIndex: number = 0;
    tableDatasource: any[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private itemService: ItemService,
        private authService: AuthenticationService,
        private orderService: OrderService,
        private router: Router,
        private datePipe: DatePipe,) { }

    ngOnInit() {

        if (this.authService.currentUserRole != RoleEnum.consumer) {
            this.router.navigate(['/login']);
        }

        this.domain = this.activatedRoute.parent.snapshot.paramMap.get('storename');
        const userId = this.authService.currentUserId;

        this.orderService.getOrderListItemByUserId(userId)
            .toPromise()
            .then((listItem: IOrderListItem[]) => {
                if (listItem && listItem.length > 0) {
                    this.orderListItemsOriginal = listItem.sort((a, b) => { return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime(); });
                    this.orderListItems = this.orderListItemsOriginal;
                    const items = [...this.orderListItems];
                    this.tableDatasource = items.splice(this.tablePageIndex * this.tablePageSize, this.tablePageSize);
                }
            });
    }

    filterOrderListItems(): void {
        var self = this;
        var filterList = self.orderListItemsOriginal.filter(o => {
            if (!self.searchKeyword) return true;
            return Object.keys(o).some(k => {
                return o[k] && o[k].toString().toLowerCase().includes(self.searchKeyword.toLowerCase())
            });
        });

        var filterList = filterList.filter(o => {
            if (self.filterStatus == -1 || !self.filterStatus) return true;
            return o.status == self.filterStatus;
        });

        const items = [...filterList];
        self.tableDatasource = items.splice(self.tablePageIndex * self.tablePageSize, self.tablePageSize);
    }

    pageChanged(event: PageEvent) {
        this.tablePageIndex = event.pageIndex;
        this.tablePageSize = event.pageSize;

        const items = [...this.orderListItems];
        this.tableDatasource = items.splice(this.tablePageIndex * this.tablePageSize, this.tablePageSize);
    }

}