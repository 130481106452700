<div class="d-flex" *ngIf="showcaseProductItems.length > 0">
    <div class="w-100">
        <mat-card class="" id="showcaseCard">
            <mat-card-header id="showcaseCardHeader" class="w-100 p-20 pt-15 pb-5 mat-elevation-z1">
                <mat-card-title class="text-0087 fs-18 ff-roboto fw-500 lh-24 fs-16-sm lh-20-sm">Products Showcased
                </mat-card-title>
            </mat-card-header>
            <mat-card-content id="showcaseContainer" class="w-100">
                <div class="d-flex-row flex-wrap w-100 p-15">
                    <mat-card *ngFor="let pitem of showcaseProductItems"
                        class="m-5 flex-bss-24 flex-bss-46-sm flex-bss-31-md p-0-i">
                        <img mat-card-image [src]="pitem.image_id | asyncImgSrc | async"
                            class="mh-224px obj-ft-contain m-0-i w-100-i">
                        <mat-card-content class="ff-roboto p-20-i">
                            <p class="text-0087 fs-14 fw-400 lh-18 mb-25-i">{{pitem.name}}</p>
                            <p class="fs-21 fw-500 lh-32 mb-10 text-black">${{pitem?.price_money?.amount / 100}}</p>
                            <button mat-raised-button color="primary" (click)="openProductDetailDialog(pitem)">Buy
                                Now</button>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>