import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import _ from 'lodash';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductTypeEnum } from 'src/app/enums/product-type-enum';

type PRODUCT_TYPE = 'EXTERNAL_PRODUCT_TYPE' | 'SQUAREUP_PRODUCT_TYPE';

@Component({
  selector: 'app-add-item-dialog',
  templateUrl: '../../views/item/add-item.component.html',
})
export class AddItemDialogComponent implements OnInit {
  itemForm: FormGroup;
  inProgress: boolean = false;
  categories: Array<{ id: string; category: string }> = [];
  itemImage: File;
  existingTaxData;
  disableTaxInfo: Boolean = true;
  product;
  formTitle: string;

  productType: FormControl;
  name: FormControl;
  price: FormControl;
  categoryId: FormControl;
  description: FormControl;
  enableTax: FormControl;
  externalProductLink: FormControl;

  externalProductType = 'EXTERNAL_PRODUCT_TYPE';
  squareUpProductType = 'SQUAREUP_PRODUCT_TYPE';

  productFormType: PRODUCT_TYPE = 'EXTERNAL_PRODUCT_TYPE';

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddItemDialogComponent>,
    public itemService: ItemService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.product = data;
  }

  ngOnInit(): void {
    const initialProductType =
      _.get(this.product, 'type') ?? this.externalProductType;
    this.productFormType = initialProductType;
    const initialName =
      _.get(this.product, 'item_data.name') ??
      _.get(this.product, 'name') ??
      '';
    const previousPriceInCents =
      _.get(
        _.first(_.get(this.product, 'item_data.variations')),
        'item_variation_data.price_money.amount'
      ) ??
      _.get(this.product, 'price') ??
      0;
    const previousPriceInDollars = parseFloat(previousPriceInCents) / 100;

    const initialPrice = previousPriceInDollars.toString() ?? '';
    const initialCategoryId =
      _.get(this.product, 'category.object.id') ??
      _.get(this.product, 'categoryId') ??
      '';
    const initialDescription =
      _.get(this.product, 'item_data.description') ??
      _.get(this.product, 'description') ??
      '';
    const initialEnableTax =
      !!_.size(_.get(this.product, 'item_data.tax_ids')) || true;
    const initialExternalProductLink =
      _.get(this.product, 'externalProductLink') || '';

    this.productType = new FormControl(initialProductType, [
      Validators.required,
    ]);
    this.name = new FormControl(initialName, [Validators.required]);
    this.price = new FormControl(initialPrice, [Validators.required]);
    this.categoryId = new FormControl(initialCategoryId, [Validators.required]);
    this.description = new FormControl(initialDescription, [
      Validators.required,
    ]);
    this.enableTax = new FormControl(initialEnableTax, []);
    this.externalProductLink = new FormControl(initialExternalProductLink, []);

    this.formTitle =
      !!this.product && !!this.product.id ? 'Edit Product' : 'Add Product';

    this.itemForm = this.formBuilder.group({
      productType: this.productType,
      name: this.name,
      price: this.price,
      description: this.description,
      categoryId: this.categoryId,
      itemImage: this.itemImage,
      enableTax: this.enableTax,
      externalProductLink: this.externalProductLink,
    });

    this.itemService.categories().subscribe((categories) => {
      this.categories = categories;
    });

    this.itemService.getTaxData().subscribe((taxData) => {
      this.existingTaxData = taxData;
      this.disableTaxInfo = !taxData;
    });

    this.onFormDataChanges();
  }

  onFormDataChanges() {
    this.itemForm.get('productType').valueChanges.subscribe((productType) => {
      if (productType === this.externalProductType) {
        this.productFormType = ProductTypeEnum.EXTERNAL_PRODUCT_TYPE;
        this.itemForm.controls['externalProductLink'].setValidators([
          Validators.required,
        ]);
        this.itemForm.controls['enableTax'].clearValidators();
      } else {
        this.productFormType = ProductTypeEnum.SQUAREUP_PRODUCT_TYPE;
        this.itemForm.controls['externalProductLink'].clearValidators();
        this.itemForm.controls['enableTax'].setValidators([
          Validators.required,
        ]);
      }
      this.itemForm.controls['enableTax'].updateValueAndValidity();
      this.itemForm.controls['externalProductLink'].updateValueAndValidity();
    });
  }

  onItemImageChange($event) {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      this.itemImage = file;
    }
  }

  onSubmit() {
    if (this.itemForm.invalid) {
      return;
    }

    this.inProgress = true;

    const formData = new FormData();
    const priceInCents = parseFloat(this.price.value) * 100;
    formData.append('productType', this.productType.value);
    formData.append('name', this.name.value);
    formData.append('price', priceInCents.toString());
    formData.append('description', this.description.value);
    formData.append('categoryId', this.categoryId.value);
    formData.append('itemImage', this.itemImage);
    formData.append('enableTax', this.enableTax.value);
    formData.append('externalProductLink', this.externalProductLink.value);
    formData.append('id', this.product.id ?? this.product._id ?? '');
    this.itemService
      .createItem(formData)
      .toPromise()
      .then((res: any) => {
        this.inProgress = false;
        this.snackBar.open('Item information updated', 'Close', {
          duration: 10000,
          panelClass: ['mat-success-bg'],
        });
        this.dialogRef.close();
      })
      .catch((err: HttpErrorResponse) => {
        this.inProgress = false;
        const errorDetail = err.error.error || err.error;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
