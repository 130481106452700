<section
  id="searchPageContent"
  class="w-100 pl-80 pr-80 p-20 p-sm-20 pl-sm-20 pr-sm-20 ff-roboto"
>
  <div class="mb-15 d-flex">
    <h1 class="text-black fs-24-i fw-500-i lh-24-i m-0-i">
      Sign up for a Session
    </h1>
  </div>
  <div class="w-100 b-s1-00024 brds-4px pstn-relative">
    <mat-spinner
      diameter="20"
      class="color-primary pstn-absolute-t0-r0-i mr-20 mt-10"
      [class.hidden]="!loadingResult"
    ></mat-spinner>
    <mat-tab-group mat-align-tabs="start">
      <mat-tab class="tt-upper" label="Exclusive Sessions">
        <div class="pl-15 pt-25">
          <div class="d-flex-row flex-wrap w-100 pstn-relative">
            <mat-card
              class="ml-5-i mr-5-i flex-bss-24 flex-bss-100-sm flex-bss-32-md p-0-i mb-20 bt-0-i"
              *ngFor="let item of sessions"
            >
              <a
                class="d-flex pstn-relative m-0-i w-100 lb-card-img-contaier"
                href="/shops/{{ item.user.domain }}/sessions/exclusive/{{
                  item._id
                }}"
              >
                <img
                  class="w-100 h-100 mb-0-i lb-card-img"
                  [src]="item.streamThumbnailUrl"
                  (error)="
                    onImageError($event, '/assets/images/thumbnail-default.jpg')
                  "
                />
              </a>
              <mat-card-content class="mb-50-i p-15-i">
                <span
                  *ngIf="hasInProgressStatus(item.status)"
                  class="bg-primary fs-10 fw-500 brds-50px text-white ff-roboto p-2px-8px"
                  >{{ parseStreamStatus(item.status, item.startTime) }}</span
                >
                <span
                  *ngIf="!hasInProgressStatus(item.status)"
                  class="fs-10 fw-600 text-0004 ff-roboto"
                  >{{ parseStreamStatus(item.status, item.startTime) }}</span
                >
                <h5 class="p3-dark-fwm mt-10-i fs-13-i">
                  <a
                    class="p3-dark-fwm mt-10-i fs-13-i"
                    href="/shops/{{ item.user.domain }}/sessions/exclusive/{{
                      item._id
                    }}"
                    >{{ item.title }}</a
                  >
                </h5>
              </mat-card-content>
              <mat-card-footer
                class="p-15-i d-flex-row-i ml-0-i pstn-absolute-b16"
              >
                <a class="d-flex-row" href="/shops/{{ item.user.domain }}">
                  <img
                    *ngIf="item.user.logoUrl"
                    [src]="item.user.logoUrl | apiImgPath : { nl: true }"
                    (error)="onImageError($event, '/assets/images/nologo.jpg')"
                    class="w-32px brds-100 b-s1 bc-00012"
                  />
                  <mat-icon
                    *ngIf="!item.user.logoUrl"
                    class="w-32px brds-100 b-s1 bc-00012"
                  >
                    storefront</mat-icon
                  >
                  <span
                    *ngIf="item.user.storeName"
                    class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
                    >{{ item.user.storeName }}</span
                  >
                  <span
                    *ngIf="!item.user.storeName"
                    class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
                    >Store</span
                  >
                </a>
              </mat-card-footer>
            </mat-card>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
