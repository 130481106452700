import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HOME_ROUTE } from 'src/app/app.config';
import { AccountService, AuthenticationService } from 'src/app/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: '../../views/login/forgot-password.component.html',
  styleUrls: ['../../../assets/css/login/login.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  loginForm: FormGroup;
  resetAccountPasswordForm: FormGroup;
  inProgress: Boolean = false;
  resetLinkSent: boolean = false;
  homeRoute: string;

  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private accountService: AccountService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.homeRoute = HOME_ROUTE;
  }

  ngOnInit(): void {
    if (this.authService.currentUserId) {
      this.router.navigate([HOME_ROUTE]);
    }
    this.loginForm = this.formBuilder.group({
      email: this.email,
    });
  }

  getErrorMessage(fieldKey: string) {
    switch (fieldKey) {
      case 'email':
        if (this.email.hasError('required')) {
          return 'Email is required';
        }
        return this.email.hasError('email') ? 'Not a valid email' : '';
        break;
    }
  }

  onSubmit() {
    this.inProgress = true;
    if (this.loginForm.invalid) {
      this.inProgress = false;
      return;
    }

    this.accountService
      .sendPasswordResetLink(this.email.value)
      .toPromise<any>()
      .then((res) => {
        // handle success response
        this.inProgress = false;
        this.resetLinkSent = true;
      })
      .catch((err: HttpErrorResponse) => {
        // handle error response
        const errorDetail = err.error.error || err.error;
        this.inProgress = false;
        this.snackBar.open(errorDetail.error || errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }

  onResetPasswordSubmit() {}
}
