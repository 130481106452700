<div class="stream-list-page-wrapper">
    <div class="bg-f5f5f5 w-100 p-30 pl-sm-20 pr-sm-20 p-20-sm">
        <div class="d-flex-row w-100">

            <h1 class="h1-dark fs-22" *ngIf="requestForSubscriptionsPage">Subscriptions</h1>
            <h1 class="h1-dark fs-22" *ngIf="requestForSubscribersPage">Subscribers</h1>

            <mat-spinner diameter="20" class="color-primary pstn-absolute r10" [class.hidden]="!loading"></mat-spinner>
        </div>
        <div class="d-inline-block d-flex-col-sm w-100 mt-20-i">
            <mat-form-field class="mr-10 mnw-320px" appearance="outline">
                <input type="search" matInput placeholder="Search" [(ngModel)]="searchKeyword"
                    (keyup)="filterOrderListItems()">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-form-field class="mr-10 mw-150px" appearance="outline" *ngIf="!isRetailer">
                <mat-label>Status</mat-label>
                <mat-select (selectionChange)="filterOrderListItems()" [(ngModel)]="filterStatus">
                    <mat-option value="-1">All</mat-option>
                    <mat-option value="0">Scheduled</mat-option>
                    <mat-option value="1">On Air</mat-option>
                    <mat-option value="2">Completed</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-inline-block w-100-i">
            <div class="responsive-table">
                <table id="subscriptionListItem" class="w-100">
                    <thead>
                        <tr>
                            <th class="pl-10-i">Subscription ID</th>
                            <th class="pl-10-i">Session</th>
                            <th class="pl-10-i">Order Date</th>
                            <th class="pl-10-i" *ngIf="!isRetailer">Shop</th>
                            <th class="pl-10-i" *ngIf="isRetailer">Subscriber</th>
                            <th class="pl-10-i">Payment</th>
                            <th class="pl-10-i">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="fs-14 fw-500 lh-24 text-0087" *ngFor="let row of tableDatasource">
                            <td *ngIf="isRetailer">
                                <a *ngIf="requestForSubscriptionsPage" class="text-primary crsr-pointer"
                                    href="/account/{{retailerDomain}}/subscriptions/{{row.subscriptionId}}">#
                                    {{row.subscriptionId}}</a>
                                <a *ngIf="requestForSubscribersPage" class="text-primary crsr-pointer"
                                    href="/store/{{retailerDomain}}/subscribers/{{row.subscriptionId}}">#
                                    {{row.subscriptionId}}</a>
                            </td>
                            <td *ngIf="!isRetailer">
                                <a *ngIf="requestForSubscriptionsPage" class="text-primary crsr-pointer"
                                    href="/account/subscriptions/{{row.subscriptionId}}"># {{row.subscriptionId}}</a>
                            </td>
                            <td class="tt-capitalize">
                                {{row.session}}
                            </td>
                            <td>{{row.orderDate | date : 'MMM dd, yyyy'}}</td>
                            <td class="tt-capitalize" *ngIf="!isRetailer">{{row.shop}}</td>
                            <td class="tt-capitalize" *ngIf="isRetailer">
                                <span>{{row.customerFirstName}}</span>
                                <span *ngIf="row.customerLastName" class="ml-5">{{row.customerLastName}}</span>
                            </td>
                            <td class="fs-12">
                                <span>{{row.payment}}</span>
                            </td>
                            <td *ngIf="isRetailer">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="more action">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <a mat-menu-item *ngIf="requestForSubscriptionsPage"
                                        href="/account/{{retailerDomain}}/subscriptions/{{row.subscriptionId}}">View
                                        Details</a>
                                    <a mat-menu-item *ngIf="requestForSubscribersPage"
                                        href="/store/{{retailerDomain}}/subscribers/{{row.subscriptionId}}">View
                                        Details</a>
                                </mat-menu>
                            </td>
                            <td *ngIf="!isRetailer">
                                <mat-menu #menu="matMenu">
                                    <a mat-menu-item href="/account/subscriptions/{{row.subscriptionId}}">View
                                        Details</a>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator (page)="pageChanged($event)" [length]="subscriptionListItem.length"
                [pageSize]="tablePageSize" [pageSizeOptions]="[2, 5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>