import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { IItem } from 'src/app/models/item.model';
import { IItemDetail } from 'src/app/models/item-detail.model';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { ICartItem } from 'src/app/models/cart-item.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { ArrayUtils } from 'src/app/helpers/arrayUtils/array.utils';
import { taxDetail } from '../../models/tax-detail.model';

export interface ProductDetailsDialogData {
    productId: string;
    productItem: IItem;
    retailerDomain: string,
    retailerStoreName: string;
}


@Component({
    selector: 'add-showcase-product-dialog',
    templateUrl: '../../views/common/product-detail.dialog.component.html',
    styleUrls: ['../../../assets/css/common/product-detail.dialog.component.css']
})
export class ProductDetailsDialogComponent implements OnInit, OnDestroy {

    data: ProductDetailsDialogData;
    itemDetail: IItemDetail;
    itemDetailvariations: any;

    colors: any[] = [];
    sizes: any[] = [];

    domain:string;

    subscriptions = new Subscription();

    colorModel: string;
    sizeModel: string;
    quantityModel: number = 1;

    taxDetail: taxDetail;

    inProgress: boolean = false;
    itemLoading: boolean = true;

    constructor(
        private currencyPipe: CurrencyPipe,
        private dialogRef: MatDialogRef<ProductDetailsDialogComponent>,
        private itemService: ItemService,
        private authService: AuthenticationService,
        private accountService: AccountService,
        private retailerStoreService: RetailerStoreService,
        private cartService: CartService,
        private session: SessionService,
        private snackBar: MatSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public dialogdata: ProductDetailsDialogData) {

        this.data = dialogdata;
    }

    ngOnInit(): void {
        
        this.domain = this.router.url.split('/').filter(function(x) {return x;})[1];
        this.subscriptions.add(
            this.itemService.getItemDetailById(this.data.productItem.id)
                .subscribe((itemdetail: IItemDetail) => {
                    this.itemDetail = itemdetail;
                    this.itemDetailvariations = itemdetail.variations[0];

                    this.itemDetail?.variations.forEach((v: any) => {
                        if (v.options
                            && v.options.length > 0) {
                            v.options.forEach(opt => {
                                if (opt["attribute"] == "color") {
                                    const color = opt["value"];
                                    if (this.colors.indexOf(color) == -1) {
                                        this.colors.push(color);
                                    }
                                } 
                                else if (opt["attribute"] == "size") {
                                    const size = opt["value"];
                                    if (this.sizes.indexOf(size) == -1) {
                                        this.sizes.push(size);
                                    }
                                }
                            });
                        }
                        // this.itemVariationList.push(v);
                    });

                    if( this.itemDetail?.tax_ids?.length ){
                        this.itemService.getItemTaxById(this.itemDetail?.tax_ids[0],this.data.retailerDomain).subscribe(
                            (res:any) => {
                                this.taxDetail = res.tax_details
                            }
                        )
                    }
                    this.itemLoading = false;

                })
        );
    }

    onCancelClick() {
        this.dialogRef.close();
    }

    onBuyNowClicked() {

        // clear previous items
        this.cartService.clearCart();
        
        this.inProgress = true;

        var variationId = ArrayUtils.findVariationIdByColorSize(this.itemDetail.variations, this.colorModel, this.sizeModel);
        if (!variationId) variationId = this.itemDetailvariations.id;

        const cartItem: ICartItem = {
            itemId: this.itemDetail.id,
            name: this.itemDetail.name,
            quantity: this.quantityModel,
            image_id: this.itemDetail?.image_id,
            unitPrice: this.itemDetailvariations?.price_money?.amount,
            tax_details: this.taxDetail ? this.taxDetail : null,
            variationId:variationId
        }

        this.session.checkoutDomain = this.domain;
        this.cartService.addToCart(cartItem);
        this.dialogRef.close();
        this.router.navigate([]).then(result => {  window.open('/shops/checkout', '_blank'); });

    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
