<div class="mw-560px-i">
    <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">{{title}}</h1>
    <div mat-dialog-content>
        <p class="p3-dark-fwm ">{{dialogHeader}}</p>
        <div class="pb-10 pt-10">
            <mat-spinner *ngIf="loadItems" class="m-0 ml-auto mr-auto mt-30 mb-45" ></mat-spinner>
            <app-chips-multi-select 
                (toggleSelectionEvent)="toggleChipSelection($event)" 
                [options]="itemCategories"
                [activeOptions]="productNatures"></app-chips-multi-select>
        </div>
        <p class="p3-dark pt-20-i pb-10-i">
            You can always change your choice from user profile.
        </p>
    </div>
    <div mat-dialog-actions class="pstn-relative">
        <mat-spinner diameter="25" class="color-primary pstn-absolute t24 l4" [class.hidden]="!inProgress"></mat-spinner>
        <button mat-raised-button (click)="onSaveAndContinueClick()" color="primary" class="tt-upper">
            <span>Save and Continue</span>
        </button>
    </div>
</div>