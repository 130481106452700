import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { BrowserUtil } from 'src/app/helpers/browser-util';
import { StringUtils } from 'src/app/helpers/stringUtils/string.utils';
import { ISchedule } from 'src/app/models/schedule.model';
import { IUserInfo } from 'src/app/models/user-info.model';
import { Red5ProPublisher } from 'src/app/red5pro/publisher/red5pro.publisher';
import { Red5ProSubscriber } from 'src/app/red5pro/subscriber/subscriber.red5pro';
import { AuthenticationService } from 'src/app/services';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { StreamSubscriptionDialogComponent } from '../../common/session/stream-subscription-dialog.component';
import { StopStreamingDialogComponent } from '../../store/stop-stream-dialog.component';

declare var videojs: any;
declare var Hls: any;

@Component({
    selector: 'session-card-content',
    templateUrl: '../../../views/sessions/card/session-stream-card-content.component.html'
})
export class SessionStreamCardContentComponent implements OnInit, OnDestroy, AfterViewInit {

    isAuthorized: boolean = false;
    subscriptionRequired: boolean = false;

    displaySessionThumbImage: boolean = true;
    displayRed5ProSubscription: boolean = false;
    displayOfflineSubscription: boolean = false;

    session: ISchedule;

    storeName: string;
    offlineStreamPlayer: any;

    streamElementId: string = 'red5pro-subscriber';
    streamElement: any;

    subscription: Subscription = new Subscription();

    constructor(
        public dialog: MatDialog,
        private datePipe: DatePipe,
        private activatedRoute: ActivatedRoute,
        private authService: AuthenticationService,
        private scheduleService: ScheduleService,
        private retailerStoreService: RetailerStoreService,
        private sessionManager: SessionComponentService,
        private snackBar: MatSnackBar) { }

    ngOnInit(): void {

        if (this.sessionManager.isRetailerView) {
            this.streamElementId = 'red5pro-publisher';
            this.storeName = this.activatedRoute.parent.snapshot.paramMap.get('domain');
        }
        else {
            this.storeName = this.activatedRoute.parent.snapshot.paramMap.get('storename');
        }

        this.subscription.add(
            this.authService.currentUser.subscribe((authorizeUser: IUserInfo) => {
                if (authorizeUser) {
                    this.isAuthorized = true;
                    this.checkIfUserHasSubscription();
                    return;
                }

                this.isAuthorized = false;
            })
        );

        this.subscription.add(
            this.sessionManager.session.subscribe((ssn: ISchedule) => {
                this.session = ssn;
                if (this.sessionManager.isConsumerView) {
                    this.subscriptionRequired = ssn.type == 'exclusive';
                    this.sessionManager.subscriptionRequired.emit(this.subscriptionRequired);
                }
                this.renderSession();
            })
        );

        this.subscription.add(
            this.sessionManager.subscriptionRequired.subscribe((isRequired: boolean) => {
                this.subscriptionRequired = isRequired;
                this.renderSession();
            })
        );

        this.subscription.add(
            this.sessionManager.startStream.subscribe((start: boolean) => {
                if (start) {
                    this.publishStream();
                    this.setOnAirVideoStreaming();
                }
            })
        );

        this.subscription.add(
            this.sessionManager.stopStream.subscribe((stop: boolean) => {
                if (stop) {
                    this.unPublishStream();
                }
            })
        );

        this.subscription.add(this.sessionManager.openSubscriptionDialog.subscribe((open: boolean) => { if (open) { this.openSubscriptionDialog(); } }));
    }

    get returnUrl(): string {
        const url = (window as any).location.href.replace(location.origin, '');
        return url;
    }

    ngAfterViewInit(): void {

        this.streamElement = document.getElementById(this.streamElementId);
        this.configureStreamCardContentContainer();
        
        var self = this;
        window.addEventListener("orientationchange", function () {
            self.configureStreamCardContentContainer();
        }, false);

        window.addEventListener("resize", function () {
            self.configureStreamCardContentContainer();
        });
        

        // bind message listener
        var self = this;
        window.addEventListener("storage", function (ev: any) {
            if (ev.key != 'session-subscription') return; // ignore other keys
            var message = JSON.parse(ev.newValue);
            if (!message) return; // ignore empty msg or msg reset

            if (message.command == 'subscriptionRequired') self.sessionManager.subscriptionRequired.emit(message.data);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private checkIfUserHasSubscription(): any {

        if (this.sessionManager.isRetailerView) {
            this.sessionManager.subscriptionRequired.emit(false);
            return;
        }

        this.subscription.add(
            this.sessionManager.isExclusive.subscribe((exclusive: boolean) => {
                if (exclusive) {
                    const userId = this.authService.currentUserId;
                    const sessionId = this.activatedRoute.snapshot.paramMap.get('sessionId');
                    this.scheduleService
                        .loadSubscriptionInfoByScheduleIdUserId(sessionId, userId)
                        .toPromise()
                        .then((res: any) => {
                            this.sessionManager.subscriptionRequired.emit(!res.hasSubscription);
                        });
                }
            })
        );
    }

    private loadOfflineRecord() {

        // stream broadcasting already completed. 
        // it can be connected from offline record now
        var streamname = StringUtils.trimAllWhitespace(this.storeName);
        // remove garbage charecter from application name
        streamname = streamname.replace(/\./g, '');
        // concate stream id to make the stream name url unique
        streamname = `${streamname}${this.session.id}`;

        const offlineStreamUrl = `https://${AppConfig.settings.red5pro.host}/live/${streamname}${AppConfig.settings.red5pro.offlineFileExtension}`;
        // var video: any = document.getElementById('video-player');
        // video.type = "application/vnd.apple.mpegurl";

        
        // if (video.canPlayType('application/vnd.apple.mpegurl')
        //     || video.canPlayType('application/x-mpegURL')) {
        //     video.src = offlineStreamUrl;
        //     //
        //     // If no native HLS support, check if HLS.js is supported
        //     //
        // }
        // else if (Hls.isSupported()) {
        //     var hls = new Hls();
        //     hls.loadSource(offlineStreamUrl);
        //     hls.attachMedia(video);
        // }

        this.offlineStreamPlayer = videojs(document.getElementById('video-player'),
            {
                sources: {
                    src: offlineStreamUrl,
                    type: "application/x-mpegURL"
                },
            },
            function onPlayerReady() {
                // Here where the magic happens :D 
                this.on('loadedmetadata', () => { });
                this.on('timeupdate', () => { });
                this.on('loadeddata', () => { });
            });
    }

    private setSessionthumbImage(): void {
        this.displayOfflineSubscription = false;
        this.displayRed5ProSubscription = false;
        this.displaySessionThumbImage = true;
        this.sessionManager.status.emit(StreamStatusEnum.PENDING);
    }
    private setOnAirVideoStreaming(): void {
        this.displayOfflineSubscription = false;
        this.displayRed5ProSubscription = true;
        this.displaySessionThumbImage = false;
        this.sessionManager.status.emit(StreamStatusEnum.IN_PROGRESS);
    }
    private setOfflineVideoRecord(): void {
        this.displayOfflineSubscription = true;
        this.displayRed5ProSubscription = false;
        this.displaySessionThumbImage = false;
        this.sessionManager.status.emit(StreamStatusEnum.CLOSED);
        if (!this.subscriptionRequired) this.loadOfflineRecord();
    }
    private subscribeStream(): void {

        var streamname = StringUtils.trimAllWhitespace(this.storeName);
        // remove garbage charecter from application name
        streamname = streamname.replace(/\./g, '');
        // concate stream id to make the stream name url unique
        streamname = `${streamname}${this.session.id}`;

        Red5ProSubscriber
            .connect(streamname)
            .then((res: any) => {
                this.subscription.add(
                    Red5ProSubscriber.connectionClosed.subscribe((closed: boolean) => {
                        this.resetstreamElement();
                        // this.setSessionthumbImage();
                    })
                );
            })
            .catch(e => {
                if (e.type && e.type.toLowerCase() == 'timeout') {
                    this.setSessionthumbImage();
                    this.sessionManager.configureConversationContentPlacement.emit(true);
                }
                else {
                    this.setSessionthumbImage();
                    this.sessionManager.configureConversationContentPlacement.emit(true);
                    console.log(JSON.stringify(e));
                }
            });
    }
    public publishStream(): void {

        this.sessionManager.publisingStream.emit(true);
        var streamname = StringUtils.trimAllWhitespace(this.storeName);
        // remove garbage charecter from application name
        streamname = streamname.replace(/\./g, '');
        // concate stream id to make the stream name url unique
        streamname = `${streamname}${this.session.id}`;

        Red5ProPublisher
            .start(streamname)
            .then(res => {
                this.updateStartStreamStatus();
            })
            .catch(e => {
                this.sessionManager.publisingStream.emit(false);
                this.resetstreamElement();
                this.setSessionthumbImage();
                if (e.type && e.type.toLowerCase() == 'timeout') {
                    this.snackBar.open('Connecting Red5Pro broadcasting server failed.', 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
                }
                else {
                    console.log(JSON.stringify(e));
                }
            });

    }

    private unPublishStream(): void {
        let self = this;
        const dialogRef = this.dialog.open(StopStreamingDialogComponent);
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result.close) {
                this.sessionManager.publisingStream.emit(false);
                this.setSessionthumbImage();
                Red5ProPublisher.stop();
                self.updateStopStreamStatus();
                self.resetstreamElement();
            }
        });
    }
    private updateStartStreamStatus() {
        const userId = this.authService.currentUserId;
        this.retailerStoreService
            .updateStartStreamingStatus(userId, this.session.id, new Date().toJSON())
            .toPromise()
            .then(res => { });
    }

    private updateStopStreamStatus() {
        const userId = this.authService.currentUserId;
        this.retailerStoreService.updateStopStreamingStatus(userId, this.session.id, new Date().toJSON())
            .toPromise()
            .then(res => { });
    }
    private renderSession() {
        if (!this.session) return;
        switch (this.session.status as any) {
            case StreamStatusEnum.CLOSED:
                this.setOfflineVideoRecord();
                break;
            case StreamStatusEnum.IN_PROGRESS:
                this.setOnAirVideoStreaming();
                if (this.sessionManager.isConsumerView) {
                    if (!this.subscriptionRequired) {
                        this.subscribeStream();
                    }
                }
                // if (this.sessionManager.isRetailerView) {
                //     this.publishStream();
                // }
                break;
            default:
                this.setSessionthumbImage();
                break;
        }
    }
    private resetstreamElement() {
        this.streamElement.pause();
        this.streamElement.currentTime = 0;
    }

    private openSubscriptionDialog() {
        const dialogRef = this.dialog.open(StreamSubscriptionDialogComponent,
            {
                data: {
                    stream: this.session
                },
                minWidth: '50vw',
                panelClass: 'stream-subscription-dialog',
            });
    }

    private configureStreamCardContentContainer(): void {
        var streamCardContentContainer$ = document.getElementById('streamCardContentContainer');
        const offsetWidth = streamCardContentContainer$.offsetWidth;
        var height = offsetWidth * 0.56;
        if (BrowserUtil.isMobileBrowser) {
            height += 60;
        }
        streamCardContentContainer$.style.height = `${height}px`;
    }
}