import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe, CurrencyPipe } from '@angular/common';

import { AppConfig } from './app.config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';

import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderTopComponent } from './components/header/header-top.component';
import { BrandComponent } from './components/header/brand.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { SearchbarComponent } from './components/header/searchbar.component';
import { AuthMenuComponent } from './components/header/auth-menu.component';
import { HeaderNavComponent } from './components/header/header-nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterNavComponent } from './components/footer/footer-nav.component';
import { FooterInfoComponent } from './components/footer/footer-info.component';
import { HomeComponent } from './components/home/home.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import {
  ActivateaccountComponent,
  LiveDemonstrationCardInHomeComponent,
  LogoutComponent,
  SignupVerificationComponent,
} from './components';
import { ExclusiveLiveSessionHomeComponent } from './components/home/excl-live-session.home.component';
import { TrendingItemsComponent } from './components/home/trending.home.component';
import { PickedItemsComponent } from './components/home/picked-item.home.component';
import { AiredItemsComponent } from './components/home/aired-item.home.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChipsMultiSelectComponent } from './components/common/chips-multi-select.component';
import { AuthorizationInterceptor } from './authorization.interceptor';
import { MatNativeDateModule } from '@angular/material/core';
import { StreamChattingComponent } from './components/session/session-conversation.component';
import { AsyncImgSrcPipe } from './pipes/async-img-src.pipe';
import { RetailerStoreHeaderNavComponent } from './components/header/retailer-store-header-nav.component';
import { ProductNaturesDialogComponent } from './components/user/product-natures.dialog.user.component';
import { LocationDialogComponent } from './components/user/location.dialog.user.component';
import { ProfileInfoDialogComponent } from './components/user/profileinfo.dialog.user.component';
import { StoreComponent } from './components/store/store.component';
import { AddShowcaseProductDialogComponent } from './components/store/add-showcase-product.dialog.component';
import { SessionComponent } from './components/session/session.component';
import { StoreDirective } from './directives/store.directive';
import { ProductsColInScheduleList } from './components/common/produts-col-in-schedule-list.component';
import { RecentlyViewedItemsComponent } from './components/home/recently-viewed.home.component';
import { ProductDetailsDialogComponent } from './components/common/product-details-dialog.component';
import { ProgressSpinnerDialogComponent } from './components/common/progress-spinner-dialog.component';
import { CheckoutFailedComponent } from './components/shops/checkout-failed.shops.component';
import { SafePipe } from './pipes/safe-pipe';
import { SearchComponent } from './components/search/search.component';
import { AccountDirective } from './directives/account.directive';
import { AccountComponent } from './components/account/account.component';
import { ApiImagePathPipe } from './pipes/api-img-path.pipe';
import { UpdateProfileDialogComponent } from './components/user/update-profile.dialog.component';
import { OrderDetailComponent } from './components/common/order-detail.component';
import { ResetPasswordComponent } from './components/login/reset-password.component';
import { UpdatePasswordComponent } from './components/login/update-password.component';
import { AppStartupConfiguration } from './app.startup';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { StreamShowcasedProductsComponent } from './components/common/session/stream-showcased-products.component';
import { StreamSubscriptionDialogComponent } from './components/common/session/stream-subscription-dialog.component';
import { SubscriptionsComponent } from './components/account/subscriptions.component';
import { SessionDirective } from './directives/session.directive';
import { ConsumerHeaderNavComponent } from './components/header/consumer-view-header-nav.component';
import { SubscriptionDetailComponent } from './components/account/subscription-detail.component';
import { SessionBreadcrumbComponent } from './components/session/session-breadcrumb.component';
import { PublicViewSessionComponent } from './components/session/public-view-session-component';
import { SessionStreamCardComponent } from './components/session/card/session-stream-card.component';
import { SessionStreamCardHeaderComponent } from './components/session/card/session-stream-card-header.component';
import { SessionStreamCardContentComponent } from './components/session/card/session-stream-card-content.component';
import { SessionStreamCardFooterComponent } from './components/session/card/session-stream-card-footer.component';
import { RetailerViewSessionComponent } from './components/session/retailer-view-session.component';
import { RetailerViewStoreComponent } from './components/store/retailer-view.store.component';
import { ConsumerViewStoreComponent } from './components/shops/consumer-view.component.store';
import { RetailerStreamsComponent } from './components/store/retailer-streams.store.component';
import { CreateStreamDialogComponent } from './components/store/create-stream.dialog.component';
import { StopStreamingDialogComponent } from './components/store/stop-stream-dialog.component';
import { CheckoutComponent } from './components/shops/checkout.shops.component';
import { CheckoutSuccessComponent } from './components/shops/checkout-success.shops.component';
import { ShopsStoreFrontComponent } from './components/shops/shops-store-front.component';
import { RetailerOrdersComponent } from './components/store/retailer-orders.component';
import { ConsumerOrdersComponent } from './components/shops/consumer-orders.component';
import { AllShopsComponent } from './components/shops/all-shops.component';
import { DeleteSessionDialogComponent } from './components/common/session/delete-session-dialog.component';
import { CancelSessionDialogComponent } from './components/common/session/cancel-session-dialog.component';
import { TaxPipe } from './pipes/tax.pipe';
import { NewInShopsComponent } from './components/shops/new-in-shops.component';
import { AllExclusiveSessionComponent } from './components/session/all-exclusive-sessions.component';
import { AddItemDialogComponent } from './components/item/add-item.dialog.component';
import { AddTaxDialogComponent } from './components/item/add-tax.dialog.component';
import { ItemListComponent } from './components/item/item-list.component';
import { DeleteItemDialogComponent } from './components/item/delete-item.dialog.component';
import { AddImageDialogComponent } from './components/item/add-image.dialog.component';

@NgModule({
  declarations: [
    ProgressSpinnerDialogComponent,
    AppComponent,
    SignupComponent,
    LoginComponent,
    HeaderComponent,
    HeaderTopComponent,
    BrandComponent,
    SearchbarComponent,
    AuthMenuComponent,
    HeaderNavComponent,
    RetailerStoreHeaderNavComponent,
    FooterComponent,
    FooterNavComponent,
    FooterInfoComponent,
    HomeComponent,
    LiveDemonstrationCardInHomeComponent,
    ExclusiveLiveSessionHomeComponent,
    TrendingItemsComponent,
    PickedItemsComponent,
    AiredItemsComponent,
    SignupVerificationComponent,
    ActivateaccountComponent,
    LogoutComponent,
    ProductNaturesDialogComponent,
    ChipsMultiSelectComponent,
    LocationDialogComponent,
    ProfileInfoDialogComponent,
    AddItemDialogComponent,
    AddTaxDialogComponent,
    DeleteItemDialogComponent,
    AddImageDialogComponent,
    StoreComponent,
    RetailerViewStoreComponent,
    ConsumerViewStoreComponent,
    RetailerStreamsComponent,
    CreateStreamDialogComponent,
    StopStreamingDialogComponent,
    AddShowcaseProductDialogComponent,
    SessionComponent,
    StreamChattingComponent,
    ProductsColInScheduleList,
    RecentlyViewedItemsComponent,
    ProductDetailsDialogComponent,
    CheckoutComponent,
    CheckoutSuccessComponent,
    CheckoutFailedComponent,
    ShopsStoreFrontComponent,
    SearchComponent,
    RetailerOrdersComponent,
    AccountComponent,
    UpdateProfileDialogComponent,
    ConsumerOrdersComponent,
    OrderDetailComponent,
    ResetPasswordComponent,
    UpdatePasswordComponent,
    StreamShowcasedProductsComponent,
    StreamSubscriptionDialogComponent,
    SubscriptionsComponent,
    ConsumerHeaderNavComponent,
    SubscriptionDetailComponent,
    SessionComponent,
    PublicViewSessionComponent,
    SessionBreadcrumbComponent,
    SessionStreamCardComponent,
    SessionStreamCardHeaderComponent,
    SessionStreamCardContentComponent,
    SessionStreamCardFooterComponent,
    RetailerViewSessionComponent,
    AllShopsComponent,
    DeleteSessionDialogComponent,
    CancelSessionDialogComponent,
    NewInShopsComponent,
    AllExclusiveSessionComponent,
    ItemListComponent,
    StoreDirective,
    AccountDirective,
    SessionDirective,
    AsyncImgSrcPipe,
    ApiImagePathPipe,
    SafePipe,
    TaxPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatButtonModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    LayoutModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    IvyCarouselModule,
    MatCardModule,
    MatDividerModule,
    MatTabsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatChipsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatProgressBarModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    CKEditorModule,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    TaxPipe,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: AppStartupConfiguration,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
