import { AppConfig } from "./app.config";

export function AppStartupConfiguration(appConfig: AppConfig) {

    return () =>

        appConfig
            .load()
            .then(ars => {
                const currentUser = AppConfig.currentUserObjectFromLocalStorage;
                const refreshTokenExpires = currentUser ? new Date(currentUser.refreshTokenExpires).getTime() : undefined;
                const isExpired: boolean = (refreshTokenExpires - (new Date()).getTime()) < (1000 * 60 * 60);
                if (isExpired) {
                    // remove user from local storage and set current user to null
                    localStorage.removeItem(AppConfig.settings.localStorage.keys.currentUser);
                }
            });
}