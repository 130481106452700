import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { HttpErrorResponse } from '@angular/common/http';
import { setToSession } from 'src/app/helpers/session-storage/sessionStorage';

@Component({
    selector: 'consumer-personal-interest-dialog',
    templateUrl: '../../views/user/profileinfo.dialog.user.component.html',
    styleUrls: ['../../../assets/css/user/profileinfo.dialog.user.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ProfileInfoDialogComponent implements OnInit {

    storeInfoForm: FormGroup;
    inProgress: boolean = false;

    profilePicture: File;
    phoneNumber = new FormControl('', []);
    addressLine1 = new FormControl('', [Validators.required]);
    addressLine2 = new FormControl('', []);
    state = new FormControl('', [Validators.required]);
    cityOrTown = new FormControl('', [Validators.required]);
    zipCode = new FormControl('', [Validators.required, Validators.minLength(4)]);

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ProfileInfoDialogComponent>,
        public itemService: ItemService,
        public authService: AuthenticationService,
        public accountService: AccountService,
        public retailerStoreService: RetailerStoreService,
        private snackBar: MatSnackBar) {
        this.storeInfoForm = this.formBuilder.group({
            profilePicture: new FormControl('', []),
            phoneNumber: this.phoneNumber,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            state: this.state,
            cityOrTown: this.cityOrTown,
            zipCode: this.zipCode
        });
    }

    ngOnInit(): void { }

    onProfileImageChange($event) {
        if ($event.target.files.length > 0) {
            const file = $event.target.files[0];
            this.profilePicture = file;
        }
    }

    onSubmit() {

        this.inProgress = true;
        if (this.storeInfoForm.invalid) {
            return;
        }

        const userId = this.authService.currentUserId;
        const formData = new FormData();
        if (this.profilePicture) {
            formData.append('profilePicture', this.profilePicture);
        }

        formData.append('phoneNumber', this.phoneNumber.value);
        formData.append('addressLine1', this.addressLine1.value);
        formData.append('addressLine2', this.addressLine2.value);
        formData.append('state', this.state.value);
        formData.append('cityOrTown', this.cityOrTown.value);
        formData.append('zipCode', this.zipCode.value);

        this.retailerStoreService.updateStoreInformation(userId, formData)
            .toPromise()
            .then((res: any) => {
                this.inProgress = false;
                this.authService.updateUserProfilePictureUrlInLocalStorage(res.profilePictureUrl);
                this.snackBar.open('Profile information updated', 'Close', { duration: 10000, panelClass: ['mat-success-bg'] });
                this.dialogRef.close();
            })
            .catch((err: HttpErrorResponse) => {
                this.inProgress = false;
                const errorDetail = err.error.error || err.error;
                this.snackBar.open(errorDetail, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
            });
    }

    closeDialog() {
        setToSession('skip_profile_update',10080)
        this.dialogRef.close();
    }
    
}
