import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { IItemCategory } from 'src/app/models';
import { IRecentlyAiredSchedule } from 'src/app/models/recently-aired-items.model';
import { IRetailerInfo } from 'src/app/models/retailer-info.model';
import { IUpcomingSchedule } from 'src/app/models/up-coming-items.model';
import { ItemService } from 'src/app/services';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'shops-store-front',
  templateUrl: '../../views/shops/shops-store-front.component.html',
  styles: [
    `
      #storeLogoContainer img {
        height: 124px;
        width: 124px;
        object-fit: contain;
      }
    `,
  ],
})
export class ShopsStoreFrontComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  retailerDomain: string;

  loadingItems: boolean = true;

  retailerInfo: IRetailerInfo;
  productNatures: any[];
  retailerProductNatures: any[];
  raItems: any[] = [];
  ucItems: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private shopService: ShopService,
    public itemService: ItemService
  ) {}

  ngOnInit(): void {
    this.retailerDomain =
      this.activatedRoute.snapshot.paramMap.get('storename');

    this.itemService.categories().subscribe((productNatures: any) => {
      this.shopService
        .loadRetailerInfoByDomain(this.retailerDomain)
        .toPromise()
        .then((retailerInfo: any) => {
          this.loadingItems = false;
          this.productNatures = productNatures;
          this.retailerInfo = retailerInfo;
          this.retailerProductNatures = this.productNatures.filter(
            (item: IItemCategory) => {
              return retailerInfo.productNatures.some((id) => id == item.id);
            }
          );
        });
    });

    this.loadRecentlyAiredSchedules();
    this.loadUpcommingSchedules();
  }

  loadRecentlyAiredSchedules() {
    this.subscriptions.add(
      this.shopService
        .allSchedulesInRecentlyAiredByDomain(this.retailerDomain)
        .subscribe((res: IRecentlyAiredSchedule[]) => {
          this.raItems = res;
        })
    );
  }

  loadUpcommingSchedules() {
    this.subscriptions.add(
      this.shopService
        .allSchedulesInUpcomingByDomain(this.retailerDomain)
        .subscribe((res: IUpcomingSchedule[]) => {
          this.ucItems = res;
        })
    );
  }

  combineBackendApiUrl(relativePath: string) {
    if (!relativePath) return '/assets/images/store_shop_banner.png';
    return relativePath;
  }

  onStoreBannerImageError(event$: any, defaultUrl: string) {
    event$.target.src = defaultUrl;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
