<h1 class="mat-dialog-title h1-dark fs-20-i fw-500-i">Subscribe to exclusive session</h1>
<div class="mat-dialog-content of-hidden-scroll">
    <div class="pb-10 pt-10">
        <div class="pb-10 pt-10">
            <div class="d-flex-col fxd-col-sm pstn-relative">
                <div class="w-100">
                    <div class="bg-f5f5f5 p-15 brds-4px d-flex-row">
                        <mat-icon>smart_display</mat-icon>
                        <span class="fs-16 fw-400 text-0087 lh-24 ml-15">{{stream?.title}}</span>
                    </div>
                </div>
                <div class="w-100 mt-25 pt-25 d-flex-col">
                    <p class="d-flex-row fxd-col-sm m-0-i mb-5-i m-0-sm">
                        <span class="fs-32 fw-400 text-primary">{{stream?.amount/100 | currency}}</span>
                        <span class="fs-24 fw-400 text-0006 lh-24 ml-10 ml-0-sm lh-60-sm">
                            <span class="hidden-sm">/</span>
                            <span *ngIf="isPending">48</span>
                            <span *ngIf="!isPending">24</span> hours access
                        </span>
                    </p>
                    <span class="fs-14 fw-400 text-0006 lh-24">(Inclusive of all taxes)</span>
                </div>
                <div class="w-100 mt-25 pt-10 d-flex-row fxd-col-sm">
                    <span class="fs-16 fw-400 text-0087 lh-24">24 hours access</span>
                    <span class="fs-16 fw-400 text-0006 lh-24 ml-5 ml-0-sm">if subscribed after live session ended.</span>
                </div>
                <div class="w-100 mt-10 pt-10 d-flex-row">
                    <mat-icon>done</mat-icon>
                    <span class="fs-16 fw-400 text-0006 lh-24 ml-15">The time limit will begin once you have started
                        watching the session.</span>
                </div>
                <div class="w-100 pt-10 d-flex-row">
                    <mat-icon>done</mat-icon>
                    <span class="fs-16 fw-400 text-0006 lh-24 ml-15">You will need to subscribe again to get access
                        after the time limit is over.</span>
                </div>
                <div class="w-100 pt-10 d-flex-row">
                    <mat-icon>done</mat-icon>
                    <span class="fs-16 fw-400 text-0006 lh-24 ml-15">Re-subscription will add 24 hours to your existing
                        time limit (if remaining).</span>
                </div>
                <div class="w-100 mt-10 pt-10 d-flex-row" *ngIf="!isAuthorized">
                    <span class="fs-14 fw-400 text-b00020 lh-24">You are not logged in. You will be prompt to login
                        or register to subscribe. </span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mat-dialog-actions pstn-relative m-0-24-neg p-0-24-i bt-s1-i bc-00012-i mt-40">
    <button mat-flat-button class="tt-upper text-primary ml-auto-i" (click)="onCancelClick()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" class="tt-upper ml-20">
        <mat-spinner diameter="20" class="color-white pstn-absolute t24 l25"
                                [class.hidden]="!inProgress"></mat-spinner>
        <span (click)="onSubscribeClick()">Subscribe</span>
    </button>
</div>