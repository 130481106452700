<mat-card id="exclusiveLiveSessionCarousel" class="w-70 mr-15 p-0-i w-100-sm">
  <carousel
    [cellWidth]="'100%'"
    [autoplay]="true"
    [dots]="true"
    [objectFit]="'cover'"
    [height]="388"
  >
    <div class="carousel-cell w-100" *ngFor="let sItem of specialStreamList">
      <mat-card class="p-0-i h-100 bxs-none b-0-i">
        <mat-card-content class="d-flex-row-i fxd-col-sm h-100 mh-sm-220px">
          <div class="w-55 w-100-sm h-100 pstn-relative">
            <a href="/shops/{{ sItem?.user?.domain }}/sessions/{{ sItem._id }}">
              <img
                src="{{ combineBackendApiUrl(sItem?.streamThumbnailUrl) }}"
                class="b-0"
                onerror="this.src='./assets/images/thumbnail-default.jpg'"
              />
            </a>
          </div>
          <div
            class="info w-45 w-100-sm h-100 pl-40 pt-50 pt-20-sm pr-30 p-sm-15"
          >
            <p class="h3-primary tt-upper mb-20 mb-5-sm d-flex-row">
              <img
                [src]="sItem?.user?.logoUrl | apiImgPath : { nl: true }"
                class="w-36px-i h-36px-i brds-100 b-s1 bc-00012 obj-ft-contain"
                onerror="this.src='./assets/images/nologo.jpg'"
              />
              <a
                class="lh-30 ml-15-i"
                href="/shops/{{ sItem?.user?.domain }}"
                >{{ sItem?.user?.storeName }}</a
              >
            </p>

            <a
              class="p2-dark mb-20 mb-5-sm p2-dark-sm"
              href="/shops/{{ sItem?.user?.domain }}/sessions/{{ sItem?._id }}"
            >
              <p class="p2-dark mb-20 mb-5-sm p2-dark-sm">{{ sItem?.title }}</p>
            </a>
            <a
              *ngIf="sItem.status == 1"
              mat-raised-button
              color="primary"
              href="/shops/{{ sItem?.user?.domain }}/sessions/{{ sItem._id }}"
              class="tt-upper mt-20-i"
              >Watch Now</a
            >
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </carousel>
</mat-card>
<mat-card
  id="exclusiveLiveSessionList"
  class="pt-0-i w-30 ml-15 pstn-relative pl-0-i pr-0-i pb-0-i w-100-sm ml-0-sm mt-15-sm"
>
  <mat-card-header class="d-flex-row d-block w-100 p-0 mat-card-header-primary">
    <mat-card-title class="h1-white">
      <h2 class="h1-white m-0-i">Exclusive Live Sessions</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="mb-0-i" id="exclusiveSessionListContainer">
    <mat-card
      class="bxs-none bt-0-i bl-0-i br-0-i w-100 brds-0-i bxs-none-i p-15 pt-10-i pb-10-i"
      *ngFor="let sitem of exclusiveSession"
    >
      <mat-card-content class="mh-100 d-flex-row-i">
        <a
          href="/shops/{{ sitem?.user?.domain }}/sessions/exclusive/{{
            sitem._id
          }}"
          class="b-0 w-90px h-76px d-inline-flex"
        >
          <img
            [src]="sitem.streamThumbnailUrl"
            class="b-0 w-90px h-76px d-inline-flex obj-ft-cover"
          />
        </a>
        <div class="d-inline-flex-col flex-wrap ml-15-i">
          <p class="h5-primary tt-upper mb-5-i">
            <a href="/shops/{{ sitem?.user?.domain }}" class="text-primary">{{
              sitem?.user?.storeName
            }}</a>
          </p>
          <h5 class="p3-dark mb-5-i fs-13-i h-36px of-hidden">
            <a
              href="/shops/{{ sitem?.user?.domain }}/sessions/exclusive/{{
                sitem._id
              }}"
              class="p3-dark mb-5-i fs-13-i h-36px of-hidden"
            >
              {{ sitem?.title }}
            </a>
          </h5>
          <p class="p4-dark m-0-i">
            {{ sitem?.startTime | date : "dd MMM, yyyy - hh:mm a" }}
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
  <mat-card-actions
    class="w-100 m-0-i p-15-i pstn-absolute-b0-l0 pstn-sm-relative bt-s1 bc-00012"
  >
    <a href="/sessions/exclusive/all" class="link-primary tt-upper"
      >Sign up for a Session</a
    >
  </mat-card-actions>
</mat-card>
