import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { JWTTokenService } from './services/jwt-token.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './services';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private jwtTokenService: JWTTokenService,
        private authService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        const token = this.jwtTokenService.authorizationToken;

        if (this.skipTokenIntercepting(request)) return next.handle(request);

        if (token) request = this.addToken(request, token);

        if (!token) {
            if (this.jwtTokenService.refreshToken)
                return this.refreshToken(next, request);
        }

        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                return this.handle401Error(request, next);
            } else {
                return throwError(error);
            }
        }));
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            return this.refreshToken(next, request);

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }

    private refreshToken(next: HttpHandler, request: HttpRequest<any>) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this.authService.refreshToken(this.jwtTokenService.refreshToken).pipe(
            switchMap((token: any) => {
                this.isRefreshing = false;
                this.refreshTokenSubject.next(token.jwtToken);
                return next.handle(this.addToken(request, token.jwtToken));
            }),
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    if (error.error?.error) {
                        const errmsg = error.error?.error.toLowerCase();
                        if (errmsg.indexOf("refresh") > -1
                            && errmsg.indexOf("token") > -1
                            && errmsg.indexOf("expired") > -1) {
                            window.location.href = '/login';
                        }
                    }

                } else {
                    return throwError(error);
                }
            }));
    }

    private skipTokenIntercepting(request: HttpRequest<any>): boolean {
        if (request.url.indexOf('login') > -1
            || request.url.indexOf('refresh-token') > -1
            || request.url.indexOf('register') > -1
            || request.url.indexOf('/api/shops/') > -1
            || (request.url.indexOf('/schedule/exclusive') > -1 && request.url.indexOf('/subscribe') == -1)
            || request.url.indexOf('/search/shops-schedules') > -1
            || request.url.indexOf('/api/items/categories') > -1
            || request.url.indexOf('/api/items/tax-details') > -1
            || request.url.indexOf('/api/items/tax-details') > -1
            || request.url.indexOf('/api/items/') > -1 ) {
                // 
                
            return true;
        }

        if (request.url.indexOf('/comments') > -1
            && request.method.toLowerCase() == "get") {
            return true;
        }

        return false;
    }
}