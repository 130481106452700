<div class="mw-580px-i mnw-320px">
  <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">{{ formTitle }}</h1>
  <div mat-dialog-content>
    <form
      name="itemForm"
      [formGroup]="itemForm"
      (ngSubmit)="onSubmit()"
      method="post"
      enctype="multipart/form-data"
    >
      <div class="pb-10 pt-10">
        <div class="pb-10 pt-10">
          <div class="form-item mt-25">
            <div class="row d-block">
              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Name</mat-label>
                  <input
                    matInput
                    placeholder="name"
                    [formControl]="name"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Price (In Cents)</mat-label>
                  <input
                    matInput
                    placeholder="Price"
                    [formControl]="price"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field
                  appearance="outline"
                  *ngIf="categories.length > 0"
                  class="d-block-i"
                >
                  <mat-label>Category</mat-label>
                  <mat-select [formControl]="categoryId">
                    <mat-option
                      *ngFor="let categoryObj of categories"
                      value="{{ categoryObj.id }}"
                      >{{ categoryObj.category }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Description</mat-label>
                  <textarea
                    matInput
                    placeholder="Description"
                    [formControl]="description"
                    required
                  ></textarea>
                </mat-form-field>
              </div>
              <div class="col-12">
                <input
                  id="itemImage"
                  class="mat-stroke-button-file-input"
                  type="file"
                  (change)="onItemImageChange($event)"
                  required
                />
              </div>
              <div class="col-12 pt-10">
                <mat-checkbox
                  [disabled]="disableTaxInfo"
                  required
                  [formControl]="enableTax"
                >
                  Enable Tax
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions class="pstn-relative">
          <mat-spinner
            diameter="25"
            class="color-primary pstn-absolute t24 r4"
            [class.hidden]="!inProgress"
          >
          </mat-spinner>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="tt-upper"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
