<div
  class="page-wrapper w-100 h-100 d-flex pstn-absolute-b0-l0"
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <div class="page-banner w-40 h-100 bg-primary d-inline-block hidden-sm">
    <div class="d-block w-100">
      <a mat-icon-button class="m-10-i d-block" href="{{ homeRoute }}">
        <mat-icon class="text-white">arrow_back</mat-icon>
        <span class="h1-white ml-10">Back to Marketplace</span>
      </a>
    </div>
    <div class="w-100 ta-center">
      <img src="./assets/images/shopping_bg.png" class="w-75 m-auto mt-60" />
    </div>
    <div class="pstn-absolute-b0-l0 ml-35 mb-30">
      <h1 class="h1-white mb-25">Contact</h1>
      <span class="d-block h3-dwhite">+1 425 280 3473</span>
      <span class="d-block h3-dwhite">info@macurian.shop</span>
      <span class="d-block h3-dwhite mt-30">info@macurian.shop</span>
      <div class="d-block mt-10">
        <img
          class="facebook_icon mr-10"
          height="25px"
          src="./assets/images/facebook_icon.png"
        />
        <img
          class="instagram_icon mr-10"
          height="22px"
          src="./assets/images/instagram_icon.png"
        />
        <img
          class="twitter_icon mr-10"
          height="21px"
          src="./assets/images/twitter_icon.png"
        />
        <img
          class="youtube_icon mr-10"
          height="20px"
          src="./assets/images/youtube_icon.png"
        />
        <img
          class="pinterist_icon"
          height="25px"
          src="./assets/images/pinterist_icon.png"
        />
      </div>
    </div>
  </div>
  <div
    class="page-form w-60 w-100-sm h-100 bg-white d-inline-block pstn-relative"
  >
    <div class="form-wrapper">
      <div
        class="dialog-form-overlap pstn.abolute-100h-100h p-140 pt-50 pb-0-i p-sm-30"
      >
        <div class="form-content form-light w-70 w-100-sm mw-400px">
          <a href="{{ homeRoute }}">
            <img src="../../assets/images/marketplace-logo.png" />
          </a>
          <form
            class="mw-400px"
            [class.fade-out]="!passwordRecoveryError"
            name="updatePasswordForm"
          >
            <h1 class="h1-black mt-20-i">Password recovery error</h1>
            <h3 class="h3-black mt-20-i text-black fs-14-i">
              We could not locate the information needed to reset your password.
              Please try again.
            </h3>
            <div class="row d-block pb-20 mt-30">
              <div class="col-12 ml-5 ta-left pr-10 ta-center">
                <label class="forget-password h3-primary text-0087"
                  ><a
                    mat-raised-button
                    color="primary"
                    href="/account/reset-password"
                    class="forget-password tt-upper"
                    >Start Over</a
                  ></label
                >
              </div>
            </div>
          </form>
          <form
            class="mw-400px"
            [class.fade-out]="passwordRecoveryError || passwordResetCompleted"
            name="registrationForm"
            [formGroup]="loginForm"
            (ngSubmit)="onSubmit()"
          >
            <h1 class="h1-black mt-20-i">Forgot your password?</h1>
            <div class="row d-block">
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>New Password</mat-label>
                  <input
                    matInput
                    placeholder="New Password"
                    [formControl]="newPassword"
                    type="password"
                    required
                  />
                  <mat-hint class="mb-10 hidden"
                    >Minimum 8 characters, include uppercase, lowercase, numbers
                    and a special charecter.</mat-hint
                  >
                  <mat-error *ngIf="newPassword.invalid"
                    >{{ getErrorMessage("newPassword") }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline">
                  <mat-label>Confirm password</mat-label>
                  <input
                    matInput
                    placeholder="Confirm password"
                    [formControl]="confirmPassword"
                    type="password"
                    required
                  />
                  <mat-error *ngIf="confirmPassword.invalid">
                    {{ getErrorMessage("confirmPassword") }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row d-block">
              <div class="col-12 ml-5 ta-left pr-10">
                <div class="md-form ta-left">
                  <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    id="button-form-submit"
                    class="w-100-i tt-upper pt-5-i pb-10-i"
                  >
                    <mat-spinner
                      diameter="20"
                      class="color-white pstn-absolute t24 l4"
                      [class.hidden]="!inProgress"
                    ></mat-spinner>
                    <span>RESET PASSWORD</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row d-block pb-20 mt-30">
              <div class="col-12 ml-5 ta-left pr-10">
                <label class="forget-password h3-primary text-0087"
                  >Remembered the Password?
                  <a href="/login" class="forget-password"> Login?</a></label
                >
              </div>
            </div>
          </form>
          <form
            class="mw-360px"
            [class.fade-out]="passwordRecoveryError || !passwordResetCompleted"
            [class.fade-in]="passwordResetCompleted"
            name="updatePasswordForm"
          >
            <h1 class="h1-black mt-20-i">Password Reset Successful</h1>
            <h3 class="h3-black mt-20-i text-black fs-14-i">
              The password for your Marketplace account has been successfully
              reset.
            </h3>
            <div class="row d-block pb-20 mt-30">
              <div class="col-12 ml-5 ta-left pr-10 ta-center">
                <label class="forget-password h3-primary text-0087"
                  ><a
                    mat-raised-button
                    color="primary"
                    href="/login"
                    class="forget-password tt-upper"
                    >Continue</a
                  ></label
                >
              </div>
            </div>
          </form>
        </div>
        <div
          class="pstn-absolute-b0-l0 ml-35 mw-400px m-auto-sm mb-30 hidden d-block-sm pstn-sm-relative"
        >
          <h1 class="h1-white mb-25 clr-sm-dark">Contact</h1>
          <span class="d-block h3-dwhite clr-sm-ddark">+1 425 280 3473</span>
          <span class="d-block h3-dwhite clr-sm-ddark">info@macurian.shop</span>
          <span class="d-block h3-dwhite mt-30 mt-sm-15 clr-sm-ddark"
            >info@macurian.shop</span
          >
          <div class="d-block mt-10 clr-sm-primary mb-sm-15">
            <img
              class="facebook_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="25px"
              src="./assets/images/facebook_icon.png"
            />
            <img
              class="instagram_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="22px"
              src="./assets/images/instagram_icon.png"
            />
            <img
              class="twitter_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="21px"
              src="./assets/images/twitter_icon.png"
            />
            <img
              class="youtube_icon mr-10 bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="20px"
              src="./assets/images/youtube_icon.png"
            />
            <img
              class="pinterist_icon bg-clr-sm-primary brds-sm-2px p-sm-5"
              height="25px"
              src="./assets/images/pinterist_icon.png"
            />
          </div>
        </div>
        <div class="pstn-absolute-b0 pstn-sm-relative ta-sm-center">
          <p class="h4-dark">
            Copyright &copy; 2017 Macurian Inc. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
