<div
  class="d-block w-100 pl-100 pr-100 ff-roboto pl-sm-0 pr-sm-0 hidden-sm header-nav-container"
  fxLayout="row"
  fxLayoutAlign="center"
>
  <a
    mat-button
    href="/account"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'account'"
    >My Account</a
  >
  <a
    mat-button
    href="/account/orders"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'orders'"
    >My Orders</a
  >
  <a
    mat-button
    href="/account/subscriptions"
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'subscriptions'"
    >My Subscriptions</a
  >
  <a
    mat-button
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'my-recomendation'"
    >My Recomendation</a
  >
  <a
    mat-button
    class="link3-dark-m60 tt-upper"
    [class.selected]="activeRoute === 'watch-history'"
    >Watch History</a
  >
</div>
