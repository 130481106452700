import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ItemService } from 'src/app/services';

@Component({
    selector: 'products-col-in-schedule-list',
    template: `<span>{{productName}}</span>`,
    styles: [``]
})
export class ProductsColInScheduleList implements OnInit {

    @Input() ProductIds: any[] = [];

    productName: string = '';

    constructor(private itemService: ItemService) { }

    ngOnInit(): void {

        if (!this.ProductIds) {
            this.productName = '';
            return;
        };

        const itemId = this.ProductIds[0];
        this.itemService.getItemNameById(itemId).toPromise().then((res:any) => {
            var name = res.name;
            if (this.ProductIds.length > 1) {
                name += '(+' + (this.ProductIds.length - 1) + ')';
            }
            this.productName = name;
        });
    }
}
