<div class="nav-container d-flex-row w-100 fx-wrap-sm">
    <mat-list class="col-fx-50-sm d-flex-sm">
        <div mat-subheader>Help</div>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Account Log-in</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Account Sign-up</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Getting Started</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">FAQs</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Sell on Marketplace0</a>
        </mat-list-item>
    </mat-list>
    <mat-list class="col-fx-50-sm d-flex-sm">
        <div mat-subheader>Shopping</div>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Order Status</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Delivery</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Returns Policy</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Security</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">GDPR Privacy</a>
        </mat-list-item>
    </mat-list>
    <mat-list class="col-fx-50-sm d-flex-sm">
        <div mat-subheader>Others</div>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">About us</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Partnership</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Terms and Conditions</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">Cookie Policy</a>
        </mat-list-item>
    </mat-list>
    <mat-list class="contacts col-fx-50-sm d-flex-sm">
        <div mat-subheader>Contact</div>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">+1 425 280 3473</a>
        </mat-list-item>
        <mat-list-item><a mat-button mat-line href="https://www.google.com/" target="_blank">info@macurian.shop</a>
        </mat-list-item>
        <mat-list-item class="follow-us-items mt-15"><a mat-button mat-line href="https://www.google.com/"
                target="_blank">Follow us on</a>
        </mat-list-item>
        <mat-list-item class="contacts-link-item">
            <div class="contacts-link-container pt-5">
                <img class="facebook_icon" src="./assets/images/facebook_icon.png">
                <img class="instagram_icon" src="./assets/images/instagram_icon.png">
                <img class="twitter_icon" src="./assets/images/twitter_icon.png">
                <img class="youtube_icon" src="./assets/images/youtube_icon.png">
                <img class="pinterist_icon" src="./assets/images/pinterist_icon.png">
            </div>
        </mat-list-item>
    </mat-list>
</div>