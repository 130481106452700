import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { ICreateOrderResponse } from '../models/create-order-response.model';
import { ICreatePaymentResponse } from '../models/create-payment-response.model';
import { IOrder } from '../models/order.model';
import { IPayment } from '../models/payment.model';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class PaymentService {

    protected apiServer = AppConfig.settings.apiServer;

    constructor(private http: HttpClient) { }

    createPayment(paymentData: IPayment): Observable<ICreatePaymentResponse> {
        const apiurl = `${this.apiServer.basepath}/payments`;
        //console.log("url: "+apiurl+" paymentData: amount: "+ paymentData.amount_money.amount+" orderid: "+paymentData.order_id+" sourceid: "+paymentData.source_id+" location: "+paymentData.location_id);
        return this.http.post<ICreatePaymentResponse>(apiurl, paymentData).pipe(
            map((res: any) => {
                return res;
            }));
    }
}