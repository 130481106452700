import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ISchedule } from 'src/app/models/schedule.model';
import { ShopService } from 'src/app/services/shop.service';

import { AuthenticationService, RouteService } from '../../services';

@Component({
  selector: 'home',
  templateUrl: '../../views/home/home.component.html',
  styleUrls: ['../../../assets/css//home/home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  currentUser: any;
  trendingItems: any[] = [
    { price: '$9.99', imageName: 't1.png', description: 'Argan, Lavender and Lemongrass Soap - 100g' },
    { price: '$17.99', imageName: 't2.png', description: 'Personalise Engraved Circle Necklace in Silver...' },
    { price: '$29.99', imageName: 't3.png', description: 'Celestial Theme Zircon Fancy Earrings' },
    { price: '$9.99', imageName: 't4.png', description: 'Replenishing Biotin Infused Conditioner - 75ml' },
  ];

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    public routeService: RouteService,
    private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
