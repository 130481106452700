<div class="stream-list-page-wrapper">
    <div class="bg-f5f5f5 w-100 p-30 pl-sm-20 pr-sm-20 p-20-sm">
        <div class="d-flex-row w-100">
            <h1 class="h1-dark fs-22">Streams</h1>
            <button (click)="openCreateNewStreamDialog()" mat-raised-button color="primary" class="ml-auto-i mr-15">Create Stream</button>
        </div>
        <div class="d-inline-block d-flex-col-sm w-100 mt-20-i create-stream-res">
            <mat-form-field class="mr-10" appearance="outline">
                <input type="search" matInput placeholder="Search" [(ngModel)]="searchKeyword" (keyup)="filterStreamList()">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-form-field class="mr-10 mw-150px" appearance="outline">
                <mat-label>Product Type</mat-label>
                <mat-select (selectionChange)="filterStreamList()" [(ngModel)]="streamType">
                    <mat-option value="-1">All</mat-option>
                    <mat-option value="showcase">Showcase Product</mat-option>
                    <mat-option value="exclusive">Exclusive Session</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mr-10 mw-150px" appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select (selectionChange)="filterStreamList()" [(ngModel)]="filterStatus">
                    <mat-option value="-1">All</mat-option>
                    <mat-option value="0">Scheduled</mat-option>
                    <mat-option value="1">On Air</mat-option>
                    <mat-option value="2">Completed</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mr-10 mw-150px" appearance="outline">
                <mat-label>From</mat-label>
                <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="dateFrom" (dateChange)="filterStreamList()">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="mr-10 mw-150px" appearance="outline">
                <mat-label>To</mat-label>
                <input matInput [matDatepicker]="pickerTo" [(ngModel)]="dateTo" (dateChange)="filterStreamList()">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
            <mat-button-toggle-group class="ml-auto float-right" value="list">
                <mat-button-toggle value="list">List View</mat-button-toggle>
                <mat-button-toggle value="week">Week View</mat-button-toggle>
                <mat-button-toggle value="month">Month View</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div class="d-inline-block w-100-i">
            <div class="responsive-table">
                <table id="streamItems" class="w-100">
                    <thead>
                        <tr>
                            <th class="pl-10-i">&nbsp;</th>
                            <th class="pl-10-i">Stream</th>
                            <th class="pl-10-i">Products</th>
                            <th class="pl-10-i">Duration</th>
                            <th class="pl-10-i">Status</th>
                            <th class="pl-10-i">Scheduled</th>
                            <th class="pl-10-i">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of tableDatasource">
                            <td><img src="{{formatImageUrl(row.streamThumbnailUrl)}}" class="responsive-image mxw-52px mh-64px h-52px w-64px b-s1 brds-2px bc-00004"></td>
                            <td class="link-primary">
                                <a href="/store/{{domain}}/sessions/{{row.id}}" class="link-primary">
                                    {{row.title}}
                                </a>
                            </td>
                            <td class="text-0006 fs-14 lh-24">
                                <products-col-in-schedule-list *ngIf="row.type=='showcase'" [ProductIds]="row.productIds">
                                </products-col-in-schedule-list>
                                <span *ngIf="row.type=='exclusive'">Exclusive Session</span>
                            </td>
                            <td class="text-0006 fs-14 lh-24">{{parseDuration(row.startTime,row.endTime)}}</td>
                            <td>
                                <span *ngIf="hasInProgressStatus(row.status)" class="bg-primary fs-10 fw-500 brds-50px text-white ff-roboto p-2px-8px">{{parseStatus(row.status)}}</span>
                                <span *ngIf="hasClosedStatus(row.status)" class="bg-00943C fs-10 fw-500 brds-50px text-white ff-roboto p-2px-8px">{{parseStatus(row.status)}}</span>
                                <span *ngIf="hasScheduledStatus(row.status)" class="bg-FF8F00 fs-10 fw-500 brds-50px text-white ff-roboto p-2px-8px">{{parseStatus(row.status)}}</span>
                            </td>
                            <td class="text-0006 fs-14 lh-24">{{row.startTime | date : 'MMM dd, hh:mma'}}</td>
                            <td>
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="openSelectedStream(row.id)">Open</button>
                                </mat-menu>
                            </td>
                        </tr>
                        <tr class="mat-row" *ngIf="tableDatasource?.length === 0 && !streamLoading">
                            <td class="mat-cell" colspan="9999">
                                No data found!
                            </td>
                        </tr>
                        <tr class="mat-row" *ngIf="streamLoading">
                            <td class="mat-cell" colspan="9999">
                                <img src="../../../assets/images/ajax-loader.gif" alt="ajax-loader">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator (page)="pageChanged($event)" [length]="streamList.length" [pageSize]="tablePageSize" [pageSizeOptions]="[2, 5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>