import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IDeliveryFee } from '../models/delivery-fee.model';
import { IExclusiveSession } from '../models/exclusive-session.model';
import { IItem } from '../models/item.model';
import { ILiveDemonstrationSchedule } from '../models/live-demonstration-schedule.model';
import { IPickedForYouSchedule } from '../models/picked-for-u-schedule.model';
import { IRecentlyAiredSchedule } from '../models/recently-aired-items.model';
import { IRetailerInfo } from '../models/retailer-info.model';
import { ISchedule } from '../models/schedule.model';
import { IShopListItem } from '../models/shop-list-item.model';
import { ISpecialStream } from '../models/special-stream.model';
import { IUpcomingSchedule } from '../models/up-coming-items.model';
import { IUserInfo } from '../models/user-info.model';

@Injectable({
    providedIn: 'root'
})
export class ShopService {

    protected apiServer = AppConfig.settings.apiServer;
    protected localStorageKeys = AppConfig.settings.localStorage.keys;

    constructor(private http: HttpClient) { }

    allShops(): Observable<IShopListItem[]> {
        const apiUrl = `${this.apiServer.basepath}/shops/all`;
        return this.http.get(apiUrl).pipe(
            map((shopList: IShopListItem[]) => {
                return shopList;
            }));
    }

    newInShops(): Observable<IShopListItem[]> {
        const apiUrl = `${this.apiServer.basepath}/shops/new-arrivals`;
        return this.http.get(apiUrl).pipe(
            map((shopList: IShopListItem[]) => {
                return shopList;
            }));
    }

    allSchedulesByDomain(domain: string): Observable<ISchedule[]> {
        const apiUrl = `${this.apiServer.basepath}/shops/${domain}/schedules`;
        return this.http.get(apiUrl).pipe(
            map((scheduleList: ISchedule[]) => {
                return scheduleList;
            }));
    }

    scheduleByScheduleId(scheduleId: string): Observable<ISchedule> {
        const apiUrl = `${this.apiServer.basepath}/shops/schedules/${scheduleId}`;
        return this.http.get(apiUrl).pipe(
            map((scheduleList: ISchedule) => {
                return scheduleList;
            }));
    }

    getScheduleStatusByScheduleId(scheduleId: string): Observable<any> {
        const apiUrl = `${this.apiServer.basepath}/shops/schedules/${scheduleId}/status`;
        return this.http.get(apiUrl).pipe(
            map((res: any) => {
                return res;
            }));
    }

    showcaseProductsByScheuleId(scheduleId: string): Observable<IItem[]> {
        const apiUrl = `${this.apiServer.basepath}/shops/${scheduleId}/items`;
        return this.http.get(apiUrl).pipe(
            map((res: any) => {
                return res.items;
            }));
    }

    allSchedulesInLiveDemonstration(itemLength?: number): Observable<ILiveDemonstrationSchedule[]> {
        var apiUrl = `${this.apiServer.basepath}/shops/schedules/live-demonstration`;
        if (itemLength) apiUrl += `?item_length=${itemLength}`;

        return this.http.get(apiUrl).pipe(
            map((scheduleList: ILiveDemonstrationSchedule[]) => {
                return scheduleList;
            }));
    }

    allSchedulesInPickedForYou(): Observable<IPickedForYouSchedule[]> {
        const apiUrl = `${this.apiServer.basepath}/shops/schedules/picked-for-you`;
        return this.http.get(apiUrl).pipe(
            map((scheduleList: IPickedForYouSchedule[]) => {
                return scheduleList;
            }));
    }

    allSchedulesInRecentlyAired(): Observable<IRecentlyAiredSchedule[]> {
        const apiUrl = `${this.apiServer.basepath}/shops/schedules/recently-aired`;
        return this.http.get(apiUrl).pipe(
            map((scheduleList: IRecentlyAiredSchedule[]) => {
                return scheduleList;
            }));
    }

    allSchedulesInRecentlyViewed(userId: string): Observable<any[]> {
        const apiUrl = `${this.apiServer.basepath}/users/${userId}/recently-viewed`;
        return this.http.get(apiUrl).pipe(
            map((scheduleList: any[]) => {
                return scheduleList;
            }));
    }

    allSchedulesInRecentlyAiredByDomain(domain: string): Observable<IRecentlyAiredSchedule[]> {
        const apiUrl = `${this.apiServer.basepath}/shops/${domain}/schedules/recently-aired`;
        return this.http.get(apiUrl).pipe(
            map((scheduleList: IRecentlyAiredSchedule[]) => {
                return scheduleList;
            }));
    }

    allSchedulesInUpcomingByDomain(domain: string): Observable<IUpcomingSchedule[]> {
        const apiUrl = `${this.apiServer.basepath}/shops/${domain}/schedules/upcoming`;
        return this.http.get(apiUrl).pipe(
            map((scheduleList: IUpcomingSchedule[]) => {
                return scheduleList;
            }));
    }

    loadDeliveryFeeInfo(domain: string): Observable<IDeliveryFee> {
        const apiUrl = `${this.apiServer.basepath}/shops/${domain}/service-charges/delivery-fee`;
        return this.http.get(apiUrl).pipe(
            map((scheduleList: IDeliveryFee) => {
                return scheduleList;
            }));
    }

    loadRetailerInfoByDomain(domain: string): Observable<IRetailerInfo> {
        const apiUrl = `${this.apiServer.basepath}/shops/${domain}/retailer-details`;
        return this.http.get(apiUrl).pipe(
            map((info: IRetailerInfo) => {
                return info;
            }));
    }

    loadSpecialStream(itemLength?: number): Observable<ISpecialStream[]> {

        var apiUrl = `${this.apiServer.basepath}/shops/special-streams`;
        if (itemLength) apiUrl += `?item_length=${itemLength}`;

        return this.http.get(apiUrl).pipe(
            map((streams: ISpecialStream[]) => {
                return streams;
            }));
    }

    loadALLOtherExclusiveStreamByStreamId(streamId: string): Observable<IExclusiveSession[]> {
        var apiUrl = `${this.apiServer.basepath}/shops/schedules/${streamId}/other-exclusive-sessions`;

        return this.http.get(apiUrl).pipe(
            map((streams: IExclusiveSession[]) => {
                return streams;
            }));
    }

    retailerLocationIdByDomain(domain: string): Observable<any> {
        var apiUrl = `${this.apiServer.basepath}/shops/${domain}/retailer-location-id`;

        return this.http.get(apiUrl).pipe(
            map((res: any) => {
                return res;
            }));
    }
}