import { Component, OnInit } from '@angular/core';
import { HOME_ROUTE } from 'src/app/app.config';

@Component({
  selector: 'app-brand',
  templateUrl: '../../views/header/brand.component.html',
  styleUrls: ['../../../assets/css/header/brand.component.css'],
})
export class BrandComponent implements OnInit {
  homeRoute: string;

  constructor() {
    this.homeRoute = HOME_ROUTE;
  }

  ngOnInit(): void {}
}
