import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { ISchedule } from 'src/app/models/schedule.model';
import { AuthenticationService } from '../authentication.service';
import { ScheduleService } from '../schedule.service';
import { ShopService } from '../shop.service';

@Injectable({
    providedIn: 'root'
})
export class SessionComponentService {

    isConsumerView:boolean;
    isRetailerView:boolean;
    
    storeName: EventEmitter<string> = new EventEmitter();

    sessionId: EventEmitter<string> = new EventEmitter();
    sessionCreatorId: EventEmitter<string> = new EventEmitter();
    session: EventEmitter<ISchedule> = new EventEmitter();
    status: EventEmitter<any> = new EventEmitter();
    sessionTitle: EventEmitter<string> = new EventEmitter();
    sessionDuration: EventEmitter<string> = new EventEmitter();
    sessionStartTime:EventEmitter<string> = new EventEmitter();
    sessionStartTimeLiteral: EventEmitter<string> = new EventEmitter();
    
    subscriptionRequired: EventEmitter<boolean> = new EventEmitter();
    isExclusive: EventEmitter<boolean> = new EventEmitter();
    conversationEnabled: EventEmitter<boolean> = new EventEmitter();

    recentlyAiredSessions: EventEmitter<any[]> = new EventEmitter();
    upcommingSessions: EventEmitter<any[]> = new EventEmitter();
    otherExclusiveSession: EventEmitter<any[]> = new EventEmitter();

    configureConversationContentPlacement: EventEmitter<boolean> = new EventEmitter();
    startStream: EventEmitter<boolean> = new EventEmitter();
    stopStream: EventEmitter<boolean> = new EventEmitter();
    openSubscriptionDialog: EventEmitter<boolean> = new EventEmitter();

    publisingStream: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private authService: AuthenticationService,
        private scheduleService: ScheduleService,
        private activatedRoute: ActivatedRoute,
        private shopService: ShopService) { }

    public getScheduleStatus(scheduleId: string): Observable<any> {
        return this.shopService.getScheduleStatusByScheduleId(scheduleId);
    }
}