import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { StoreDirective } from 'src/app/directives/store.directive';
import { RoleEnum } from 'src/app/enums/role.enum';
import { AuthenticationService } from 'src/app/services';
import { ConsumerViewStoreComponent } from '../shops/consumer-view.component.store';
import { ShopsStoreFrontComponent } from '../shops/shops-store-front.component';
import { RetailerViewStoreComponent } from './retailer-view.store.component';

@Component({
    selector: 'app-store',
    templateUrl: '../../views/store/store.component.html'
})
export class StoreComponent implements OnInit, OnDestroy {

    @ViewChild(StoreDirective, { static: true }) storeHost!: StoreDirective;

    shopsStoreFrontComponent: any = ShopsStoreFrontComponent;

    retailerViewStore: any = RetailerViewStoreComponent;
    consumerViewStore: any = ConsumerViewStoreComponent;

    urlSegments: string[];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private authenticationService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private router: Router) {
        this.urlSegments = this.router.url.split('/').filter(function (item) { return item; });
    }

    ngOnInit(): void {

        if (this.urlSegments.length > 2) {

            if (this.authenticationService.currentUserRole === RoleEnum.retailer) {
                this.loadRetailerStoreComponent();
            }
            else if (this.authenticationService.currentUserRole === RoleEnum.consumer) {
                this.loadConsumerStoreComponent();
            }
        }
        else {
            const rootSegment = this.urlSegments[0];
            if (rootSegment === "shops") {
                this.loadShopsStoreFrontComponent();
            }
        }
    }
    ngOnDestroy(): void {
    }

    loadShopsStoreFrontComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.shopsStoreFrontComponent);

        const viewContainerRef = this.storeHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<any>(componentFactory);
    }

    loadRetailerStoreComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.retailerViewStore);

        const viewContainerRef = this.storeHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<any>(componentFactory);
    }

    loadConsumerStoreComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.consumerViewStore);

        const viewContainerRef = this.storeHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<any>(componentFactory);
    }

}