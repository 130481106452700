<div class="mw-580px-i mnw-320px">
  <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">Add Image</h1>
  <div mat-dialog-content>
    <form
      name="imageForm"
      [formGroup]="imageForm"
      (ngSubmit)="onSubmit()"
      method="post"
    >
      <div class="pb-10 pt-10">
        <div class="pb-10 pt-10">
          <div class="form-item mt-25">
            <div class="row d-block">
              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Name</mat-label>
                  <input
                    matInput
                    placeholder="Image Name"
                    [formControl]="name"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-12">
                <input
                  id="catalogImage"
                  class="mat-stroke-button-file-input"
                  type="file"
                  (change)="onCatalogImageChange($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions class="pstn-relative">
          <mat-spinner
            diameter="25"
            class="color-primary pstn-absolute t24 l4"
            [class.hidden]="!inProgress"
          >
          </mat-spinner>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="tt-upper"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
