<mat-card class="m-0-i d-flex-col flex-auto p-0-i slider-card-item">
  <a class="d-flex pstn-relative m-0-i w-100-i lb-card-img-contaier" href="#">
    <img class="mb-0-i lb-card-img" src="./assets/images/{{imageName}}">
  </a>
  <mat-card-content class="mb-50-i p-15-i">
    <p class="p3-dark desc">{{description}}</p>
  </mat-card-content>
  <mat-card-footer class="p-15-i d-block of-hidden w-100 ml-0-i pstn-absolute-b16">
    <h3 class="h3-primary name"><a class="text-primary">{{price}}</a></h3>
  </mat-card-footer>
</mat-card>