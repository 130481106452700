

export abstract class ArrayUtils {
    public static containsValue = function (
        arrOjb: any[],
        atributeKey: string,
        atributeValue: string,
        propertyKey: string,
        propertyValue: string) {

        var hasMatching: boolean = false;
        var index;

        for (index = 0; index < arrOjb.length; index++) {
            const element = arrOjb[index];
            if (element[atributeKey] == atributeValue) {
                if (element[propertyKey] == propertyValue) {
                    hasMatching = true;
                    break;
                }
            }
        }

        return hasMatching;
    }

    public static findVariationIdByColorSize = function (variations: any[], color: string, size: string) {

        var variationId;

        var index;
        for (index = 0; index < variations.length; index++) {
            const element = variations[index];
            var hasColorMatched: boolean = false;
            var hasSizeMatched: boolean = false;

            if (element.options && element.options.length) {
                var optIndex;
                for (optIndex = 0; optIndex < element.options.length; optIndex++) {

                    const opt = element.options[optIndex];

                    if (opt["attribute"] == "color") {
                        if (opt["value"] == color) {
                            hasColorMatched = true;
                        }
                    }

                    if (opt["attribute"] == "size") {
                        if (opt["value"] == size) {
                            hasSizeMatched = true;
                        }
                    }
                }
            }

            if (hasColorMatched && hasSizeMatched) {
                variationId = element.id;
                break;
            }
        }

        return variationId;
    }
}