import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'retailer-store-header-nav',
  templateUrl: '../../views/header/retailer-store-header-nav.component.html',
  styleUrls: ['../../../assets/css/header/retailer-store-header-nav.component.css']
})
export class RetailerStoreHeaderNavComponent implements OnInit {

  activeRoute: string;
  retailerDomain: string;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    const segments: string[] = this.router.url.split('/').filter(function (x) { return x; });
    if (segments.length === 4) {
      this.activeRoute = segments[2].toLowerCase();
    } else {
      this.activeRoute = segments.pop().toLowerCase();
    }

    this.retailerDomain = this.authenticationService.retailerDomain;
  }
}
