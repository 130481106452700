<div class="auth-menu-container ff-roboto" *ngIf="!isAuthenticated">
  <a mat-button class="fs-16 lh-24 fw-400 hidden-sm" routerLink="/login"
    >Log in</a
  >
  <a mat-button class="fs-16 lh-24 fw-400 hidden-sm" routerLink="/register"
    >Register</a
  >
  <div class="hidden d-block-sm">
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <a mat-menu-item class="fs-16 lh-24 fw-400" routerLink="/login">Log in</a>
      <a mat-menu-item class="fs-16 lh-24 fw-400" routerLink="/register"
        >Register</a
      >
    </mat-menu>
  </div>
</div>
<div class="auth-menu-container ff-roboto" *ngIf="isAuthenticated">
  <mat-icon
    *ngIf="isCreator || isRetailer"
    [ngClass]="{ 'text-primary': unseenNotificationsCount !== 0 }"
    class="text-primary ml-10 pstn-relative header-icon"
    [matMenuTriggerFor]="notification"
    (menuOpened)="onMenuOpened()"
    (menuClosed)="onMenuClosed()"
    >notifications</mat-icon
  >
  <mat-icon
    *ngIf="isCreator || isRetailer"
    [ngClass]="{ 'text-primary': unseenConversationCount !== 0 }"
    class="ml-10 pstn-relative header-icon"
    (click)="openMessageContainer()"
    >message</mat-icon
  >
  <button mat-button [matMenuTriggerFor]="menu" class="menu-button">
    <span class="tt-upper ml-10 hidden-sm"
      >{{ currentUser.firstName }} {{ currentUser.lastName }}</span
    >
    <mat-icon
      *ngIf="!currentUser.profilePictureUrl && !currentUser.logoUrl"
      class="ml-10"
      >account_circle</mat-icon
    >
    <img
      *ngIf="currentUser.profilePictureUrl || currentUser.logoUrl"
      class="profile-avatar"
      src="/api{{ currentUser.profilePictureUrl || currentUser.logoUrl }}"
      onerror="this.src='../../../assets/images/default-profile-pic.jpg'"
    />
    <mat-icon class="hidden d-inherit-sm">expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a mat-menu-item class="fs-16 lh-24 fw-400" routerLink="{{ homeRoute }}"
      >Home</a
    >
    <a mat-menu-item class="fs-16 lh-24 fw-400" routerLink="/account"
      >My Account</a
    >
    <a
      *ngIf="isRetailer"
      mat-menu-item
      class="fs-16 lh-24 fw-400"
      routerLink="/store/{{ userDomain }}/sessions"
      >My Streams</a
    >
    <a
      *ngIf="isRetailer"
      mat-menu-item
      class="fs-16 lh-24 fw-400"
      routerLink="/store/{{ userDomain }}/orders"
      >My Orders</a
    >
    <a
      *ngIf="isRetailer"
      mat-menu-item
      class="fs-16 lh-24 fw-400"
      routerLink="/store/{{ userDomain }}/items"
      >My Products</a
    >

    <a
      *ngIf="!isConsumer"
      mat-menu-item
      class="fs-16 lh-24 fw-400"
      routerLink="/account/subscriptions"
      >My Subscriptions</a
    >
    <a
      *ngIf="isRetailer"
      mat-menu-item
      class="fs-16 lh-24 fw-400"
      routerLink="/account/{{ userDomain }}/subscriptions"
      >My Subscriptions</a
    >

    <a
      *ngIf="isRetailer"
      mat-menu-item
      class="fs-16 lh-24 fw-400"
      routerLink="/store/{{ userDomain }}/subscribers"
      >My Subscribers</a
    >
    <a
      *ngIf="isConsumer"
      mat-menu-item
      class="fs-16 lh-24 fw-400"
      href="/account/orders"
      >My Orders</a
    >
    <a
      *ngIf="isCreator"
      mat-menu-item
      class="fs-16 lh-24 fw-400"
      href="/account/projects"
      >Projects</a
    >
    <!-- <a mat-menu-item class="fs-16 lh-24 fw-400">My Recomendations</a>
    <a mat-menu-item class="fs-16 lh-24 fw-400">Watch History</a> -->
    <mat-divider></mat-divider>
    <a mat-menu-item class="fs-16 lh-24 fw-400" routerLink="/logout">Log out</a>
  </mat-menu>
  <mat-menu #notification="matMenu">
    <div style="max-height: 300px">
      <!-- <mat-spinner
        diameter="25"
        class="color-primary"
        [class.hidden]="!isFetchingNotification"
      >
      </mat-spinner> -->
      <notification-list
        *ngIf="showNotificationList"
        [notificationList]="notificationList"
      ></notification-list>
    </div>
  </mat-menu>
</div>
