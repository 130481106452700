<div class="mb-15 d-flex flex-wrap lh-28" *ngIf="!isRetailerView">
    <a href="/" class="text-primary fs-14 fw-500 mr-5">Shops</a>
    <mat-icon class="text-0006 lh-unset mr-10">chevron_right</mat-icon>
    <a href="/shops/{{domain}}/" class="text-primary fs-14 fw-500">{{domain}}</a>
    <mat-icon class="text-0006 lh-unset mr-10">chevron_right</mat-icon>
    <a class="text-0006 fs-14 fw-400 mr-5 crsr-pointer">{{sessionTitle}}</a>
</div>
<div class="mb-15 d-flex flex-wrap lh-28" *ngIf="isRetailerView">
    <a href="/store/{{domain}}/sessions" class="text-primary fs-14 fw-500 mr-5">Sessions</a>
    <mat-icon class="text-0006 lh-unset mr-10">chevron_right</mat-icon>
    <a class="text-0006 fs-14 fw-400 mr-5 crsr-pointer">{{sessionTitle}}</a>
</div>