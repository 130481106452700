import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { BrowserUtil } from 'src/app/helpers/browser-util';
import { StringUtils } from 'src/app/helpers/stringUtils/string.utils';
import {
  IConversation,
  IConversationUser,
} from 'src/app/models/conversession.model';
import { AuthenticationService } from 'src/app/services';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';
import { ConversessionService } from 'src/app/services/conversession.service';

@Component({
  selector: 'session-coversation',
  templateUrl: '../../views/sessions/session-coversation.component.html',
  styleUrls: ['../../../assets/css/common/stream-chatting.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class StreamChattingComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  sessionCreatorId: string;
  isExclusive: boolean = false;
  isAuthorized: boolean = false;
  subscriptionRequired: boolean = true;
  conversationEnabled: boolean = true;
  onAir: boolean;
  isCompleted: boolean;

  sessionId: string;

  conversationList: IConversation[] = [];
  postCommentContent: string;
  postingComment: boolean = false;

  commentForm: FormGroup;
  commentContent = new FormControl('', [Validators.required]);

  chattingContentHeightConfigured: boolean = false;

  subscriptions = new Subscription();

  constructor(
    @Inject(DOCUMENT) document,
    private formBuilder: FormBuilder,
    private conversessionService: ConversessionService,
    private authenticationService: AuthenticationService,
    private sessionManager: SessionComponentService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.commentForm = this.formBuilder.group({
      commentContent: this.commentContent,
    });
    this.subscriptions.add(
      this.authenticationService.currentUser.subscribe((x) => {
        if (x) {
          this.isAuthorized = true;
        } else {
          this.isAuthorized = false;
        }
      })
    );

    this.subscriptions.add(
      this.sessionManager.sessionCreatorId.subscribe((creatorId: string) => {
        this.sessionCreatorId = creatorId;
      })
    );
    this.subscriptions.add(
      this.sessionManager.sessionId.subscribe((id: string) => {
        this.sessionId = id;
        this.subscribeConversationList();
      })
    );

    this.subscriptions.add(
      this.sessionManager.status.subscribe((status: any) => {
        this.onAir = status == StreamStatusEnum.IN_PROGRESS;
        this.isCompleted = status == StreamStatusEnum.CLOSED;
      })
    );
    this.subscriptions.add(
      this.sessionManager.isExclusive.subscribe((isExclusive: boolean) => {
        this.isExclusive = isExclusive;
        if (!isExclusive) {
          this.subscriptionRequired = false;
        }
      })
    );
    this.subscriptions.add(
      this.sessionManager.subscriptionRequired.subscribe(
        (required: boolean) => {
          this.subscriptionRequired = required;
        }
      )
    );

    this.subscriptions.add(
      this.sessionManager.configureConversationContentPlacement.subscribe(
        (configure: boolean) => {
          this.configureChatcontentHeight();
        }
      )
    );
    this.subscriptions.add(
      this.sessionManager.conversationEnabled.subscribe((enabled: boolean) => {
        this.conversationEnabled = enabled;
      })
    );
  }

  subscribeConversationList() {
    this.subscriptions.add(
      interval(5000)
        .pipe(startWith(0))
        .subscribe(() => {
          this.loadConversationList();
          this.sessionManager
            .getScheduleStatus(this.sessionId)
            .toPromise()
            .then((res: any) => {
              this.sessionManager.status.emit(res.status);
              this.sessionManager.conversationEnabled.emit(
                res.isExclusiveSessionChatEnabled
              );
            });
        })
    );
  }

  loadConversationList() {
    this.conversessionService
      .getConversessionsByScheduleId(this.sessionId)
      .toPromise()
      .then((res: IConversation[]) => {
        this.conversationList = res;
        if (!this.chattingContentHeightConfigured) {
          this.configureChatcontentHeight();
        }

        var cvContainer$ = document.getElementById('conversessionContainer');
        cvContainer$.scrollTop = cvContainer$.scrollHeight;
      });
  }

  getAvatarName(fullName: string): string {
    return StringUtils.converToAvatarName(fullName);
  }

  postComment(): void {
    this.postingComment = true;
    if (!this.postCommentContent || this.postCommentContent == '') {
      this.snackBar.open('Type your message', 'Close', {
        duration: 10000,
        panelClass: ['mat-error-bg'],
      });
      this.postingComment = false;
      return;
    }

    const userId = this.authenticationService.currentUserId;
    this.conversessionService
      .postConversationByScheduleIdUserId(
        this.sessionId,
        userId,
        this.postCommentContent
      )
      .toPromise()
      .then((res: IConversation[]) => {
        this.conversationList = res;
        this.postingComment = false;
        this.postCommentContent = '';
      });
  }

  showChatInputDisabledNotification() {
    if (this.isExclusive && !this.conversationEnabled) {
      this.snackBar.open('Chatting is disabled.', 'Close', {
        duration: 10000,
        panelClass: ['mat-error-bg'],
      });
      return;
    }

    if (this.subscriptionRequired) {
      this.snackBar.open(
        'Only the subscribed users will be able to view and participate in the conversation.',
        'Close',
        { duration: 10000, panelClass: ['mat-error-bg'] }
      );
      return;
    } else if (this.isCompleted) {
      this.snackBar.open('Chatting is only enabled till it is live.', 'Close', {
        duration: 10000,
        panelClass: ['mat-error-bg'],
      });
      return;
    }

    this.snackBar.open(
      'Chatting is disabled until live stream starts',
      'Close',
      { duration: 10000, panelClass: ['mat-error-bg'] }
    );
  }

  hasProfilePic(user: IConversationUser): boolean {
    if (user && user.roles.indexOf('RETAILER') > -1) {
      if (user.storeBannerUrl) return true;
    }

    if (user && user.profilePictureUrl) return true;

    return false;
  }

  getProfilePicUrl(user: IConversationUser): string {
    if (user && user.roles.indexOf('RETAILER') > -1) {
      var url = user.storeBannerUrl;
      if (!url || user.storeBannerUrl.indexOf('undefined') > -1) {
        url = user.profilePictureUrl;
      }

      if (url) return user.storeBannerUrl;
    }

    if (
      user &&
      user.profilePictureUrl &&
      user.profilePictureUrl.indexOf('undefined') == -1
    )
      return user.profilePictureUrl;
  }
  isRetailerComment(user: IConversationUser): boolean {
    if (this.sessionCreatorId === user._id) {
      return true;
    }

    return false;
  }
  minimizeChateWindow() {
    var $chatwindowelm = document.getElementById('streamChattingContainer');
    var $minimizedChatIcon = document.getElementById('minimizedChatIcon');
    $chatwindowelm.classList.add('minimized');
    $minimizedChatIcon.classList.remove('hidden');
  }

  maximizeChatWindow() {
    var $chatwindowelm = document.getElementById('streamChattingContainer');
    var $minimizedChatIcon = document.getElementById('minimizedChatIcon');
    $chatwindowelm.classList.remove('minimized');
    $minimizedChatIcon.classList.add('hidden');
  }

  configureChatcontentHeight() {
    this.chattingContentHeightConfigured = true;
    if (BrowserUtil.isMobileBrowser) {
      this.configureChatcontentHeightInMobileBrowser();
      return;
    }

    var checkExist = setInterval(function () {
      var $conversessionFooter = document.getElementById('conversessionFooter');
      $conversessionFooter.style.top = null;
      $conversessionFooter.style.height = null;
      $conversessionFooter.style.width = null;
      $conversessionFooter.style.display = null;
      $conversessionFooter.style.position = null;

      var $sessionStartTimeLiterelContainer = document.getElementById(
        'sessionStartTimeLiterelContainer'
      );

      if ($conversessionFooter && $sessionStartTimeLiterelContainer) {
        clearInterval(checkExist);
        var $streamCard = document.getElementById('streamCard');
        $streamCard.style.marginBottom = null;

        var $conversessionCardHeader = document.getElementById(
          'conversessionCardHeader'
        );

        var $conversessionContainer = document.getElementById(
          'conversessionContainer'
        );
        $conversessionContainer.style.maxHeight = null;
        $conversessionContainer.style.overflow = null;
        $conversessionContainer.style.minHeight = null;
        $conversessionContainer.style.height = null;
        $conversessionContainer.style.width = null;
        $conversessionContainer.style.position = null;
        $conversessionContainer.style.top = null;

        var maxHieght = $streamCard.offsetHeight;
        var conversessionCardHeaderHeight =
          $conversessionCardHeader.offsetHeight;
        var conversessionFooterHeight = $conversessionFooter.offsetHeight;

        var conversessionContainerHeight =
          maxHieght -
          (conversessionCardHeaderHeight + conversessionFooterHeight);
        $conversessionContainer.style.maxHeight = `${conversessionContainerHeight}px`;
        $conversessionContainer.style.overflowY = 'auto';

        if (conversessionContainerHeight <= 500) {
          $conversessionContainer.style.minHeight = `180px`;
        } else {
          $conversessionContainer.style.minHeight = `500px`;
        }

        var cvCard = document.getElementById('conversessionCard');
        cvCard.classList.add('fadeIn');

        var cvContainer$ = document.getElementById('conversessionContainer');
        cvContainer$.scrollTop = cvContainer$.scrollHeight;
      }
    }, 1000);
  }

  configureChatcontentHeightInMobileBrowser() {
    var checkExist = setInterval(function () {
      var cvFooter$ = document.getElementById('conversessionFooter');
      cvFooter$.style.top = null;
      cvFooter$.style.height = null;
      cvFooter$.style.width = null;
      cvFooter$.style.display = null;
      cvFooter$.style.position = null;

      var sessionStartTimeLiterelContainer$ = document.getElementById(
        'sessionStartTimeLiterelContainer'
      );
      if (cvFooter$ && sessionStartTimeLiterelContainer$) {
        clearInterval(checkExist);

        var stmFooter$ = document.getElementById('streamFooter');
        var stmCard$ = document.getElementById('streamCard');
        stmCard$.style.marginBottom = null;

        cvFooter$.style.display = 'table';
        cvFooter$.style.position = 'absolute !important';

        const cvFooterHeight = cvFooter$.offsetHeight;
        const stmFooterOffsetTop = stmFooter$.offsetTop;
        const stmFooterOffsetHeight = stmFooter$.offsetHeight;

        cvFooter$.style.top = `${stmFooterOffsetHeight + stmFooterOffsetTop}px`;
        cvFooter$.style.width = `${stmFooter$.offsetWidth}px`;
        // cvFooter$.style.marginLeft = `-9px`;
        stmCard$.style.marginBottom = `${cvFooterHeight}px`;

        var stmVideoContainer$ = document.getElementById(
          'streamVideoContainer'
        );
        var cvContainer$ = document.getElementById('conversessionContainer');

        const cvContainerHeight = (stmVideoContainer$.offsetHeight / 3) * 2;
        cvContainer$.style.height = `${cvContainerHeight}px`;
        cvContainer$.style.width = `${stmVideoContainer$.offsetWidth}px`;
        // cvContainer$.style.marginLeft = `-9px`;

        const cvContainerTop =
          stmVideoContainer$.offsetHeight +
          stmVideoContainer$.offsetTop -
          (cvContainer$.offsetHeight + 60);
        cvContainer$.style.position = 'absolute';
        cvContainer$.style.top = `${cvContainerTop}px`;
        cvContainer$.style.overflow = 'auto';

        var cvCard = document.getElementById('conversessionCard');
        cvCard.classList.add('fadeIn');
        cvContainer$.scrollTop = cvContainer$.scrollHeight;
      }
    }, 1000); // check every 1000ms
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get windowUrl(): string {
    const returnUrl = (window as any).location.href.replace(
      location.origin,
      ''
    );
    return returnUrl;
  }

  openSubscriptionDialog(): void {
    this.sessionManager.openSubscriptionDialog.emit(true);
  }

  ngAfterViewInit(): void {
    var self = this;
    window.addEventListener(
      'orientationchange',
      function () {
        self.configureChatcontentHeight();
      },
      false
    );

    window.addEventListener('resize', function () {
      self.configureChatcontentHeight();
    });
  }
}
