<div>
    <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">Delete {{data.sessionTitle}}?</h1>
    <div mat-dialog-content>
        <div class="pb-10 pt-10">
            <div class="pb-10 pt-10">
                <div class="form-item">
                    <label class="fs-14 fw-400 lh-16 text-0087 d-block ff-roboto">Are you sure that you want to delete this {{data.sessionTitle}}?</label>
                </div>
            </div>
            <div mat-dialog-actions class="pstn-relative mb-0-i p-0-24-i bt-s1-i bc-00012-i mt-15">
                <mat-spinner diameter="25" class="color-primary pstn-absolute t24 l4" [class.hidden]="!inProgress">
                </mat-spinner>
                <button mat-flat-button class="tt-upper text-primary ml-auto-i mr-20-i" (click)="onCancelClick()">
                    <span>Cancel</span>
                </button>
                <button mat-raised-button color="primary" class="tt-upper ml-0-i" (click)="deleteSession()">
                    <span>Delete</span>
                </button>
            </div>
        </div>
    </div>
</div>