import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IUserInfo } from 'src/app/models/user-info.model';
import { RoleEnum } from 'src/app/enums/role.enum';
import { AppConfig } from 'src/app/app.config';

@Component({
    selector: 'update-profile-dialog',
    templateUrl: '../../views/user/update-profile.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UpdateProfileDialogComponent implements OnInit, OnDestroy {

    profileInfo: IUserInfo;

    profileForm: FormGroup;
    inProgress: boolean = false;

    profilePicture: File;
    storeLogo: File;
    storeBannerImage: File;
    
    firstName: FormControl;
    lastName: FormControl;
    emailAddress: FormControl;

    storeName: FormControl;
    phoneNumber: FormControl;
    addressLine1: FormControl;
    addressLine2: FormControl;
    state: FormControl;
    cityOrTown: FormControl;
    zipCode: FormControl;

    storeLogoPath: string;
    storeBannerPath: string;
    profilePicturePath: string;

    isRetailerAccount: boolean;
    profileImageFileError:string;
    storeBannerImageFileError:string;
    storeLogoImageFileError:string;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<UpdateProfileDialogComponent>,
        public itemService: ItemService,
        public authService: AuthenticationService,
        public accountService: AccountService,
        public retailerStoreService: RetailerStoreService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: IUserInfo) {
        this.profileInfo = data;
    }

    ngOnInit(): void {

        this.isRetailerAccount = this.authService.currentUserRole == RoleEnum.retailer;

        this.firstName = new FormControl(this.profileInfo.firstName, [Validators.required]);
        this.lastName = new FormControl(this.profileInfo.lastName, []);
        this.emailAddress = new FormControl(this.profileInfo.email, [Validators.required]);

        this.storeName = new FormControl(this.profileInfo.storeName, []);
        this.phoneNumber = new FormControl(this.profileInfo.phoneNumber, []);
        this.addressLine1 = new FormControl(this.profileInfo.addressLine1, [Validators.required]);
        this.addressLine2 = new FormControl(this.profileInfo.addressLine2, []);
        this.state = new FormControl(this.profileInfo.state, [Validators.required]);
        this.cityOrTown = new FormControl(this.profileInfo.cityOrTown, [Validators.required]);
        this.zipCode = new FormControl(this.profileInfo.zipCode, [Validators.required, Validators.minLength(4)]);

        this.profileForm = this.formBuilder.group({
            firstName: this.firstName,
            lastName: this.lastName,
            emailAddress: this.emailAddress,
            profilePicture: new FormControl('', []),
            storeLogo: new FormControl('', []),
            storeBannerImage: new FormControl('', []),
            storeName: this.storeName,
            phoneNumber: this.phoneNumber,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            state: this.state,
            cityOrTown: this.cityOrTown,
            zipCode: this.zipCode
        });

    }

    onProfileImageChange($event) {
        this.profileImageFileError = '';
        if ($event.target.files.length > 0) {
            const file = $event.target.files[0];
            const maxSize = AppConfig.settings.appConfig.imageFileMaxSize;
            const fileSize = file.size / 1024;
            
            if (fileSize > maxSize) {
                this.profileImageFileError = `Profile picture file size can not exceed more than ${maxSize}KB`;
                return;
            }

            this.profilePicture = file;
        }
    }

    onStoreLogoImageChange($event) {
        this.storeLogoImageFileError = '';
        if ($event.target.files.length > 0) {
            const file = $event.target.files[0];
            
            const maxSize = AppConfig.settings.appConfig.imageFileMaxSize;
            const fileSize = file.size / 1024;
            
            if (fileSize > maxSize) {
                this.storeLogoImageFileError = `Store logo image file size can not exceed more than ${maxSize}KB`;
                return;
            }

            this.storeLogo = file;
        }
    }

    onStoreBannerImageChange($event) {
        this.storeBannerImageFileError = '';
        if ($event.target.files.length > 0) {
            const file = $event.target.files[0];

            const maxSize = AppConfig.settings.appConfig.imageFileMaxSize;
            const fileSize = file.size / 1024;
            
            if (fileSize > maxSize) {
                this.storeBannerImageFileError = `Store banner image file size can not exceed more than ${maxSize}KB`;
                return;
            }

            this.storeBannerImage = file;
        }
    }

    onSubmit() {
        if (this.profileForm.invalid) {
            return;
        }

        this.inProgress = true;

        const userId = this.authService.currentUserId;
        const formData = new FormData();

        if (this.profilePicture) {
            formData.append('profilePicture', this.profilePicture);
            this.profileImageFileError = '';
        }

        if (this.storeLogo) {
            formData.append('storeLogo', this.storeLogo);
            this.storeLogoImageFileError = '';
        }

        if (this.storeBannerImage) {
            formData.append('bannerImage', this.storeBannerImage);
            this.storeBannerImageFileError = '';
        }

        formData.append('firstName', this.firstName.value);
        formData.append('lastName', this.lastName.value);

        if (this.isRetailerAccount) {
            formData.append('storeName', this.storeName.value);
        }

        formData.append('phoneNumber', this.phoneNumber.value);
        formData.append('addressLine1', this.addressLine1.value);
        formData.append('addressLine2', this.addressLine2.value);
        formData.append('state', this.state.value);
        formData.append('cityOrTown', this.cityOrTown.value);
        formData.append('zipCode', this.zipCode.value);

        this.retailerStoreService.updateStoreInformation(userId, formData)
            .toPromise()
            .then((res: any) => {
                this.inProgress = false;
                if (this.isRetailerAccount) {
                    this.authService.updateStoreLogoUrlInLocalStorage(res.logoUrl);
                } else {
                    this.authService.updateUserProfilePictureUrlInLocalStorage(res.profilePictureUrl);
                }

                this.snackBar.open('Profile information updated', 'Close', { duration: 10000, panelClass: ['mat-success-bg'] });
                this.dialogRef.close();
            })
            .catch((err: HttpErrorResponse) => {
                this.inProgress = false;
                const errorDetail = err.error.error || err.error;
                this.snackBar.open(errorDetail, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
            });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
    }
}
