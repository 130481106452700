import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'delete-item-dialog',
  templateUrl: '../../views/item/add-image.dialog.component.html',
})
export class AddImageDialogComponent implements OnInit {
  imageForm: FormGroup;
  inProgress: boolean = false;

  catalogImage: File;
  name = new FormControl('', [Validators.required]);

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddImageDialogComponent>,
    public itemService: ItemService,
    private snackBar: MatSnackBar
  ) {
    this.imageForm = this.formBuilder.group({
      catalogImage: new FormControl('', []),
      name: this.name,
    });
  }

  ngOnInit(): void {}

  onCatalogImageChange($event) {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      this.catalogImage = file;
    }
  }

  onSubmit() {
    if (this.imageForm.invalid) {
      return;
    }

    this.inProgress = true;

    const formData = new FormData();
    if (this.catalogImage) {
      formData.append('catalogImage', this.catalogImage);
    }

    formData.append('name', this.name.value);

    // this.itemService
    //   .createImage(formData)
    //   .toPromise()
    //   .then((res: any) => {
    //     this.inProgress = false;
    //     this.snackBar.open('Image created', 'Close', {
    //       duration: 10000,
    //       panelClass: ['mat-success-bg'],
    //     });
    //     this.dialogRef.close();
    //   })
    //   .catch((err: HttpErrorResponse) => {
    //     this.inProgress = false;
    //     const errorDetail = err.error.error || err.error;
    //     this.snackBar.open(errorDetail, 'Close', {
    //       duration: 10000,
    //       panelClass: ['mat-error-bg'],
    //     });
    //   });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
