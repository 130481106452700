import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { ISchedule } from 'src/app/models/schedule.model';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';

@Component({
    selector: 'session-card-footer',
    templateUrl: '../../../views/sessions/card/session-stream-card-footer.component.html'
})
export class SessionStreamCardFooterComponent implements OnInit, OnDestroy {

    isExclusive: boolean;
    onAir: boolean;
    session: ISchedule;
    storeName: string;
    isRetailer: boolean;
    sessionStartTimeLiteral: string;

    subscription: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private sessionManager: SessionComponentService) { }

    ngOnInit(): void {
        
        if (this.sessionManager.isRetailerView){
            this.storeName = this.activatedRoute.parent.snapshot.paramMap.get('domain');
            this.isRetailer = true;
        }
        else {
            this.storeName = this.activatedRoute.parent.snapshot.paramMap.get('storename');
            this.isRetailer = false;
        }

        this.subscription.add(this.sessionManager.session.subscribe((session: ISchedule) => { this.session = session; }));
        this.subscription.add(this.sessionManager.isExclusive.subscribe((isExclusive: boolean) => { this.isExclusive = isExclusive; }));
        this.subscription.add(this.sessionManager.status.subscribe((status: any) => { this.onAir = status == StreamStatusEnum.IN_PROGRESS; }));
        this.subscription.add(this.sessionManager.sessionStartTimeLiteral.subscribe((res: string) => { this.sessionStartTimeLiteral = res; }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}