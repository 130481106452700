import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'checkout-success',
  templateUrl: '../../views/shops/checkout-failed.shops.component.html',
  styleUrls: ['../../../assets/css/shops/checkout.shops.component.css']
})
export class CheckoutFailedComponent implements OnInit {

  constructor(
    private session: SessionService) { }

  ngOnInit(): void {
  }

}