<div class="stream-list-page-wrapper">
    <div class="bg-f5f5f5 w-100 p-30 pl-sm-20 pr-sm-20 p-20-sm">
        <div class="d-flex-row w-100 pstn-relative">
            <h1 class="h1-dark fs-22">Orders</h1>
            <mat-spinner diameter="20" class="color-primary pstn-absolute r10"
                                            [class.hidden]="!loading"></mat-spinner>
        </div>
        <div class="d-inline-block d-flex-col-sm w-100 mt-20-i">
            <mat-form-field class="mr-10 mnw-320px" appearance="outline">
                <input type="search" matInput placeholder="Search" [(ngModel)]="searchKeyword"
                    (keyup)="filterOrderListItems()">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-form-field class="mr-10 mw-150px" appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select (selectionChange)="filterOrderListItems()" [(ngModel)]="filterStatus">
                    <mat-option value="-1">All</mat-option>
                    <mat-option value="0">Scheduled</mat-option>
                    <mat-option value="1">On Air</mat-option>
                    <mat-option value="2">Completed</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-inline-block w-100-i">
            <div class="responsive-table">
                <table id="streamItems" class="w-100">
                    <thead>
                        <tr>
                            <th class="pl-10-i">Order ID</th>
                            <th class="pl-10-i">Type</th>
                            <th class="pl-10-i">Order Date</th>
                            <th class="pl-10-i">Customer</th>
                            <th class="pl-10-i">Status</th>
                            <th class="pl-10-i">Fulfillment Date</th>
                            <th class="pl-10-i">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="fs-14 fw-500 lh-24 text-0087" *ngFor="let row of tableDatasource">
                            <td>
                                <a class="text-primary crsr-pointer" href="/store/{{domain}}/orders/{{row.order_id}}"># {{row.order_id}}</a>
                            </td>
                            <td class="tt-capitalize">{{row.type}}</td>
                            <td>{{row.orderDate | date : 'MMM dd, yyyy'}}</td>
                            <td class="tt-capitalize">{{row.customer}}</td>
                            <td class="fs-12">
                                <span 
                                    [class.text-0088FF]="row.status.toLowerCase()=='new'" 
                                    [class.text-FF8F00]="row.status.toLowerCase()=='in progres'"
                                    [class.text-00C14E]="row.status.toLowerCase()=='ready'"
                                    [class.text-707070]="row.status.toLowerCase()=='completed'">{{row.status}}</span>
                            </td>
                            <td>{{row.fullFillmentDate | date : 'MMM dd, yyyy'}}</td>
                            <td>
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="more action">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <a mat-menu-item href="/store/{{domain}}/orders/{{row.order_id}}">View Details</a>
                                </mat-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator (page)="pageChanged($event)" [length]="orderListItems.length" [pageSize]="tablePageSize"
                [pageSizeOptions]="[2, 5, 10, 25, 100]"></mat-paginator>
        </div>
    </div>
</div>