

export abstract class StringUtils {
    public static trimAllWhitespace = function (text: string): string {
        return text.replace(/\s/g, "");
    };

    public static capitalize = function (text: string): string {
        return text.replace(/\b\w/g, l => l.toUpperCase());
    }

    public static converToAvatarName = function(fullName: string): string {
        const name = fullName.split(' ');
        const initials = name.shift().charAt(0) + name.pop().charAt(0);
        return initials.toUpperCase();
    }
}