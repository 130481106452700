<div class="d-flex-col w-100 p-20 pl-sm-20 pr-sm-20 p-20-sm">
  <app-ssn-brdcrmb></app-ssn-brdcrmb>
  <div class="d-flex-row flex-1 d-block-sm pstn-relative">
    <session-card class="w-70 mr-10 w-100-sm"></session-card>
    <session-coversation
      id="streamChattingContainer"
      class="w-30 ml-10 d-flex-row w-100-sm d-block-sm ml-0-sm"
    ></session-coversation>
  </div>
</div>

<showcased-products
  class="d-block w-100 p-20 pl-sm-20 pr-sm-20 p-20-sm pt-10 mat-elevation-z1"
  *ngIf="stream?.id && !isExclusive"
  [streamId]="stream?.id"
  [retailerDomain]="retailerDomain"
></showcased-products>

<div
  *ngIf="isExclusive"
  class="w-100 pl-20 pr-20 p-20 pl-sm-20 pr-sm-20 p-20-sm bg-white"
>
  <mat-tab-group mat-align-tabs="start" class="b-s1 bc-00012 brds-4px p-15">
    <mat-tab class="tt-upper" label="SESSION DETAILS">
      <div [innerHtml]="stream.description" class="p-15 pl-0"></div>
    </mat-tab>
  </mat-tab-group>
</div>
<div
  *ngIf="otherExclusiveSession.length > 0"
  class="w-100 pl-20 pr-20 p-20 bg-white"
>
  <mat-card class="p-0-i">
    <mat-card-header class="d-block w-100 p-15 pb-0 d-flex-row">
      <mat-card-title class="h1-dark">Other Sessions</mat-card-title>
      <a class="link-primary lh-23-i ml-auto ta-right">View All</a>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content class="fx-wrap-sm p-5">
      <div class="d-flex-row fx-wrap-sm w-100 pstn-relative">
        <mat-card
          class="m-5 d-flex-col flex-auto mxw-31-33-30-19 p-0-i slider-card-item"
          *ngFor="let item of otherExclusiveSession"
        >
          <a
            class="d-flex pstn-relative m-0-i w-100 lb-card-img-contaier"
            href="/shops/{{ item?.user?.domain }}/sessions/{{ item._id }}"
          >
            <img
              class="w-100 h-100 mb-0-i lb-card-img"
              [src]="item?.streamThumbnailUrl"
            />
          </a>
          <mat-card-content class="mb-50-i p-15-i">
            <span class="fs-10 fw-600 text-0004 ff-roboto">{{
              item?.startTime
                | date
                  : "dd MMM hh:mm
                            a"
            }}</span>
            <h5 class="p3-dark-fwm mt-10-i fs-13-i">{{ item?.title }}</h5>
          </mat-card-content>
          <mat-card-footer
            class="p-15-i d-block of-hidden w-100 ml-0-i pstn-absolute-b16"
          >
            <p class="m-0-i">
              <a class="d-flex-row" href="/shops/{{ item?.user?.domain }}">
                <img
                  [src]="item.user.logoUrl | apiImgPath : { nl: true }"
                  class="w-36px-i h-36px-i brds-100 b-s1 bc-00012"
                  onerror="this.src='./assets/images/nologo.jpg'"
                />
                <span
                  *ngIf="item.user.storeName"
                  class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i wp-nowrap"
                  >{{ item.user.storeName }}</span
                >
                <span
                  *ngIf="!item.user.storeName"
                  class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i wp-nowrap"
                  >Store</span
                >
              </a>
            </p>
          </mat-card-footer>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div
  *ngIf="!isExclusive && ucItems.length > 0"
  class="w-100 pl-20 pr-20 p-20 pl-sm-20 pr-sm-20 p-20-sm bg-white"
>
  <mat-card>
    <mat-card-header
      class="d-block w-100 p-0"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <mat-card-title class="h1-dark">Upcoming Live Demos</mat-card-title>
      <a routerLink="#" class="link-primary lh-23-i">View All</a>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content class="pt-15 d-flex-row-i flex-wrap w-100">
      <mat-card
        class="ml-5 mr-5 mb-20-i flex-bss-24 flex-bss-46-sm flex-bss-31-md p-0-i"
        *ngFor="let item of ucItems"
      >
        <a
          class="d-flex pstn-relative m-0-i w-100 lb-card-img-contaier"
          href="/shops/{{ item?.user?.domain }}/sessions/{{ item._id }}"
        >
          <img
            class="w-100 h-100 mb-0-i lb-card-img"
            [src]="item?.streamThumbnailUrl"
          />
        </a>
        <mat-card-content class="mb-50-i p-15-i">
          <span class="fs-10 fw-600 text-0004 ff-roboto">{{
            item?.startTime
              | date
                : "dd MMM hh:mm
                        a"
          }}</span>
          <h5 class="p3-dark-fwm mt-10-i fs-13-i">{{ item?.title }}</h5>
        </mat-card-content>
        <mat-card-footer class="p-15-i d-flex-row-i ml-0-i pstn-absolute-b16">
          <a class="d-flex-row" href="/shops/{{ item?.user?.domain }}">
            <img
              [src]="item.user.logoUrl | apiImgPath : { nl: true }"
              class="w-32px brds-100 b-s1 bc-00012"
            />
            <span
              *ngIf="item.user.storeName"
              class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
              >{{ item?.user?.storeName }}</span
            >
            <span
              *ngIf="!item.user.storeName"
              class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
              >Store</span
            >
          </a>
        </mat-card-footer>
      </mat-card>
    </mat-card-content>
  </mat-card>
</div>
<div
  *ngIf="!isExclusive && raItems.length > 0"
  class="w-100 pl-20 pr-20 p-20 pl-sm-20 pr-sm-20 p-20-sm bg-white"
>
  <mat-card>
    <mat-card-header
      class="d-block w-100 p-0"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <mat-card-title class="h1-dark">Recently Aired</mat-card-title>
      <a routerLink="#" class="link-primary lh-23-i">View All</a>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content class="pt-15 d-flex-row-i flex-wrap w-100">
      <mat-card
        class="ml-5 mr-5 mb-20-i flex-bss-24 flex-bss-46-sm flex-bss-31-md p-0-i"
        *ngFor="let item of raItems"
      >
        <a
          class="d-flex pstn-relative m-0-i w-100 lb-card-img-contaier"
          href="/shops/{{ item?.user?.domain }}/sessions/{{ item._id }}"
        >
          <img
            class="w-100 h-100 mb-0-i lb-card-img"
            [src]="item?.streamThumbnailUrl"
            onerror="this.src='./assets/images/thumbnail-default.jpg'"
          />
        </a>
        <mat-card-content class="mb-50-i p-15-i">
          <span class="fs-10 fw-600 text-0004 ff-roboto">{{
            item?.startTime
              | date
                : "dd MMM hh:mm
                        a"
          }}</span>
          <h5 class="p3-dark-fwm mt-10-i fs-13-i">{{ item?.title }}</h5>
        </mat-card-content>
        <mat-card-footer class="p-15-i d-flex-row-i ml-0-i pstn-absolute-b16">
          <a class="d-flex-row" href="/shops/{{ item?.user?.domain }}">
            <img
              [src]="item.user.logoUrl | apiImgPath : { nl: true }"
              class="w-32px brds-100 b-s1 bc-00012"
            />
            <span
              *ngIf="item.user.storeName"
              class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
              >{{ item?.user?.storeName }}</span
            >
            <span
              *ngIf="!item.user.storeName"
              class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i"
              >Store</span
            >
          </a>
        </mat-card-footer>
      </mat-card>
    </mat-card-content>
  </mat-card>
</div>
