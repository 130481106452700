export function validateSession(key: string, time: boolean) {
    let seassionVal:any = sessionStorage.getItem(key);
    if (seassionVal !== null) {
        if( !time ) {
            return true;
        }
        let sessionObj = JSON.parse(seassionVal);
        let expiredAt = new Date(sessionObj.expiredAt);
        if (expiredAt > new Date()) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function setToSession(key:any, inMin:number) {
    let expiredAt = new Date(new Date().getTime() + (60000 * inMin));
    let obj = {
        expiredAt: expiredAt.toISOString()
    }
    sessionStorage.setItem(key, JSON.stringify(obj));
}