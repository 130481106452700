import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService, AuthenticationService, ItemService } from 'src/app/services';
import { ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RoleEnum } from 'src/app/enums/role.enum';

@Component({
  selector: 'consumer-personal-interest-dialog',
  templateUrl: '../../views/user/location.dialog.user.component.html',
  styleUrls: ['../../../assets/css/user/location.dialog.user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LocationDialogComponent implements OnInit {

  storeInfoForm: FormGroup;
  inProgress: boolean = false;
  isRetailerAccount: boolean = false;

  storeLogo: File;
  storeBanner: File;
  phoneNumber = new FormControl('', [Validators.required]);
  addressLine1 = new FormControl('', [Validators.required]);
  addressLine2 = new FormControl('', []);
  state = new FormControl('', [Validators.required]);
  cityOrTown = new FormControl('', [Validators.required]);
  zipCode = new FormControl('', [Validators.required, Validators.minLength(4)]);

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LocationDialogComponent>,
    public itemService: ItemService,
    public authService: AuthenticationService,
    public accountService: AccountService,
    public retailerStoreService: RetailerStoreService,
    private snackBar: MatSnackBar) {
    this.storeInfoForm = this.formBuilder.group({
      storeLogo: new FormControl('', []),
      storeBanner: new FormControl('', []),
      phoneNumber: this.phoneNumber,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      state: this.state,
      cityOrTown: this.cityOrTown,
      zipCode: this.zipCode
    });
  }

  ngOnInit(): void { 
    this.isRetailerAccount = this.authService.currentUserRole == RoleEnum.retailer;
    if(this.isRetailerAccount){
      this.dialogRef.disableClose = true;
    }
  }

  onStoreLogoChange($event) {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      this.storeLogo = file;
    }
  }

  onStoreBannerChange($event) {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      this.storeBanner = file;
    }
  }

  getErrorMessage(fieldKey: string) {
    switch (fieldKey) {
      case 'phoneNumber':
        if (this.phoneNumber.hasError('required')) return 'Phone no is required';
        break;
      case 'addressLine1':
        if (this.addressLine1.hasError('required')) return 'AddressLine1 is required';
        break;
      case 'state':
        if (this.state.hasError('required')) return 'State is required';
        break;
      case 'cityOrTown':
        if (this.state.hasError('required')) return 'City is required';
        break;
      case 'zipCode':
        if (this.state.hasError('required')) return 'Zip is required';
        break;
    }
  }

  onSubmit() {
    
    if (this.storeInfoForm.invalid) {
      return;
    }

    this.inProgress = true;

    const userId = this.authService.currentUserId;
    const formData = new FormData();
    if (this.storeLogo) {
      formData.append('storeLogo', this.storeLogo);
    }

    if (this.storeBanner) {
      formData.append('bannerImage', this.storeBanner);
    }

    formData.append('phoneNumber', this.phoneNumber.value);
    formData.append('addressLine1', this.addressLine1.value);
    formData.append('addressLine2', this.addressLine2.value);
    formData.append('state', this.state.value);
    formData.append('cityOrTown', this.cityOrTown.value);
    formData.append('zipCode', this.zipCode.value);

    this.retailerStoreService.updateStoreInformation(userId, formData)
      .toPromise()
      .then((res:any) => {
        this.inProgress = false;
        this.authService.updateUserLocationIdInLocalStorage(res.locationId);
        this.snackBar.open('Store information updated', 'Close', { duration: 10000, panelClass: ['mat-success-bg'] });
        this.dialogRef.close();
      })
      .catch((err: HttpErrorResponse) => {
        this.inProgress = false;
        const errorDetail = err.error.error || err.error;
        this.snackBar.open(errorDetail, 'Close', { duration: 10000, panelClass: ['mat-error-bg'] });
      });
  }
}
