import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'footer-info',
  templateUrl: '../../views/footer/footer-info.component.html',
  styleUrls: ['../../../assets/css/footer/footer-info.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
