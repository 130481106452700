import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IExclusiveSession } from '../models/exclusive-session.model';

@Injectable({
    providedIn: 'root'
})
export class ScheduleService {

    protected apiServer = AppConfig.settings.apiServer;
    protected localStorageKeys = AppConfig.settings.localStorage.keys;

    constructor(private http: HttpClient) { }

    loadAllFeaturedExclusiveSession(itemLength?: number): Observable<IExclusiveSession[]> {

        var apiUrl = `${this.apiServer.basepath}/schedule/exclusive`;
        if (itemLength) apiUrl += `?length=${itemLength}`;

        return this.http.get(apiUrl).pipe(
            map((streams: IExclusiveSession[]) => {
                return streams;
            }));
    }

    loadAllExclusiveSessions(itemLength?: number): Observable<IExclusiveSession[]> {

        var apiUrl = `${this.apiServer.basepath}/schedule/exclusive/all`;
        if (itemLength) apiUrl += `?length=${itemLength}`;

        return this.http.get(apiUrl).pipe(
            map((streams: IExclusiveSession[]) => {
                return streams;
            }));
    }

    loadSubscriptionInfoByScheduleIdUserId(scheduleId: string, userId: string): Observable<any> {
        var apiUrl = `${this.apiServer.basepath}/schedule/${scheduleId}/has-subscription/${userId}`;
        return this.http.get(apiUrl).pipe(
            map((res: any) => {
                return res;
            }));
    }

    toggleExclusiveSessionEnableChatOption(scheduleId: string, enabled: boolean): Observable<any> {
        var apiUrl = `${this.apiServer.basepath}/schedule/${scheduleId}/enable-chat-option`;
        return this.http.patch(apiUrl, { isExclusiveSessionChatEnabled: enabled }).pipe(
            map((res: any) => {
                return res;
            }));
    }

    postRecentlyViewed(userId: string, formData: any): Observable<any> {
        return this.http.post<any>(`${this.apiServer.basepath}/users/${userId}/view-history`, formData).pipe(
            map((res: any) => {
                return res;
            }));
    }

    deleteScheduleById(scheduleId: string): Observable<any> {
        return this.http.delete<any>(`${this.apiServer.basepath}/schedule/${scheduleId}/delete`).pipe(
            map((res: any) => {
                return res;
            }));
    }

    cancelScheduleById(scheduleId: string): Observable<any> {
        return this.http.patch<any>(`${this.apiServer.basepath}/schedule/${scheduleId}/cancel`, {}).pipe(
            map((res: any) => {
                return res;
            }));
    }
}