import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'consumer-header-nav',
  templateUrl: '../../views/header/consumer-header-nav.component.html',
  styleUrls: ['../../../assets/css/header/retailer-store-header-nav.component.css']
})
export class ConsumerHeaderNavComponent implements OnInit {

  activeRoute: string;
  retailerDomain: string;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    const segments: string[] = this.router.url.split('/').filter(function (x) { return x; });
    if (segments.length === 4) {
      this.activeRoute = segments[2].toLowerCase();
    }
    else if (segments.length === 3
      && segments.indexOf("account") > -1
      && segments.indexOf("subscriptions") > -1) {
      this.activeRoute = segments[1].toLowerCase();
    }
    else {
      this.activeRoute = segments.pop().toLowerCase();
    }
    this.retailerDomain = this.authenticationService.retailerDomain;
  }
}
