import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-retailer-view-store',
    templateUrl: '../../views/store/retailer-view.store.component.html',
    styleUrls: ['../../../assets/css/store/retailer-view.store.component.css']
})
export class RetailerViewStoreComponent implements OnInit, OnDestroy {

    constructor() { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

}
