import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CancelSessionDialogData {
    sessionTitle: string;
}

@Component({
    selector: 'cancel-session-dialog',
    templateUrl: '../../../views/common/session/cancel-session-dialog.html'
})
export class CancelSessionDialogComponent implements OnInit {

    inProgress: boolean;
    data: CancelSessionDialogData;

    constructor(
        public dialogRef: MatDialogRef<CancelSessionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogdata: CancelSessionDialogData) {
        this.data = dialogdata;
    }

    ngOnInit(): void {
    }

    onCancelClick() {
        this.dialogRef.close({ close: false });
    }

    cancelSession() {
        this.inProgress = true;
        this.dialogRef.close({ cancel: true });
    }
}
