import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { JWTTokenService } from './jwt-token.service';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class StoreAuthGuardService implements CanActivate {

    constructor(
        private jwtTokenService: JWTTokenService,
        private authenticationService: AuthenticationService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        
        // check if user has authentication
        if (this.jwtTokenService.isRefreshTokenExpired()) {
            this.router.navigate(['login']);
            return false;
        }

        // check if user bellongs to the store
        const domainnameFromPathParam = route.params["domain"] || route.parent.params["domain"];
        const loggedInUserDomainName = this.authenticationService.retailerDomain;

        if (domainnameFromPathParam != loggedInUserDomainName){
            this.router.navigate(['login']);
            return false;
        }

        return true;
    }
}