import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';

import { CreatorProjectTypeEnum, IProject } from 'src/app/models/project.model';
import { AccountService, AuthenticationService } from 'src/app/services';
import { HttpErrorResponse } from '@angular/common/http';
import { IProduct } from 'src/app/models/product.model';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationStatusEnum } from 'src/app/enums/notification.enum';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectFormDialogComponent } from '../account/project-form.dialog.component';
import { RoleEnum } from 'src/app/enums/role.enum';
import { Router } from '@angular/router';
import { ProjectPaymentDialog } from '../message/project-payment.dialog.component';
import {
  CREATE_STREAM_FROM_PROJECT,
  CreateStreamDialogComponent,
} from '../store/create-stream.dialog.component';

export enum PROJECT_DISPLAY_TYPE_ENUM {
  'VIEW',
  'CREATOR_AS_OWNER',
  'RETAILER_FOR_PAYMENT',
  'RETAILER_FOR_INVITATION',
  'RETAILER_FOR_CREATE_STREAM',
}

type PROJECT_DISPLAY_TYPE =
  | PROJECT_DISPLAY_TYPE_ENUM.VIEW
  | PROJECT_DISPLAY_TYPE_ENUM.CREATOR_AS_OWNER
  | PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_PAYMENT
  | PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_INVITATION
  | PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_CREATE_STREAM;

interface IProjectDisplayDialogData {
  projectId: string;
  actionType: PROJECT_DISPLAY_TYPE;
}

@Component({
  selector: 'project-display-dialog',
  templateUrl: '../../views/common/project-display.dialog.component.html',
  styleUrls: [],
})
export class ProjectDisplayDialogComponent implements OnInit {
  projectId: string;
  project: IProject;
  productList: Array<IProduct>;
  selectedProductId: string;
  inProgress: boolean;
  productFetchProgress: boolean;
  isCreatingNotification: boolean;
  isOwner: boolean;
  isRetailer: RoleEnum;

  actionType: PROJECT_DISPLAY_TYPE;
  showPayment: boolean;
  showInvitation: boolean;
  showEdit: boolean;
  showCreateStream: boolean;
  noAction: boolean;

  videoPath: string;

  constructor(
    public dialogRef: MatDialogRef<ProjectDisplayDialogComponent>,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
    private projectService: ProjectService,
    private snackBar: MatSnackBar,
    private notificationService: NotificationService,
    public editFormDialog: MatDialog,
    public createStreamDialog: MatDialog,
    public projectPaymentDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: IProjectDisplayDialogData
  ) {
    this.projectId = data.projectId;

    this.actionType = data.actionType;
    this.showPayment =
      this.actionType === PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_PAYMENT;
    this.showInvitation =
      this.actionType === PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_INVITATION;
    this.showEdit =
      this.actionType === PROJECT_DISPLAY_TYPE_ENUM.CREATOR_AS_OWNER;
    this.showCreateStream =
      this.actionType === PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_CREATE_STREAM;
    this.noAction = this.actionType === PROJECT_DISPLAY_TYPE_ENUM.VIEW;

    this.productList = [];
    this.isCreatingNotification = false;
    this.isOwner = false;
  }

  ngOnInit(): void {
    this.fetchProject();
    this.fetchProductList();
  }

  fetchProject() {
    this.inProgress = true;
    this.projectService
      .getProjectById(this.projectId)
      .toPromise()
      .then((res) => {
        this.project = res;
        this.videoPath =
          this.project.status === 'PAID_PROJECT'
            ? this.project.video
            : this.project.watermarkedVideo;
        this.isOwner =
          this.project.creatorId === this.authenticationService.currentUserId;
        this.isRetailer = this.authenticationService.isRetailer;
      })
      .catch((err: HttpErrorResponse) => {
        const errorDetail = err.error.error || err.error || err.statusText;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  fetchProductList() {
    this.productFetchProgress = true;
    this.accountService
      .getProductDetailsList(this.authenticationService.currentUserId)
      .toPromise()
      .then((res: any) => {
        this.productList = res;
      })
      .catch((err: HttpErrorResponse) => {
        const errorDetail = err.error.error || err.error || err.statusText;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      })
      .finally(() => {
        this.productFetchProgress = false;
      });
  }

  onProductSelectionChange(product: IProduct) {
    this.selectedProductId = product.id;
  }

  createNotification() {
    this.isCreatingNotification = true;
    if (_.isEmpty(this.selectedProductId)) {
      this.snackBar.open('A product must be selected', 'Close', {
        duration: 10000,
        panelClass: ['mat-error-bg'],
      });
    }
    this.notificationService
      .createNotification({
        retailerId: this.authenticationService.currentUserId,
        creatorId: this.project.creatorId,
        productId: this.selectedProductId,
        referenceProjectId: this.project.id,
        status: NotificationStatusEnum.INVITED,
      })
      .toPromise()
      .then((res) => {
        if (res?.isSuccess) {
          this.snackBar.open('Invitation sent!', 'Close', {
            duration: 10000,
            panelClass: ['mat-success-bg'],
          });
          this.dialogRef.close();
        } else {
          this.snackBar.open(res.data.message, 'Close', {
            duration: 10000,
            panelClass: ['mat-error-bg'],
          });
        }
      })
      .catch((err) => {
        this.inProgress = false;
        const errorDetail = err.error.error || err.error;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      })
      .finally(() => {
        this.isCreatingNotification = false;
      });
  }

  canInvite() {
    // if not retailer, false
    const roles = _.get(
      this.authenticationService.currentUserValue,
      'roles',
      []
    );
    if (!_.includes(roles, 'RETAILER')) {
      return false;
    }
    // if have no product, false
    if (!_.size(this.productList)) {
      return false;
    }
    // if retailer project, false
    if (this.project.type === CreatorProjectTypeEnum.RETAILER_PROJECT) {
      return false;
    }

    return true;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  editProject() {
    this.closeDialog();
    this.editFormDialog.open(ProjectFormDialogComponent, {
      data: this.project,
    });
  }

  goToPayment() {
    this.closeDialog();
    const projectPaymentDialogRef = this.projectPaymentDialog.open(
      ProjectPaymentDialog,
      {
        data: {
          projectId: this.projectId,
        },
      }
    );
    projectPaymentDialogRef.afterClosed().toPromise();
  }

  goToCreateStream() {
    this.closeDialog();
    this.createStreamDialog.open(CreateStreamDialogComponent, {
      data: {
        projectId: this.projectId,
        actionType: CREATE_STREAM_FROM_PROJECT,
      },
    });
  }
}
