<div class="bg-white mt-40 mb-25 p-25 w-60 w-100-sm b-s1-00012 brds-4px d-flex-col ff-robot m-auto">
    <div class="d-block w-100 ta-center">
        <mat-icon class="text-00C851">check_circle</mat-icon>
    </div>
    <p class="fs-20 fw-500 lh-24 text-black ta-center mt-15-i" *ngIf="!isSubscription">Order Placed Successfully</p>
    <p class="fs-20 fw-500 lh-24 text-black ta-center mt-15-i" *ngIf="isSubscription">Successfully Subscribed</p>
    <p class="fs-16 lh-24 text-0087 mt-15-i ta-center" *ngIf="!isSubscription">Thank you for your order. An email confirmation was sent to you
        at
        <a class="link-primary"
            href="mailto:{{pr.shipping_details?.recipient?.email_address}}">{{pr.shipping_details?.recipient?.email_address}}</a> If you have
        questions about your order, please contact us at
        <a href="mailto:admin@marketplace0.com" class="link-primary">admin@marketplace0.com</a>
    </p>
    <p class="fs-16 lh-24 text-0087 mt-15-i ta-center" *ngIf="isSubscription">Thank you for your subscription. An email confirmation was sent to you
        at
        <a class="link-primary"
            href="mailto:{{sbpn?.subscriberInfo?.email}}">{{sbpn?.subscriberInfo?.email}}</a> If you have
        questions about your subscription, please contact us at
        <a href="mailto:admin@marketplace0.com" class="link-primary">admin@marketplace0.com</a>
    </p>
</div>
<div class="bg-white mt-40 mb-25 p-25 w-60 w-100-sm b-s1-00012 brds-4px d-flex-col ff-robot m-auto" *ngIf="!isSubscription">
    <h1 class="fs-20 fw-500 lh-24 text-0087 pl-15">Order #{{pr.order_id}}</h1>
    <mat-divider></mat-divider>
    <div class="d-flex-row w-100 p-15 flex-wrap">
        <div class="flex-bss-50 flex-bss-100-sm text-0087">
            <h2 class="d-block fs-16 fw-500 lh-24">Order Details</h2>
            <label class="d-block fs-14 fw-400 lh-24"> {{pr.quantity}} Item (Shipment)</label>
            <label class="d-block fs-14 fw-500 lh-24"> Seller: <span class="fw-400">{{pr.storeName}}</span></label>
            <label class="d-block fs-14 fw-500 lh-24">Order Placed: <span
                    class="fw-400">{{pr.shipmentOrPickUpDetails?.placed_at | date : 'MMM dd, yyyy hh:mm
                a'}}</span></label>
            <label class="d-block fs-14 fw-500 lh-24">Order By: <span class="fw-400">{{pr.shipmentOrPickUpDetails?.recipient?.display_name}}</span></label>
            <label class="d-block fs-14 fw-500 lh-24">Email: <span class="fw-400">{{pr.shipmentOrPickUpDetails?.recipient?.email_address}}</span></label>
        </div>
        <div class="flex-bss-50 flex-bss-100-sm text-0087">
            <h2 class="d-block fs-16 fw-500 lh-24">Shipping Address</h2>
            <label class="d-block fs-14 fw-400 lh-24">{{pr.shipmentOrPickUpDetails?.recipient?.display_name}}</label>
            <label class="d-block fs-14 fw-400 lh-24">
                <span class="d-block">{{pr.shipmentOrPickUpDetails?.recipient?.address?.address_line_1}} {{pr.shipmentOrPickUpDetails?.recipient?.address?.address_line_2}}</span>
            </label>
            <label class="d-block fs-14 fw-400 lh-24">
                <span>{{pr.shipmentOrPickUpDetails?.recipient?.address?.locality}} , </span>
                <span>{{pr.shipmentOrPickUpDetails?.recipient?.address?.administrative_district_level_1}}, </span>
                <span>{{pr.shipmentOrPickUpDetails?.recipient?.address?.postal_code}} </span>
            </label>
            <label class="d-block fs-14 fw-400 lh-24">{{pr.shipmentOrPickUpDetails?.recipient?.phone_number}}</label>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex-row w-100 p-15 flex-wrap d-block fs-14 fw-400 lh-24">
        <span class="d-inline">{{pr.item_name}} ({{pr.quantity}} item)</span>
        <span class="d-inline fw-500 ml-auto">{{pr.base_price_money?.amount/100 | currency}}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex-row w-100 pl-15 pr-15 pb-5 pt-5 flex-wrap d-block fs-14 fw-400 lh-24">
        <span class="d-inline">Subtotal</span>
        <span class="d-inline ml-auto">{{ pr.quantity * (pr.base_price_money?.amount/100) | currency}}</span>
    </div>
    <div class="d-flex-row w-100 pl-15 pr-15 pb-5 pt-5 flex-wrap d-block fs-14 fw-400 lh-24">
        <span class="d-inline">Delivery Fee</span>
        <span class="d-inline ml-auto">{{pr.delivery_fee?.amount | currency}}</span>
    </div>
    <div class="d-flex-row w-100 pl-15 pr-15 pb-5 pt-5 flex-wrap d-block fs-14 fw-400 lh-24">
        <span class="d-inline">Tax</span>
        <span class="d-inline ml-auto">{{pr.tax?.amount/100 | currency}}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex-row w-100 pl-15 pr-15 pb-5 pt-5 flex-wrap d-block fs-18 fw-500 lh-24">
        <span class="d-inline">Order Total</span>
        <span class="d-inline fw-500 ml-auto">{{pr.order_total?.amount/100 | currency}}</span>
    </div>
</div>
<div class="bg-white mt-40 mb-25 p-25 w-60 w-100-sm b-s1-00012 brds-4px d-flex-col ff-robot m-auto" *ngIf="isSubscription">
    <h1 class="fs-20 fw-500 lh-24 text-0087 pl-15">Subscription #{{sbpn.subscriptionId}}</h1>
    <mat-divider></mat-divider>
    <div class="d-flex-row w-100 p-15 flex-wrap">
        <div class="flex-bss-50 flex-bss-100-sm text-0087">
            <h2 class="d-block fs-16 fw-500 lh-24">Subscription Details</h2>
            <label class="d-block fs-14 fw-400 lh-24"> {{sbpn.quantity}} Item (Exclusive Session)</label>
            <label class="d-block fs-14 fw-500 lh-24"> Seller: <span class="fw-400">{{sbpn.seller}}</span></label>
            <label class="d-block fs-14 fw-500 lh-24">Order Placed: <span
                    class="fw-400">{{sbpn.orderPlaced | date : 'MMM dd, yyyy hh:mm
                a'}}</span></label>
            <label class="d-block fs-14 fw-500 lh-24">
                Order By: <span class="fw-400">{{sbpn.subscriberInfo.firstName}}</span>
                <span *ngIf="sbpn.subscriberInfo.lastName">{{sbpn.subscriberInfo.lastName}}</span>
            </label>
            <label class="d-block fs-14 fw-500 lh-24">Email: <span class="fw-400">{{sbpn.subscriberInfo?.email}}</span></label>
        </div>
        <div class="flex-bss-50 flex-bss-100-sm text-0087">
            <h2 class="d-block fs-16 fw-500 lh-24">Shipping Address</h2>
            <label class="d-block fs-14 fw-400 lh-24">
                <span class="fw-400">{{sbpn.subscriberInfo.firstName}}</span>
                <span *ngIf="sbpn.subscriberInfo.lastName">{{sbpn.subscriberInfo.lastName}}</span>
            </label>
            <label class="d-block fs-14 fw-400 lh-24">{{sbpn.subscriberInfo.phoneNumber}}</label>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex-row w-100 p-15 flex-wrap d-block fs-14 fw-400 lh-24">
        <span class="d-inline">
            {{sbpn.sessionTitle}} ({{sbpn.quantity}} item)
            <span>(Access {{sbpn.accessHour}} hours)</span>
        </span>
        <span class="d-inline fw-500 ml-auto">{{sbpn.amount/100 | currency}}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex-row w-100 pl-15 pr-15 pb-5 pt-5 flex-wrap d-block fs-14 fw-400 lh-24">
        <span class="d-inline">Subtotal</span>
        <span class="d-inline ml-auto">{{sbpn.amount/100 | currency}}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex-row w-100 pl-15 pr-15 pb-5 pt-5 flex-wrap d-block fs-18 fw-500 lh-24">
        <span class="d-inline">Total</span>
        <span class="d-inline fw-500 ml-auto">{{sbpn.amount/100 | currency}}</span>
    </div>
</div>