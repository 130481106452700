import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddItemDialogComponent } from './add-item.dialog.component';
import { AddTaxDialogComponent } from './add-tax.dialog.component';
import { AuthenticationService, ItemService } from 'src/app/services';
import { DeleteItemDialogComponent } from './delete-item.dialog.component';
import { AddImageDialogComponent } from './add-image.dialog.component';

@Component({
  selector: 'app-item',
  templateUrl: '../../views/item/item-list.component.html',
})
export class ItemListComponent implements OnInit {
  productList = [];
  categories = [];
  subscriptions = new Subscription();
  constructor(
    public dialog: MatDialog,
    public itemService: ItemService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.loadProducts();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadProducts() {
    const userId = this.authenticationService.currentUserId;
    this.subscriptions.add(
      this.itemService.categories().subscribe((categories) => {
        this.categories = categories;
        this.itemService.allItemsByUserId(userId).subscribe((items: any) => {
          this.productList = (items.items || []).map((product) => {
            const categoryObj = this.categories.find(
              (cat) => cat.id === product.category_id
            );
            product.categoryName = categoryObj ? categoryObj.category : 'N/A';
            return product;
          });
          console.log(this.productList);
        });
      })
    );
  }

  openAddProductDialog(product: {}) {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      data: product,
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }

  openAddTaxDialog() {
    const dialogRef = this.dialog.open(AddTaxDialogComponent);
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }

  openDeleteProductDialog(product) {
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, {
      data: product,
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }

  openAddImageDialog() {
    const dialogRef = this.dialog.open(AddImageDialogComponent, {});
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }
}
