import { Component, OnInit } from '@angular/core';
import { RoleEnum } from 'src/app/enums/role.enum';
import { AuthenticationService, RouteService } from 'src/app/services';

@Component({
  selector: 'app-header',
  templateUrl: '../../views/header/header.component.html',
  styleUrls: ['../../../assets/css/header/header.component.css']
})
export class HeaderComponent implements OnInit {

  isRetailer: boolean = false;

  constructor(
    public routeService: RouteService,
    private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.isRetailer = this.authService.currentUserRole === RoleEnum.retailer;
  }

}
