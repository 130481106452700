import { Component, OnInit } from '@angular/core';
import { RouteService } from 'src/app/services';

@Component({
  selector: 'app-header-top',
  templateUrl: '../../views/header/header-top.component.html',
  styleUrls: ['../../../assets/css/header/header-top.component.css']
})
export class HeaderTopComponent implements OnInit {

  constructor(public routeService: RouteService) { }

  ngOnInit(): void {
  }

}
