import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from 'src/app/services/shop.service';
import _ from 'lodash';
import { IRecentlyAiredSchedule } from 'src/app/models/recently-aired-items.model';
import { IExclusiveSession } from 'src/app/models/exclusive-session.model';
import { ISchedule } from 'src/app/models/schedule.model';
import { ScheduleService } from 'src/app/services/schedule.service';
import { Subscription } from 'rxjs';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/services';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import {
  StreamStatusEnum,
  userStreamStatusEnum,
} from 'src/app/enums/stream-status.enum';
import { HOME_ROUTE } from 'src/app/app.config';

@Component({
  selector: 'pves-session',
  templateUrl:
    '../../views/sessions/public-view-exclusive-session.component.html',
})
export class PublicViewSessionComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  streamingIsInProgress: boolean = false;
  retailerDomain: string;
  streamId: string;
  stream: ISchedule;
  viewedStreamForm: FormGroup;

  isExclusive: boolean = false;
  hasSubscription: boolean = false;
  isSubscriptionRequired: boolean = true;
  subscriptionInfoLoaded: boolean = false;
  sessionDetail: string;

  raItems: any[] = [];
  ucItems: any[] = [];
  otherExclusiveSession: any[] = [];

  subscriptions = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private scheduleService: ScheduleService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthenticationService,
    private router: Router,
    private shopService: ShopService,
    private sessionManager: SessionComponentService
  ) {}

  ngOnInit(): void {
    this.retailerDomain =
      this.activatedRoute.parent.snapshot.paramMap.get('storename');
    this.streamId = this.activatedRoute.snapshot.paramMap.get('sessionId');

    this.subscriptions.add(
      this.sessionManager.isExclusive.subscribe((exclusive: boolean) => {
        this.isExclusive = exclusive;
        if (this.isExclusive) {
          this.loadALLOtherExclusiveStream();
        }
      })
    );
    this.loadStreamInfo();
    this.loadRecentlyAiredSchedules();
    this.loadUpcommingSchedules();
  }

  ngAfterViewInit() {}

  postRecentlyViewed(): void {
    if (
      this.authService.currentUserId &&
      (this.stream.status == (StreamStatusEnum.IN_PROGRESS as any) ||
        (StreamStatusEnum.CLOSED as any) == this.stream.status)
    ) {
      let formdata = {
        itemId: this.stream.id,
        itemType: userStreamStatusEnum.RECENTLY_VIEWD,
      };
      this.scheduleService
        .postRecentlyViewed(this.authService.currentUserId, formdata)
        .toPromise()
        .then((res: any) => {})
        .catch((err: HttpErrorResponse) => {
          console.log(err);
        });
    }
  }

  loadStreamInfo(): void {
    this.shopService
      .scheduleByScheduleId(this.streamId)
      .toPromise()
      .then((res: ISchedule) => {
        // handle schedule not found response
        if (!res) {
          this.router.navigate([HOME_ROUTE]);
          return;
        }

        this.stream = res;
        setTimeout(() => {
          this.sessionManager.sessionCreatorId.emit(res.retailerId);
          this.sessionManager.status.emit(res.status);
          this.sessionManager.session.emit(res);
          this.sessionManager.sessionTitle.emit(res.title);
          this.sessionManager.sessionStartTime.emit(res.startTime);
          this.sessionManager.isExclusive.emit(
            _.lowerCase(_.get(res, 'type')) === 'exclusive'
          );
          this.sessionManager.sessionStartTimeLiteral.emit(
            this.getSessionStartTimeLiteral(
              res.status,
              res.startTime,
              res.endTime
            )
          );
          this.sessionManager.conversationEnabled.emit(
            res.isExclusiveSessionChatEnabled
          );
          this.sessionManager.sessionId.emit(res.id);
          this.postRecentlyViewed();
        }, 1000);
      })
      .catch((err: any) => {
        this.router.navigate([HOME_ROUTE]);
        return;
      });
  }

  loadRecentlyAiredSchedules() {
    this.shopService
      .allSchedulesInRecentlyAiredByDomain(this.retailerDomain)
      .toPromise()
      .then((res: IRecentlyAiredSchedule[]) => {
        this.raItems = res;
      });
  }

  loadUpcommingSchedules() {
    this.shopService
      .allSchedulesInUpcomingByDomain(this.retailerDomain)
      .toPromise()
      .then((res: IRecentlyAiredSchedule[]) => {
        this.ucItems = res;
      });
  }

  loadALLOtherExclusiveStream() {
    this.shopService
      .loadALLOtherExclusiveStreamByStreamId(this.streamId)
      .toPromise()
      .then((res: IExclusiveSession[]) => {
        this.otherExclusiveSession = res;
      });
  }

  getSessionStartTimeLiteral(
    status: any,
    startTime: any,
    endTime: any
  ): string {
    if (status == '2') {
      return `Started at - ${this.datePipe.transform(
        new Date(startTime),
        'MMM dd, yyyy'
      )}`;
    }

    const timeStart: any = new Date(startTime);
    const now: any = new Date();

    if (now > timeStart) {
      return `Started at - ${this.datePipe.transform(
        new Date(startTime),
        'MMM dd, yyyy hh:mm a'
      )}`;
    }

    var diffMs = timeStart - now; // milliseconds between now & starttime
    var duration = Math.floor(diffMs / 60000); // in minutes
    if (duration > 60 * 24) {
      duration = Math.round(duration / (60 * 24));
      const streamDuration = parseFloat(duration.toString()).toFixed(0);
      return `Starts in - ${streamDuration}d`;
    } else if (duration > 59) {
      duration = Math.round(duration / 60);
      const streamDuration = parseFloat(duration.toString()).toFixed(0);
      return `Starts in - ${streamDuration}h`;
    }

    const streamDuration = parseFloat(duration.toString()).toFixed(0);
    return `Starts in - ${streamDuration}m`;
  }

  ngOnDestroy(): void {}
}
