<div class="mw-580px-i mnw-320px">
  <h1 mat-dialog-title class="h1-dark fs-20-i fw-500-i">
    Add/Edit Tax Information
  </h1>
  <div mat-dialog-content>
    <form
      name="taxForm"
      [formGroup]="taxForm"
      (ngSubmit)="onSubmit()"
      method="post"
    >
      <div class="pb-10 pt-10">
        <div class="pb-10 pt-10">
          <div class="form-item mt-25">
            <div class="row d-block">
              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Name</mat-label>
                  <input
                    matInput
                    placeholder="name"
                    [formControl]="name"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Percentage</mat-label>
                  <input
                    matInput
                    placeholder="Percentage"
                    [formControl]="percentage"
                    required
                  />
                </mat-form-field>
              </div>

              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Calculation Phase</mat-label>
                  <mat-select [formControl]="calculationPhase" required>
                    <mat-option
                      *ngFor="let calculationPhase of calculationPhases"
                      value="{{ calculationPhase.value }}"
                      >{{ calculationPhase.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Inclusion Type</mat-label>
                  <mat-select [formControl]="inclusionType" required>
                    <mat-option
                      *ngFor="let inclusionType of inclusionTypes"
                      value="{{ inclusionType.value }}"
                      >{{ inclusionType.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block-i">
                  <mat-label>Applies To Custom Amounts Type</mat-label>
                  <mat-select [formControl]="appliesToCustomAmounts" required>
                    <mat-option
                      *ngFor="
                        let appliesToCustomAmountsType of appliesToCustomAmountsTypes
                      "
                      value="{{ appliesToCustomAmountsType.value }}"
                      >{{ appliesToCustomAmountsType.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions class="pstn-relative">
          <mat-spinner
            diameter="25"
            class="color-primary pstn-absolute t24 r4"
            [class.hidden]="!inProgress"
          >
          </mat-spinner>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            class="tt-upper"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
