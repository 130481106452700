import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { ICartItem } from 'src/app/models/cart-item.model';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { ISchedule } from 'src/app/models/schedule.model';

export interface StreamSubscriptionDialogData {
    stream: ISchedule;
}


@Component({
    selector: 'stream-subscription-dialog',
    templateUrl: '../../../views/common/session/stream-subscription-dialog.component.html'
})
export class StreamSubscriptionDialogComponent implements OnInit, OnDestroy {

    inProgress:boolean = false;
    stream: ISchedule;
    isAuthorized: boolean = false;
    isPending: boolean = false;

    domain:string;

    subscriptions = new Subscription();

    constructor(
        private authService: AuthenticationService,
        private dialogRef: MatDialogRef<StreamSubscriptionDialogComponent>,
        private cartService: CartService,
        private session: SessionService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public dialogdata: StreamSubscriptionDialogData) {
        this.stream = dialogdata.stream;
    }

    ngOnInit(): void {
        this.domain = this.router.url.split('/').filter(function(x) {return x;})[1];
        const loggedInUserId = this.authService.currentUserId;
        this.isAuthorized = loggedInUserId != undefined && loggedInUserId != null && loggedInUserId != "";
        this.isPending = this.stream.status != "2";
    }

    onCancelClick() {
        this.dialogRef.close();
    }

    onSubscribeClick() {

        // clear previous items
        this.cartService.clearCart();
        
        this.inProgress = true;

        const cartItem: ICartItem = {
            itemId: this.stream.id,
            name: this.stream.title,
            quantity: 1,
            image_id: this.stream.streamThumbnailUrl,
            unitPrice: this.stream.amount,
            variationId: undefined,
            isExclusiveStream: true,
            exclusiveStreamStatus: this.stream.status
        };

        this.session.checkoutDomain = this.domain;
        this.cartService.addToCart(cartItem);
        this.dialogRef.close();
        if (this.isAuthorized) {
            this.router.navigate([]).then(() => {  window.open('/shops/checkout', '_blank'); });
            return;
        }

        this.router.navigate([]).then(() => {  
            window.open('/login?returnUrl=/shops/checkout', '_blank'); 
        });
        
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
