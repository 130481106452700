import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouteService } from 'src/app/services';

@Component({
  selector: 'signup-verfication',
  templateUrl: '../../views/signup/verification.signup.component.html',
  styleUrls: ['../../../assets/css/signup/signup.component.css']
})
export class SignupVerificationComponent implements OnInit, OnDestroy {

  signedUpEmail:string;

  constructor(private routeService: RouteService) { }

  ngOnInit(): void {
    this.signedUpEmail = this.routeService.verificationData.email;
  }

  ngOnDestroy(): void {
    this.routeService.verificationData = undefined;
  }

}
