import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleEnum } from 'src/app/enums/role.enum';
import { IOrderDetail } from 'src/app/models/order-detail.model';
import { ISubscriptionDetail } from 'src/app/models/subscription-detail.model';
import { AuthenticationService } from 'src/app/services';
import { OrderService } from 'src/app/services/order.service';

@Component({
    selector: 'subscription-detail',
    templateUrl: '../../views/account/subscription-detail.component.html',
})
export class SubscriptionDetailComponent implements OnInit, OnDestroy {

    isRetailer: boolean;
    retailerDomain: string;
    subscriptionId: string;
    subscriptionDetail: ISubscriptionDetail;
    loadingSubscriptionIdDetailInfo: boolean = true;

    requestForSubscriptionsPage: boolean;
    requestForSubscribersPage: boolean;

    constructor(
        private activeRoute: ActivatedRoute,
        private authService: AuthenticationService,
        private router: Router,
        private orderService: OrderService) {
        this.requestForSubscriptionsPage = this.router.url.indexOf('subscriptions') > -1;
        this.requestForSubscribersPage = this.router.url.indexOf('subscribers') > -1;
    }

    ngOnInit() {
        
        this.isRetailer = this.authService.currentUserRole == RoleEnum.retailer;
        if (this.isRetailer) {
            this.retailerDomain = this.activeRoute.parent.snapshot.paramMap.get('domain') || this.activeRoute.snapshot.paramMap.get('domain');
            if (this.requestForSubscriptionsPage) {
                this.subscriptionId = this.activeRoute.snapshot.paramMap.get('subscriptionId');
            }
            else if (this.requestForSubscribersPage) {
                this.subscriptionId = this.activeRoute.snapshot.paramMap.get('subscriberId');
            }
        } else {
            this.subscriptionId = this.activeRoute.snapshot.paramMap.get('subscriptionId');
        }
        this.orderService.getSubscriptionDetailBySubscriptionId(this.subscriptionId)
            .toPromise()
            .then(res => {
                this.subscriptionDetail = res;
                this.loadingSubscriptionIdDetailInfo = false;
            });
    }

    get orderTotal() {
        const total = this.subscriptionDetail?.quantity * this.subscriptionDetail?.amount;
        return total;
    }

    ngOnDestroy(): void { }
}