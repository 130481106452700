<div class="d-flex-col ff-roboto pstn-relative">
    <p class="text-0087 fs-18 fw-500 ff-roboto lh-24 mw-80 mw-80-sm">{{session?.title}}</p>
    <div class="d-flex-row ff-roboto" *ngIf="!isRetailer">
        <a class="d-flex-row" href="/shops/{{storeName}}">
            <img [src]="session?.logoUrl | apiImgPath : {nl:true}" class="w-36px-i h-36px-i brds-100 b-s1 bc-00012"
                onerror="this.src='./assets/images/nologo.jpg'">
            <span class="link-primary lh-30 ml-15-i wp-nowrap">{{storeName}}</span>
        </a>
    </div>
    <div class="d-flex-row ff-roboto" *ngIf="isRetailer">
        <a class="d-flex-row" href="/store/{{storeName}}/sessions">
            <img [src]="session?.logoUrl | apiImgPath : {nl:true}" class="w-36px-i h-36px-i brds-100 b-s1 bc-00012"
                onerror="this.src='./assets/images/nologo.jpg'">
            <span class="link-primary lh-30 ml-15-i wp-nowrap">{{storeName}}</span>
        </a>
    </div>
    <div class="d-flex-row ml-auto pstn-absolute-t0-r0 pstn-relative mr-0 mt-5 mr-sm-10n mt-sm-5" *ngIf="isExclusive">
        <span class="fs-9 lh-8 p-2 pl-5 pr-5 tt-upper text-primary bg-primary-0012 b-s1 bc-951D8A brds-2px">
            EXCLUSIVE
        </span>
    </div>
    <div id="sessionStartTimeLiterelContainer"
        class="d-flex-col ml-auto pstn-absolute-t0-r0 pstn-sm-absolute-l0 mt-40 ta-right ta-sm-justify mt-sm-60 pl-sm-50">
        <span *ngIf="!onAir" class="fs-11 lh-11 text-primary lh-24">{{sessionStartTimeLiteral}}</span>
    </div>
</div>